import {Button, Form, Input, Space} from "antd";
import {useCallback, useMemo} from "react";

export const SubCategoryForm = ({
    formRef,
    subCategory = null,
    onSubmit,
    onCancel,
    isLoading = false,
}) => {
    const initialValues = useMemo(() => {
        return subCategory ? subCategory : {};
    }, [subCategory]);

    const onFormSubmitHandler = useCallback(async (values) => {
        await onSubmit(values);
    }, [onSubmit]);

    return (
        <Form form={formRef} onFinish={onFormSubmitHandler} disabled={isLoading} initialValues={initialValues}>
            <Form.Item name="name" label="Название подкатегории"  rules={[
                {
                    required: true,
                    message: 'Поле обязательное',
                },
            ]}>
                <Input />
            </Form.Item>

            <Form.Item>
                <Space>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        Сохранить
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
