import React from "react";

import { EnumList, Paragraph } from "./styled";
import { config } from "./config";

export default function PersonalInformation() {
  return (
    <ul>
      {config.map(({ id, text, subtext, isBolded}) => (
        <>
          <Paragraph key={"corp_p_" + id} isBolded={isBolded}>
            {text}
          </Paragraph>
          {subtext && (
            <EnumList>
              {subtext.map((item, index) => (
                <li key={"corp_li_" + index}>{item}</li>
              ))}
            </EnumList>
          )}
        </>
      ))}
    </ul>
  );
}
