export const config = [
  {
    id: 1,
    title:
      "Информация о выплате дивидендов по акциям ОАО Беларускабель за 2023",
    link:
      "/shareholders/Информация о выплате дивидендов по акциям ОАО Беларускабель за 2023.pdf",
  },
  {
    id: 2,
    title: "О начале выплаты дивидендов за 2022 год",
    link: "/shareholders/О начале выплаты дивидендов за 2022 год.pdf",
  },
  {
    id: 3,
    title: "О выплате дивидендов за 2022 год",
    link: "/shareholders/О выплате дивидендов за 2022 год.pdf",
  },
  {
    id: 4,
    title: "О выплате дивидендов акционерам ОАО Беларускабель за 2021 год",
    link: "/shareholders/О выплате дивидендов акционерам ОАО Беларускабель.pdf",
  },
  {
    id: 5,
    title: "Дивиденды ОАО Беларускабель за 2021 год",
    link: "/shareholders/Дивиденды ОАО Беларускабель за 2021 год.pdf",
  },
  {
    id: 6,
    title: "О дивидендах за 2020 год",
    link: "/shareholders/О дивидендах за 2020 год.pdf",
  },
  {
    id: 7,
    title: "О начале выплаты дивидендов за 2019 год",
    link: "/shareholders/О начале выплаты дивидендов за 2019 год.pdf",
  },
  {
    id: 8,
    title: "Информация о дивидендах за 2019 год",
    link: "/shareholders/Информация о дивидендах за 2019 год.pdf",
  },
];
