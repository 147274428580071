export const products = {
    ru: {
        translation: {
            "Кабели силовые": 'Кабели силовые',
            "Контрольный кабель": 'Контрольный кабель',
            "Кабели и провода связи": 'Кабели и провода связи',
            "Сигнально-блокировочный": 'Сигнально-блокировочный',
            "Провода теплостойкие": 'Провода теплостойкие',
            "Провода монтажные": 'Провода монтажные',
            "КУПД": 'КУПД',
            "КВПД": 'КВПД',
            "МКЭШв": 'МКЭШв',
            "КВВГ": 'КВВГ',
            "КВВГЭ": 'КВВГЭ',
            "ТППэп": 'ТППэп',
            "ТППШв": 'ТППШв',
            "КВП": 'КВП',
            "СБЗПУ": 'СБЗПУ',
            "БПДО": 'БПДО',
            "МСТП": 'МСТП',
            "МЛТП": 'МЛТП',
            "МНВ": 'МНВ',
            "МГШВ": 'МГШВ',
            "НВ": 'НВ',
        }
    },
    en: {
        translation: {
            "Кабели силовые": 'Power cables',
            "Контрольный кабель": 'Control cable',
            "Кабели и провода связи": 'Cables and wires',
            "Сигнально-блокировочный": 'Signal-blocking',
            "Провода теплостойкие": 'Heat-resistant wires',
            "Провода монтажные": 'Installation wires',
            "КУПД": 'КУПД',
            "КВПД": 'КВПД',
            "МКЭШв": 'МКЭШв',
            "КВВГ": 'КВВГ',
            "КВВГЭ": 'КВВГЭ',
            "ТППэп": 'ТППэп',
            "ТППШв": 'ТППШв',
            "КВП": 'КВП',
            "СБЗПУ": 'СБЗПУ',
            "БПДО": 'БПДО',
            "МСТП": 'МСТП',
            "МЛТП": 'МЛТП',
            "МНВ": 'МНВ',
            "МГШВ": 'МГШВ',
            "НВ": 'НВ',
        }
    },
    bel: {
        translation: {
            "Кабели силовые": 'Кабелі сілавыя',
            "Контрольный кабель": 'Кантрольны кабель',
            "Кабели и провода связи": 'Кабелі і правады сувязі',
            "Сигнально-блокировочный": 'Сігнальна-блакіровачны',
            "Провода теплостойкие": 'Правады цеплаўстойлівыя',
            "Провода монтажные": 'Правады мантажныя',
            "КУПД": 'КУПД',
            "КВПД": 'КВПД',
            "МКЭШв": 'МКЭШв',
            "КВВГ": 'КВВГ',
            "КВВГЭ": 'КВВГЭ',
            "ТППэп": 'ТППэп',
            "ТППШв": 'ТППШв',
            "КВП": 'КВП',
            "СБЗПУ": 'СБЗПУ',
            "БПДО": 'БПДО',
            "МСТП": 'МСТП',
            "МЛТП": 'МЛТП',
            "МНВ": 'МНВ',
            "МГШВ": 'МГШВ',
            "НВ": 'НВ',
        }
    }
}