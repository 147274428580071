import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Wrapper = styled.div`
    position: relative;
`

export const Content = styled.div`
    overflow: hidden;
    max-width: 1270px;
    width: 100%;
    margin: 50px auto;
`

export const PageTitle = styled.h1`
    font-family: "Raleway";
    font-size: 36px;
    line-height: 43px;
    text-transform: uppercase;
    color: #e74137;
    font-weight: 700;

    text-align: center;
    margin: 50px auto;
`

export const CardsList = styled.ul`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    max-width: 984px;
    width: 100%;
    margin: 0 auto;

    gap: 24px;
    padding: 0;
`

export const CatalogCard = styled.li`
    padding: 24px 12px;
    transition: all 0.3s ease;
    
    max-width: 480px;
    width: 100%;
    
    &:hover {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.7);
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(40px);
        border-radius: 5px;

        & > a > p {
            color: #e74137;
        }
    }

    &:last-child {
        margin-right: auto;

        @media (max-width: 983px) {
            margin-right: unset;
        }
    }
`

export const CatalogLink = styled(Link)`
    color: inherit;
    text-decoration: inherit;

    display: flex;
`

export const CardImage = styled.img`
    // width: 150px;
    margin-right: 24px;
    // height: auto;
`

export const CardTitle = styled.p`
    font-family: "Raleway";
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
`

export const Background = styled.div`
    background-image: url('../../backCab.png');
    background-position: right;
    background-repeat: repeat-y;
    background-size: 110vh;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
`