import React from 'react'
import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import useQuery from '../../../../hooks/useQuery'
import s from './Pagination.module.scss'

const PaginationCatalogue = ({
  activePage,
  setActivePage,
  allPages,
  setAllPages,
  activePages,
  setActivePages,
  data,
}) => {
  const query = useQuery()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!query.get('page')) {
      setActivePage(1)
    } else {
      setActivePage(+query.get('page'))
    }
  }, [query.get('page')])

  useEffect(() => {
    if (data) setAllPages([...Array(Math.ceil(data.length / 20)).keys()].map(x => ++x))
  }, [data])

  useEffect(() => {
    if (allPages.length < 5) {
      setActivePages([...allPages])
    } else {
      if (activePage < 4) {
        setActivePages([1, 2, 3, 4])
        return
      }
      if (activePage >= 4 && activePage < allPages.length - 2) {
        setActivePages([activePage - 1, activePage, activePage + 1, activePage + 2])
        return
      } else setActivePages([allPages.at(-4), allPages.at(-3), allPages.at(-2), allPages.at(-1)])
    }
  }, [data, activePage, allPages])

  return (
    <div className={s.pages}>
      {activePages &&
        activePages.map(page => (
          <span
            idx={page}
            to={`/shop/catalogue?page=${page}`}
            className={`${s.page} ${activePage === page ? s.active : ''}`}
            onClick={() => {
              setActivePage(+page)

              if (searchParams.get('industry')) {
                setSearchParams({industry: searchParams.get('industry'), page})
              } else if (searchParams.get('category')) {
                setSearchParams({category: searchParams.get('category'), page})
              } else {
                setSearchParams({page})
              }

              scrollIntoView(document.body.querySelector('.goods'), {
                time: 0,
                align: {
                  top: 0.1,
                },
              })
            }}
          >
            {page}
          </span>
        ))}
    </div>
  )
}

export default PaginationCatalogue
