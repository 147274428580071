export const management = {
  ru: {
    translation: {
      'Шилов Виталий Петрович': 'Шилов Виталий Петрович',
      'Директор ОАО «Беларускабель»': 'Директор ОАО «Беларускабель»',
      'Фиранчук Дмитрий Николаевич': 'Фиранчук Дмитрий Николаевич',
      'Зам. директора по развитию': 'Зам. директора по развитию',
      'Камеш Владимир Александрович': 'Камеш Владимир Александрович',
      'Зам. директора по снабжению и организации перевозок':
        'Зам. директора по снабжению и организации перевозок',
      'Барановский Сергей Григорьевич': 'Барановский Сергей Григорьевич',
      'Главный инженер': 'Заместитель директора - главный инженер',
      'Фещенко Светлана Леонидовна': 'Фещенко Светлана Леонидовна',
      'Заместитель директора по идеологической работе, кадрам и социальным вопросам':
        'Заместитель директора по идеологической работе, кадрам и социальным вопросам',
      'Белько Анна Александровна': 'Белько Анна Александровна',
      'Главный бухгалтер': 'Главный бухгалтер',
    },
  },
  en: {
    translation: {
      'Шилов Виталий Петрович': 'Shilov Vitaly Petrovich',
      'Директор ОАО «Беларускабель»': 'Director',
      'Фиранчук Дмитрий Николаевич': 'Firanchuk Dmitry Nikolaevich',
      'Зам. директора по развитию': 'Deputy Development Director',
      'Камеш Владимир Александрович': 'Kamesh Vladimir Alexandrovich',
      'Заместитель директора по снабжению и организации перевозок':
        `Deputy Director,\nProcurement and Logistics`,
        'Зам. директора по снабжению и организации перевозок':
        `Deputy Director,\nProcurement and Logistics`,        
      'Барановский Сергей Григорьевич': 'Baranovsky Sergey Grigorievich',
      'Главный инженер': 'Deputy director - Chief Engineer',
      'Директор':'Director',
      'Фещенко Светлана Леонидовна': 'Feshchenko Svetlana Leonidovna',
      'Заместитель директора по идеологической работе, кадрам и социальным вопросам':
        `Deputy director for ideological work,\npersonnel and social issues`,
      'Белько Анна Александровна': 'Belko Anna Alexandrovna',
      'Главный бухгалтер': 'Chief Accountant',
    },
  },
  bel: {
    translation: {
      'Шилов Виталий Петрович': 'Шылаў Віталь Пятровіч',
      'Директор ОАО «Беларускабель»': 'Дырэктар ААТ "Беларускабель"',
      'Фиранчук Дмитрий Николаевич': 'Фіранчук Дзмітрый Мікалаевіч',
      'Зам. директора по развитию': 'Нам. дырэктара па развіцці',
      'Камеш Владимир Александрович': 'Камеш Уладзімір Аляксандравіч',
      'Зам. директора по снабжению и организации перевозок':
        'Нам. дырэктара па забеспячэнні і арганізацыі перавозак',
      'Барановский Сергей Григорьевич': 'Бараноўскі Сяргей Рыгоравіч',
      'Главный инженер': 'Нам. дырэктара - галоўны інжынер',
      'Фещенко Светлана Леонидовна': 'Фешчанка Святлана Леанідаўна',
      'Заместитель директора по идеологической работе, кадрам и социальным вопросам':
        'Намеснік дырэктара па ідэалагічнай рабоце, кадрах і сацыяльных пытаннях',
      'Белько Анна Александровна': 'Белька Ганна Аляксандраўна',
      'Главный бухгалтер': 'Галоўны бухгалтар',
      'Директор':'Дырэктар',
    },
  },
}
