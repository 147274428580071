import React, {useEffect} from 'react'
import {useOutletContext} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import Advantages from '../Advantages/Advantages'
import Banner from '../Banner/Banner'
import MainSlider from '../MainSlider/MainSlider'
import SecondSlider from '../SecondSlider/SecondSlider'
import Slider from '../Slider/Slider'
import Types from '../Types/Types'
import s from './HomePage.module.scss'

const MainHomePage = () => {
  const {setBreadCrumb} = useOutletContext()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })

    setBreadCrumb([])
  }, [])

  return (
    <div className={s.body}>
      <MainSlider />
      <div className={s.container}>
        <Advantages />
        <Types />
        <Banner />
        <Slider title={'Лидеры продаж'} />
        <SecondSlider title={'Товары по скидке'} />
      </div>
    </div>
  )
}
export default MainHomePage
