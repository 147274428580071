import React from 'react'
import s from './Production.module.scss'
import ShowBacketItems from '../../../ui/ShowBacketItems/ShowBacketItems'
import {useTranslation} from 'react-i18next'

const Production = props => {
  const {t} = useTranslation()

  return (
    <div>
      <p className={s.title}>{t('Продукция в наличии')}</p>
      <ShowBacketItems />
    </div>
  )
}

export default Production
