import React from 'react'
import s from './Dillers.module.scss'
import {
  Layout,
  FaqQuestion,
  FaqAnswer,
  QuestionWrapper,
  ActiveBar,
  Wrapper,
  ArrowIcon,
} from "./styled"
import { useToggle } from "hooks/useToggle"
import AnimateHeight from "react-animate-height"

const TableWrapper = ({text, info}) => {
  const [isOpen, setIsOpen] = useToggle();
  return ( 
        
    <Layout isOpen={isOpen}>      
      <ActiveBar className="faq-active-bar" />
      <Wrapper className="wrapper-question">
        <QuestionWrapper>
          <ArrowIcon
            className="faq-arrow-icon"
            src="svg/arrow-right.svg"
            alt="arrow-right"
          />
          <FaqQuestion className="faq-question" onClick={setIsOpen}>
          {text}
          </FaqQuestion>
        </QuestionWrapper>
        <AnimateHeight duration={500} height={isOpen ? "auto" : 0}>
          <FaqAnswer className="faq-answer">
          <table className={s.table}>
          <tbody>
            {info.map(inf => (
              <tr className={s.tableItem}>
                <td>{inf.title}</td>
                <td>{inf.text}</td>
              </tr>
            ))}
          </tbody>
        </table>

          </FaqAnswer>
        </AnimateHeight>
      </Wrapper>
    </Layout>
  )
}

export default TableWrapper
