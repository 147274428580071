import React, {useState} from 'react'
import s from './SecondSlider.module.scss'
import './SecondSlider.css'
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react/swiper-react.js'
import {Navigation, Pagination} from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getProducts} from '../../../store/async/asyncCategories'
import {addToCart} from '../../../store/reducers/ShoppingReducer';

import provod from '../../../media/main/default.png'

const SecondSlider = ({title}) => {
  const [activeSlide, setActiveSlide] = useState(-1)
  const dispatch = useDispatch()
  const {language} = useSelector(store => store.language)

  const {data} = useSelector(store => {
    return store.products;
  });
  useEffect(() => {
    
    dispatch(getProducts(language))
    
  }, [language])
  
    var IdFilter = [
      "e1cec32a-72da-43a8-ae61-18f30d134c84",
      "9ceeae0b-4ea6-4e91-b1c0-7f8638673ff4",
      "2db00861-6f58-4e88-9e86-86224ccd1097",
      "9265be42-6851-478b-9479-648c3b23f3c0",
      "434907d2-e3c8-446a-97f5-452b25e9b5bf",
      "ec6803eb-6567-409f-9429-feb6d1f9a200",
      "bb4715ad-f934-402f-91d0-c9255495c84b",
      "e1c45fac-c132-4821-b8d3-dfd9cdc5476b",
      "1d5c703f-da86-4d2e-bfff-83a89657372c",
      "fbf75b79-fba7-424d-ad2c-03f2df680ead"

    ];

  var filtered = data.filter(item => IdFilter.includes(item.id))

  const SwiperButtonNext = ({children}) => {
    const swiper = useSwiper()
    return (
      <div
        className={`swiper-button-next popular ${
          swiper.activeIndex === 0 && 'swiper-button-disabled'
        }`}
        style={{display: 'none'}}
        onClick={() => swiper.slideNext()}
      ></div>
    )
  }

  const SwiperButtonPrev = ({children}) => {
    const swiper = useSwiper()
    return (
      <div
        className={`swiper-button-prev popular ${
          swiper.activeIndex === 0 && 'swiper-button-disabled'
        }`}
        style={{display: 'none'}}
        onClick={() => swiper.slidePrev()}
      ></div>
    )
  }

  return (
    <>
      <div className={`${s.wrapper} slider-swiper`}>
        <p className={s.title}>{title}</p>

        <Swiper
          pagination={true}
          modules={[Pagination, Navigation]}
          navigation={true}
          slidesPerView={4.5}
          slidesPerGroup={2}
          spaceBetween={15}
          onSlideChange={slide => {
            if (slide.activeIndex === 0) {
              setActiveSlide(-1)
            } else if (slide.isEnd) {
              setActiveSlide(1)
            } else {
              setActiveSlide(0)
            }
          }}
          autoHeight={true}
          breakpoints={{
            1050: {
              slidesPerView: 4.5,
            },

            800: {
              slidesPerView: 3,
            },

            520: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },

            100: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
          }}
        >
          
        { data &&
         filtered.map((card, idx) => (
          <SwiperSlide>
                <div className={s.card} key={idx}>
                  <div className={s.logoImage}>
                    <img
                      src={
                        card.image
                          ? card.image.includes('https://')
                            ? card.image
                            : process.env.REACT_APP_API + '/uploads/' + card.image
                          : provod
                      }
                      alt={card.name}
                      className={s.image}
                    />
                  </div>
                  {card.oldPrice &&
                    <p className={s.oldPrice}>
                    <s>{card.oldPrice}</s>
                    <span>{card.discount}</span>
                  </p>
                  }
                  {card.price &&
                    <p className={s.price}>{card.price}р</p>
                  }
                  {!card.price && 
                 
                    <p className={s.price}>По запросу</p>
                  }
                  <p className={s.name}>{card.name}</p>

                  <div className={s.flex}>
                    <Link to={'/shop/product/' + card.id}>Подробнее</Link>
                    <span className={s.img} onClick={() => 
                      dispatch(addToCart({id: card.id, title: card.name, img: card.img, price: card.price, quantity: 1}))
                    }>
                      <svg width="26" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g
                          clipPath="url(#prefix__clip0_131_103)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M9.764 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.08-.993-.595 0-1.078.445-1.078.993s.483.992 1.079.992zM21.63 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.079-.993s-1.079.445-1.079.993.483.992 1.079.992zM1.133.992h4.315L8.34 14.281c.1.457.37.867.764 1.16a2.28 2.28 0 001.394.438h10.486c.506.008 1-.147 1.394-.439.394-.292.665-.702.763-1.16l1.726-8.326H6.527" />
                        </g>
                        <defs>
                          <clipPath id="prefix__clip0_131_103">
                            <path
                              fill="#fff"
                              transform="translate(.055)"
                              d="M0 0h25.89v23.818H0z"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
                </SwiperSlide>
              ))
              }
          <SwiperButtonNext></SwiperButtonNext>
          <SwiperButtonPrev></SwiperButtonPrev>
        </Swiper>

        <div className={'swiper-arrows'}>
          <div
            className={`swiper-button-prev popular ${
              activeSlide === -1 && 'swiper-button-disabled'
            }`}
            onClick={() => document.querySelector('.swiper-button-prev.popular').click()}
          ></div>
          <div
            className={`swiper-button-next popular ${
              activeSlide === 1 && 'swiper-button-disabled'
            }`}
            onClick={() => document.querySelector('.swiper-button-next.popular').click()}
          ></div>
        </div>
      </div>

      <p className={s.link}>
        <Link to="/shop/actions">Подробнее</Link>
      </p>
    </>
  )
}

export default SecondSlider
