import React, { useEffect } from "react";
import s from "./ForInvestors.module.scss";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import scrollIntoView from "scroll-into-view";
import { useTranslation } from "react-i18next";

import Faq from "components/Faq";
import { config } from "./config";

export default function ForInvestors() {
  const { t } = useTranslation();

  useEffect(() => {
    scrollIntoView(document.body.querySelector("#root"), {
      time: 0,
      align: {
        top: 0.1,
      },
    });
  }, []);

  return (
    <>
      <div className={s.container}>
        <Breadcrumb className="custom-breadcrumb" style={{ marginTop: "38px" }}>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">{t("Меню Главная")}</Link>
          </span>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">{t("Меню Компания")}</Link>
          </span>
          <Breadcrumb.Item active>{t("Меню Акционерам")}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t("Меню Акционерам")}</div>
        
        {config.map(({ id, question, answer }) => (
          <Faq key={id} question={question} answer={answer} />
        ))}
      </div>
    </>
  );
}
