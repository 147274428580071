import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import {store} from './store/store'
import {Provider} from 'react-redux'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import './i18next'
import {AppSettingsProvider} from "./context/AppSettingsContext.jsx";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <AppSettingsProvider>
                <App />
            </AppSettingsProvider>
        </Provider>
      </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
