import {createAsyncThunk} from '@reduxjs/toolkit'
import {https} from '../../config'

export const registration = createAsyncThunk('authorize/registration', async info => {
    return await https
        .post('/registration', {
            email: info.email,
            password: info.password,
            role: 'auth',
            entity: info.entity,
            fullName: info.name,
        })
        .then(response => {
            return info
        })
})

export const auth = createAsyncThunk('authorize/auth', async info => {
    return await https
        .post('/user/auth', {
            email: info.email,
            password: info.password,
        })
        .then(response => {
            return response.data.data
        })
})

export const selfInfo = createAsyncThunk('authorize/selfInfo', async info => {
    return await https
        .get('/user/self', {
            headers: {
                Authorization: info.jwt_token,
            },
        })
        .then(response => response)
})
