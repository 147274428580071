import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import s from './Form.module.scss'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup
  .object({
    name: yup.string().required('Необходимо ввести имя'),
    email: yup.string().email('Неправильный email').required('Необходимо ввести email'),
    company: yup.string().required('Необходимо ввести название компании'),
    tel: yup
      .string()
      .matches(phoneRegExp, 'Неправильный номер телефона')
      .required('Необходимо ввести номер телефона'),
    password: yup.string().min(4, 'Слишком слабый пароль').required('Необходимо ввести пароль'),
    repeatPassword: yup
      .string()
      .min(4, 'Слишком слабый пароль')
      .required('Необходимо ввести пароль'),
  })
  .required()

const Form = ({registration}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  })

  const [handlePassword, setHandlePassword] = useState('')

  const onSubmit = data => {
    if (data.repeatPassword === data.password) {
      registration(data)
      setHandlePassword('')
    } else {
      setHandlePassword('Пароли не совпадают')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
      <h2 className={s.title}>Регистрация</h2>
      <input className={s.defaultInput} {...register('name')} placeholder="Имя" />
      {errors.name && <p>{errors.name?.message}</p>}
      <input
        className={s.defaultInput}
        {...register('company')}
        placeholder="Наименование компании"
      />
      {errors.company && <p>{errors.company?.message}</p>}
      <input className={s.defaultInput} {...register('tel')} placeholder="Телефон" />
      {errors.tel && <p>{errors.tel?.message}</p>}
      <input className={s.defaultInput} {...register('email')} placeholder="Адрес e-mail" />
      {errors.email && <p>{errors.email?.message}</p>}
      <input
        className={s.defaultInput}
        type="password"
        {...register('password')}
        placeholder="Пароль"
      />
      {errors.password && <p>{errors.password?.message}</p>}
      <input
        className={s.defaultInput}
        {...register('repeatPassword')}
        placeholder="Повторите пароль"
        type="password"
      />
      {handlePassword && <p>{handlePassword}</p>}
      <button type="submit" className={s.button}>
        Регистрация
      </button>
    </form>
  )
}

export default Form
