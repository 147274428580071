import {memo, useCallback, useMemo} from "react";
import {Button, Card, Layout, notification, Popconfirm, Space, Table} from "antd";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getAllSubCategories, removeSubCategory} from "../api.js";
import {CreateSubCategoryModal} from "./CreateSubCategoryModal.jsx";
import {EditSubCategoryModal} from "./EditSubCategoryModal.jsx";

export const SubCategoryTable = memo(({jwt}) => {
    const [api, contextHolder] = notification.useNotification();
    const queryClient = useQueryClient();
    const subCategoriesQuery = useQuery({ queryKey: ['subCategory', 'all'], queryFn: getAllSubCategories });
    const deleteSubCategoryMutation = useMutation({
        mutationFn: removeSubCategory,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['subCategory', 'all'] }),
    });

    const dataSource = useMemo(() => {
        if (subCategoriesQuery.data?.length) {
            return subCategoriesQuery.data;
        }
        return [];
    }, [subCategoriesQuery.data]);

    const onDeleteHandler = useCallback((id, name) => async () => {
        try {
            await deleteSubCategoryMutation.mutateAsync(id);
            api.success({ message: `Подкатегория "${name}" удалена!` });
        } catch (e) {
            api.error({ message: 'Произошла ошибка при удалении подкатегории'})
            console.error(e);
        }
    }, []);

    const columns = useMemo(() => ([
        {
            title: "Название",
            dataIndex: 'name'
        },
        {
            title: 'Используется в категориях #',
            dataIndex: 'categoryCount',
        },
        {
            key: 'Actions',
            dataIndex: 'id',
            render: (id, subcategory) => (
                <Space.Compact>
                    <EditSubCategoryModal subCategory={subcategory} />
                    <Popconfirm
                        title="Удалить товар?"
                        description="Это действие нельзя будет отменить"
                        onConfirm={onDeleteHandler(id, subcategory.name)}
                        okText="Удалить"
                        cancelText="Отмена"
                    >
                        <Button danger type="primary" size="small" loading={deleteSubCategoryMutation.isLoading}>
                            Удалить
                        </Button>
                    </Popconfirm>
                </Space.Compact>
            )
        }
    ]), []);
    return (
        <Layout>
            {contextHolder}
            <Layout.Content>
                <Card title="Все подкатегории">
                    <Space direction="vertical">
                        <CreateSubCategoryModal jwt={jwt} />
                        <Table
                            dataSource={dataSource}
                            columns={columns}
                            rowKey="_id"
                            loading={subCategoriesQuery.isLoading}
                        />
                    </Space>
                </Card>
            </Layout.Content>
        </Layout>
    );
});
