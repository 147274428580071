import {createContext, useContext, useEffect, useState} from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(() => localStorage.getItem("jwt_token") || null);

    useEffect(() => {
        const jwt = localStorage.getItem('jwt_token');
        setToken(jwt);
    }, []);

    return (
        <AuthContext.Provider value={{ token }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
