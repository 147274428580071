import './Calculator.css'
import {v4 as uuidv4} from 'uuid'
import {Row, Col, Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import scrollIntoView from 'scroll-into-view'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import {useTranslation} from 'react-i18next'

export default function Calculator() {
  const {t} = useTranslation()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  const infoList = [
    {
      label: `${t('ТОКОПРОВОДЯЩАЯ ЖИЛА')}`,
      items: [
        [uuidv4(), `${t('ОП Алюминиевая')}`],
        [uuidv4(), `${t('Медная мягкая проволока класса 4 по ГОСТ 22483-77')}`],
        [uuidv4(), `${t('ОП Медная')}`],
        [uuidv4(), `${t('Медная проволока')}`],
        [uuidv4(), `${t('Медная луженая проволока')}`],
        [uuidv4(), `${t('Медная проволока, лужёная ПОСом')}`],
        [uuidv4(), `${t('Медная мягкая проволока')}`],
        [uuidv4(), `${t('Медная проволока, луженая оловом')}`],
      ],
    },
    {
      label: `${t('ЗАГ ИЗОЛЯЦИЯ')}`,
      items: [
        [uuidv4(), `${t('Полимерные композиции не содержащие галогенов')}`],
        [uuidv4(), `${t('Нить полиэфирная')}`],
        [uuidv4(), `${t('Полиэтилен низкого давления')}`],
        [uuidv4(), `${t('Сшитый полиэтилен')}`],
        [uuidv4(), `${t('ОП Полиэтилен')}`],
        [uuidv4(), `${t('ПВХ пластикат пониженной пожароопасности')}`],
        [uuidv4(), `${t('Радиационносшитый полиэтилен')}`],
        [uuidv4(), `${t('Термостабилизированный радиационносшитый полиэтилен')}`],
        [uuidv4(), `${t('Внутренний слой - ПВХ')}`],
        [uuidv4(), `${t('Поливинилхлоридный пластикат')}`],
        [uuidv4(), `${t('Радиационносшитый фторпласт 2М')}`],
        [uuidv4(), `${t('Наружный слой - ПВХ повышенной теплостойкости')}`],
        [uuidv4(), `${t('ОП Полипропилен')}`],
        [uuidv4(), `${t('ОП Стекловолокно')}`],
      ],
    },
    {
      label: `${t('ОБОЛОЧКА КАБЕЛЯ')}`,
      items: [
        [uuidv4(), `${t('Полимерные композиции не содержащие галогенов')}`],
        [uuidv4(), `${t('Поливинилхлоридный пластикат не распространяющий горение')}`],
        [uuidv4(), `${t('ПВХ пластикат пониженной горючести')}`],
        [uuidv4(), `${t('Поливинилхлоридный пластикат повышенной теплостойкости')}`],
        [uuidv4(), `${t('ПВХ пластикат пониженной пожароопасности')}`],
        [uuidv4(), `${t('Светостабилизированный полиэтилен')}`],
        [uuidv4(), `${t('Поливинилхлоридный пластикат')}`],
        [uuidv4(), `${t('ОП Полиэтилен')}`],
      ],
    },
    {
      label: `${t('С ЭКРАНОМ')}`,
      items: [[uuidv4(), `${t('ДА ОП')}`]],
    },
    {
      label: `${t('НОМИНАЛЬНОЕ СЕЧЕНИЕ ЖИЛ, ММ²')}`,
      items: [
        [uuidv4(), '0.05'],
        [uuidv4(), '0.14'],
        [uuidv4(), '0.30'],
        [uuidv4(), '0.5'],
        [uuidv4(), '0.75'],
        [uuidv4(), '1.2'],
        [uuidv4(), '2.5'],
        [uuidv4(), '6.0'],
        [uuidv4(), '150'],
        [uuidv4(), '25'],
        [uuidv4(), '95'],
        [uuidv4(), '0.08'],
        [uuidv4(), '0.15'],
        [uuidv4(), '0.32'],
        [uuidv4(), '0.60'],
        [uuidv4(), '0.8'],
        [uuidv4(), '1.5'],
        [uuidv4(), '3.0'],
        [uuidv4(), '8.0'],
        [uuidv4(), '16'],
        [uuidv4(), '35'],
        [uuidv4(), '0.10'],
        [uuidv4(), '0.2'],
        [uuidv4(), '0.35'],
        [uuidv4(), '0.64'],
        [uuidv4(), '0.8'],
        [uuidv4(), '1.8'],
        [uuidv4(), '4.0'],
        [uuidv4(), '10'],
        [uuidv4(), '185'],
        [uuidv4(), '50'],
        [uuidv4(), '0.10'],
        [uuidv4(), '0.25'],
        [uuidv4(), '0.40'],
        [uuidv4(), '0.7'],
        [uuidv4(), '1.0'],
        [uuidv4(), '2.0'],
        [uuidv4(), '5.0'],
        [uuidv4(), '120'],
        [uuidv4(), '240'],
        [uuidv4(), '70'],
      ],
    },
    {
      label: `${t('ЧИСЛО ЖИЛ')}`,
      items: [
        [uuidv4(), '-'],
        [uuidv4(), '14'],
        [uuidv4(), '2'],
        [uuidv4(), '27'],
        [uuidv4(), '37'],
        [uuidv4(), '7'],
        [uuidv4(), '1'],
        [uuidv4(), '16'],
        [uuidv4(), '20'],
        [uuidv4(), '3'],
        [uuidv4(), '4'],
        [uuidv4(), '8'],
        [uuidv4(), '10'],
        [uuidv4(), '18'],
        [uuidv4(), '24'],
        [uuidv4(), '30'],
        [uuidv4(), '5'],
        [uuidv4(), '9'],
        [uuidv4(), '12'],
        [uuidv4(), '19'],
        [uuidv4(), '26'],
        [uuidv4(), '33'],
        [uuidv4(), '6'],
      ],
    },
    {
      label: `${t('ЧИСЛО ПАР')}`,
      items: [
        [uuidv4(), '-'],
        [uuidv4(), '12'],
        [uuidv4(), '2'],
        [uuidv4(), '3'],
        [uuidv4(), '7'],
        [uuidv4(), '30'],
        [uuidv4(), '1'],
        [uuidv4(), '14'],
        [uuidv4(), '20'],
        [uuidv4(), '4'],
        [uuidv4(), '8'],
        [uuidv4(), '37'],
        [uuidv4(), '10'],
        [uuidv4(), '16'],
        [uuidv4(), '21'],
        [uuidv4(), '5'],
        [uuidv4(), '9'],
        [uuidv4(), '103'],
        [uuidv4(), '11'],
        [uuidv4(), '19'],
        [uuidv4(), '24'],
        [uuidv4(), '6'],
        [uuidv4(), '27'],
        [uuidv4(), '41'],
      ],
    },
  ]

  return (
    <>
      <div className="calculator-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Подбор продукции по характеристикам')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels addMargins">{t('Подбор продукции по характеристикам')}</div>
        <div className="info-container">
          {infoList.map(info => (
            <Row className="info-container-row">
              <div className="info-label">{info.label}</div>
              {info.items.map(values => (
                <Col lg={3} md={4} sm={6} xm={12} className="info-container-col">
                  <input type="checkbox" id={values[0]} name="val" />
                  <label for={values[0]}>{values[1]}</label>
                </Col>
              ))}
            </Row>
          ))}
        </div>
        <Row className="calculator-buttons">
          <button className="calculator-button">{t('Кнопка Подобрать')}</button>
          <button className="calculator-button">{t('Кнопка Очистить')}</button>
        </Row>
      </div>
    </>
  )
}
