import React, {useState} from 'react'
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react/swiper-react.js'
import s from './MainSlider.module.scss'
import {Link} from 'react-router-dom'
import {Navigation, Pagination} from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import logo from '../../../media/main/logo.svg'
import './MainSlider.css'

import first from '../../../media/main/slider/01.jpg'
import second from '../../../media/main/slider/02.jpg'
import third from '../../../media/main/slider/03.jpg'
import backet from '../../../media/main/backet1.svg'

const MainSlider = props => {
  const [activeSlide, setActiveSlide] = useState(-1)

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>'
    },
  }

  const SwiperButtonNext = ({children}) => {
    const swiper = useSwiper()
    return (
      <div
        className={`swiper-button-next arrow ${
          swiper.activeIndex === 0 && 'swiper-button-disabled'
        }`}
        style={{display: 'none'}}
        onClick={() => swiper.slideNext()}
      ></div>
    )
  }

  const SwiperButtonPrev = ({children}) => {
    const swiper = useSwiper()
    return (
      <div
        className={`swiper-button-prev arrow ${
          swiper.activeIndex === 0 && 'swiper-button-disabled'
        }`}
        style={{display: 'none'}}
        onClick={() => swiper.slidePrev()}
      ></div>
    )
  }

  return (
    <div className={`${s.wrapper} main-slider-swiper`}>
      <div className={s.swiperWrapper}>
        <div className={`${s.swiper} custom_homepage_swiper`}>
          <Swiper
            pagination={pagination}
            modules={[Pagination, Navigation]}
            navigation={true}
            spaceBetween={15}
            onSlideChange={slide => {
              if (slide.activeIndex === 0) {
                setActiveSlide(-1)
              } else if (slide.isEnd) {
                setActiveSlide(1)
              } else {
                setActiveSlide(0)
              }
            }}
          >
            <SwiperSlide>
              <img src={second} alt="" className={s.slideBackground}/>
              <div className={s.swiperWrap}>
                <p className={`${s.title} ${s.modify}`}>Интернет-магазин розничной торговли кабельно-проводниковой продукции напрямую от ОАО “Беларускабель”</p>
                <div className={s.cards}>
                  <p className={s.card}>Качественное сырье</p>
                  <p className={s.card}>гарантия</p>
                </div>
                <div className={s.cards}>
                  <p className={s.card}>ГОст стандарт</p>
                  <p className={s.card}>уровень технологии</p>
                </div>
                <div className={s.button}>
                  <Link to="/shop/catalogue"><span className={s.btn}><img src={backet}/> Перейти в каталог</span></Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={first} alt="" className={s.slideBackground}/>
              <div className={s.swiperWrap}>
                <p className={s.title} style={{color: "#000"}}>Высокое качество и привлекательные цены!</p>
                <p className={`${s.paragraph} ${s.black}`}>
                  Интернет магазин “Беларускабель” представляет продукцию с гарантией от производителя
                  и по выгодной стоимости.
                </p>
                <div className={s.button}>
                  <Link to="/shop/catalogue"><span className={s.btn}><img src={backet}/> Перейти в каталог</span></Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={third} alt="" className={s.slideBackground}/>
              <div className={s.swiperWrap} style={{width: '550px', marginLeft: '75px', padding: 0, background: '#fff', borderRadius: '20px', marginTop: '70px'}}>
                <Link to="/shop/catalogue">
                  <img src={logo} alt="Logo" style={{ padding: '40px 0 0 20px'}}/>
                </Link>
                <p className={s.title} style={{fontSize: 30, lineHeight: '35px', padding: '0 0 0 20px', color: '#000', width: '73%', fontWeight: 600, marginTop: '30px', marginBottom: 0}}>Безопасная электропроводка - безопасная жизнь!</p>
                <div className={`${s.button} ${s.unique}`} style={{padding: '0 20px 20px 20px'}}>
                  <Link to="/shop/catalogue"><span className={s.btn}>Подробнее</span></Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperButtonNext></SwiperButtonNext>
            <SwiperButtonPrev></SwiperButtonPrev>
          </Swiper>
          <div className={'swiper-arrows'}>
            <div
              className={`swiper-button-prev arrow ${
                activeSlide === -1 && 'swiper-button-disabled'
              }`}
              onClick={() => document.querySelector('.swiper-button-prev.arrow').click()}
            ></div>
            <div
              className={`swiper-button-next arrow ${
                activeSlide === 1 && 'swiper-button-disabled'
              }`}
              onClick={() => document.querySelector('.swiper-button-next.arrow').click()}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainSlider
