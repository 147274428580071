import React from 'react'
import {useEffect} from 'react'
import AdminTitle from '../../ui/AdminTitle/AdminTitle'
import Button from '../../ui/Button/Button'
import s from './Table.module.scss'
import TableForm from './TableForm/TableForm'

const Table = ({values, setValues}) => {
  return (
    <div className={s.tableWrapper}>
      <AdminTitle title="Размеры товара" />
      <table>
        <tbody className={s.table}>
          <tr>
            <th>
              Номинальное сечение жил, мм<sup>2</sup>
            </th>
            <th>Число жил</th>
            <th>Максимальный диаметр, мм </th>
            <th>Масса, кг/км</th>
            <th>Конструкция жилы</th>
          </tr>
          {values.map((val, idx) => (
            <tr key={idx}>
              <TableForm id={idx} setValues={setValues} values={values} activeValues={val} />
            </tr>
          ))}
        </tbody>
      </table>

      <div
        className={s.btn}
        onClick={e => {
          e.preventDefault()
          setValues([...values, ['', '', '', '', '']])
        }}
      >
        <Button text="Добавить строку" small />
      </div>
    </div>
  )
}

export default Table
