import React, {useEffect} from 'react'
import s from './Production.module.scss'
import file from '../../../media/main/backet/file.svg'
import bin from '../../../media/main/backet/bin.svg'
import letters from '../../../media/main/backet/letters.svg'
import packet from '../../../media/main/backet/packet.svg'
import rub from '../../../media/main/backet/rub.svg'

import { useDispatch, useSelector } from 'react-redux'
import { removeItem } from 'store/reducers/ShoppingReducer'


const Production = props => {
  
  const dispatch = useDispatch()

  const cart = useSelector((state) => state.persistedReducer.cart)
  const getRowTotal = (quantity, price) => {
    let total = quantity * price;
    return total.toFixed(2);
  }
  return (
    <div>
      <p className={s.title}>Продукция в наличии</p>
      <table className={s.table}>
        <tr>
          <th className={s.tableHeader}>
            <img className={s.tableImg} src={file} alt="Наименование" />
            <span>Наименование</span>
          </th>
          <th className={s.tableHeader}>
            <img className={s.tableImg} src={rub} alt="Цена" />
            <span>Цена</span>
          </th>
          <th className={s.tableHeader}>
            <img className={s.tableImg} src={letters} alt="Количество" />
            <span>Количество (м)</span>
          </th>
          <th className={s.tableHeader}>
            <img className={s.tableImg} src={packet} alt="Стоимость" />
            <span>Стоимость</span>
          </th>
          <th></th>
        </tr>
        {cart && cart.map(item => (
        <tr>
          <td className={s.firstCell} key={item.id}>
            <div className={s.flexCard}>
              <img className={s.img} src="/publicPages/provod.png" alt="Иконка провода" />
              <p className={s.cardTitle}>{item.name}</p>
            </div>
            <p className={s.paragraph}>{item.purpose}</p>
          </td>
          <td>
            <p className={s.price}>{item.price ? item.price + 'р' : 'отсутс.'}</p>
                    <div className={s.flexDiscount}>
                      <span className={s.oldPrice}>
                        <s>отсутс.</s>
                      </span>
            </div>
          </td>
          <td>
            <span className={s.amount}>{item.quantity}</span>
          </td>
          <td>
            <span className={s.result}>{getRowTotal(item.quantity, item.price)}р.</span>
          </td>
          <td>
            <img src={bin} alt="Удалить элемент" onClick={() => {
                      dispatch(removeItem(item.id))
                    }}/>
          </td>
        </tr>
        ))}
      </table>
      <div className={s.mobile}>
        <div className={s.flexMobile}>
          <img className={s.imgMobile} src="/publicPages/provod.png" alt="Иконка провода" />
          <div>
            <p className={s.cardTitle}>МКЭШМнг (А)-LS</p>
            <p className={s.paragraph}>Номинальное сечение жил, мм² - 0.35; Число жил - 10 </p>
          </div>
        </div>
        <div className={s.flexWrapper}>
          <p>Цена</p>

          <div className={s.flexDiscount}>
            <p className={s.price}>299р</p>
            <span className={s.oldPrice}>
              <s>599р</s>
            </span>
            <span className={s.discount}>20%</span>
          </div>
        </div>
        <div className={s.flexWrapper}>
          <p>Количество</p>

          <span className={s.amount}>40</span>
        </div>
        <div className={s.flexWrapper}>
          <p>Итого</p>

          <span className={s.result}>2190р</span>
        </div>
      </div>
    </div>
  )
}

export default Production
