import {lazy, memo, Suspense, useEffect, useState} from "react";
import {Button, Space, Spin, Tooltip} from 'antd';
import {useQuery} from "@tanstack/react-query";
import {getAllMedia} from "../../../api/media.endpoint.js";
import {AppstoreOutlined, MenuOutlined} from "@ant-design/icons";
import UploadMediaForm from "./UploadMediaForm.jsx";

const MediaCardViewMode = lazy(() => import("./MediaCardViewMode"));
const MediaListViewMode = lazy(() => import("./MediaListViewMode"));

const VIEW_MODE_CARD = 'CARD_VIEW';
const VIEW_MODE_LIST = 'LIST_VIEW';

const VIEW_MODE = {
    card: VIEW_MODE_CARD,
    list: VIEW_MODE_LIST,
};

const MediaManagement = memo(({
    isFullPage = false,
    selectedMediaId,
    onChange
}) => {
    const [viewMode, setViewMode] = useState(VIEW_MODE.card);
    const [selectedImage, setSelectedImage] = useState(undefined);

    const mediaQuery = useQuery({
        queryKey: ["media", "all"],
        queryFn: getAllMedia,
        staleTime: 1000 * 60 * 3, // 3 minutes
        onSuccess: (data) => {
            if (selectedMediaId && data.length) {
                const idx = data.findIndex(media => media._id === selectedMediaId);
                if (idx !== -1) {
                    setSelectedImage(data[idx]);
                }
            }
        },
    });

    useEffect(() => {
        onChange?.(selectedImage);
    }, [selectedImage]);

    if (mediaQuery.isFetching) {
        return <Spin />;
    }

    const onSelectMedia = (media) => {
        if (media._id === selectedImage?._id) {
            setSelectedImage(undefined);
        } else {
            setSelectedImage(media);
        }
    };

    return (
        <div>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Space direction="horizontal" style={{ width: "100%" }}>
                    <Space.Compact>
                        <Tooltip title="Показать в виде картинок">
                            <Button onClick={() => setViewMode(VIEW_MODE.card)} disabled={viewMode === VIEW_MODE.card}>
                                <AppstoreOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Показать в виде списка">
                            <Button onClick={() => setViewMode(VIEW_MODE.list)} disabled={viewMode === VIEW_MODE.list}>
                                <MenuOutlined />
                            </Button>
                        </Tooltip>
                    </Space.Compact>

                    {isFullPage ? <UploadMediaForm /> : null}
                </Space>

                <div style={{ display: "flex", gap: 8 }}>
                    <b>Выбран:</b>
                    <span>{selectedImage?.path}</span>
                    {selectedImage?.path ? <Button size="small" onClick={() => setSelectedImage(undefined)} disabled={!selectedImage} type="link">Отменить</Button> : null}
                </div>
                <Suspense fallback={<Spin />}>
                    {viewMode === VIEW_MODE.card ? (
                        <MediaCardViewMode
                            isFullPage={isFullPage}
                            mediaList={mediaQuery.data}
                            selectedImage={selectedImage}
                            onSelectImage={onSelectMedia}
                        />
                    ) : (
                        <MediaListViewMode
                            isFullPage={isFullPage}
                            mediaList={mediaQuery.data}
                            selectedImage={selectedImage}
                            onSelectImage={onSelectMedia}
                        />
                    )}
                </Suspense>
            </Space>
        </div>
    )
});

export default MediaManagement;
