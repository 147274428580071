import "./PressCard.css";
import { Row, Col, Image, Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import scrollIntoView from "scroll-into-view";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import { useSelector } from "react-redux";

export default function PressCard() {
  const news = useSelector((store) => store.news);
  const params = useParams();
  const { date, img, paragraph, title } = news.filter(
    (n) => n.id === +params.id
  )[0];

  useEffect(() => {
    scrollIntoView(document.body.querySelector("#root"), {
      time: 0,
      align: {
        top: 0.1,
      },
    });
  }, []);

  return (
    <>
      <div className="press-card-page-conteiner">
        <Breadcrumb className="custom-breadcrumb" style={{ marginTop: "38px" }}>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">Главная</Link>
          </span>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">Компания</Link>
          </span>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/presscenter">Новости</Link>
          </span>
          <Breadcrumb.Item active>{title}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">Новости</div>
        <Row className="press-card-page-row">
          <Col className="press-card-page-col">
            <Row>
              <p>{date}</p>
              <p>
                <span className="press-card-name">{title}</span>
              </p>

              <p
                className="press-card-info-text"
                dangerouslySetInnerHTML={{ __html: paragraph }}
              ></p>
              <Link to="/presscenter">
                <span className="press-card-local-link">Все новости</span>
              </Link>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
