import {Button, Checkbox, Divider, Form, Input, Space} from "antd";
import {useCallback, useMemo, useRef, useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {saveSettings} from "../../../../api/settings.endpoint.js";
import {sendFeedback, validateEmailSettings} from "../../../../api/email.endpoint.js";

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export const EmailSettingsForm = ({ settings, notification }) => {
    const [form] = Form.useForm();
    const [testFeedbackBtnDisabled, setTestFeedbackBtnDisabled] = useState(true);

    const queryClient = useQueryClient();
    const saveSettingsMutation = useMutation({
        mutationFn: saveSettings,
        onSuccess: () => {
            notification.success({message: 'Конфигурация успешно сохранена'})
            queryClient.invalidateQueries(['settings', 'private', 'all'])
        }
    });
    const validateSettingsMutation = useMutation({
        mutationFn: validateEmailSettings,
        onError: error => {
            notification.error({message: error.message});
        },
        onSuccess: (data) => {
            if (data.success === false && data.error) {
                notification.error({message: data.error});
            } else {
                notification.success({message: 'Корректная конфигурация email сервиса'});
            }
        },
    });

    const sendTestFeedbackMutation = useMutation({
        mutationFn: sendFeedback,
        onSuccess: () => {
            form.resetFields(['testFeedback'])
            notification.success({ message: 'Тестовое письмо отправлено'});
        },
        onError: (error) => notification.error({message: error.message}),
    });

    const onFormSubmit = useCallback(async (values) => {
        delete values['testFeedback'];
        await saveSettingsMutation.mutateAsync({key: "EMAIL_SETTINGS", value: values })
    }, []);


    const saveAndTestConfig = useCallback(async () => {
        try {
            await form.validateFields();
            const formValues = form.getFieldsValue();
            delete formValues['testFeedback'];
            await saveSettingsMutation.mutateAsync({key: "EMAIL_SETTINGS", value: formValues });
            await validateSettingsMutation.mutateAsync();
        } catch (e) {
            console.error('error', e);
        }
    }, [form]);

    const testFeedback = useCallback(async () => {
        const emailValue = form.getFieldValue('testFeedback');
        const now = new Date();

        if (emailValue && isValidEmail(emailValue)) {
            await sendTestFeedbackMutation.mutateAsync({
                company: "[Test] Company name",
                name: "[Test] User name",
                email: emailValue,
                phone: "[Test] +375123000000",
                text: `[Test] Lorem:50: ${now.toLocaleDateString('ru-RU')} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`,
            });
        }
    }, [form]);

    const onTestFeedbackEmailChanged = (event) => {
        setTestFeedbackBtnDisabled(isValidEmail(event.target.value) === false);
    };

    return (
        <Form form={form} name="email-settings-form" {...layout} labelAlign="left" initialValues={settings}
              onFinish={onFormSubmit} disabled={saveSettingsMutation.isLoading || validateSettingsMutation.isLoading}>
            <Form.Item
                label="SMTP Host"
                name="host"
                rules={[{required: true, message: "Введите SMTP хост"}]}
            >
                <Input placeholder="smtp.example.com"/>
            </Form.Item>

            <Form.Item
                label="SMTP Порт"
                name="port"
                rules={[{required: true, message: "Введите SMTP порт"}]}
            >
                <Input type="number" placeholder="587"/>
            </Form.Item>

            <Form.Item
                label="Безопасное соединение (TLS)"
                name="secure"
                valuePropName="checked"
            >
                <Checkbox>Использовать безопасное соединение</Checkbox>
            </Form.Item>

            <Form.Item
                label="Логин"
                name={["auth", "user"]}
                rules={[{required: true, message: "Введите логин"}]}
            >
                <Input placeholder="your-email@example.com"/>
            </Form.Item>

            <Form.Item
                label="Пароль"
                name={["auth", "pass"]}
                rules={[{required: true, message: "Введите пароль"}]}
            >
                <Input.Password placeholder="Введите пароль"/>
            </Form.Item>

            <Divider />

            <Form.Item
                label="Получатель писем формы обратной связи"
                name={["feedback", "to"]}
                rules={[{required: true, message: "Введите email получателя"}, {
                    type: "email",
                    message: 'Введите корректный адрес почты'
                }]}
            >
                <Input placeholder="to@example.com"/>
            </Form.Item>

            <Form.Item
                label="Копия писем формы обратной связи"
                name={["feedback", "cc"]}
                rules={[{type: "email", message: 'Введите корректный адрес почты'}]}
            >
                <Input placeholder="cc@example.com"/>
            </Form.Item>

            <Form.Item
                label="Отправитель писем формы обратной связи"
                name={["feedback", "from"]}
                rules={[{required: true, message: "Введите email получателя"}, {
                    type: "email",
                    message: 'Введите корректный адрес почты'
                }]}
                extra="Рекомендуется использовать тот же адрес почты что и в поле Логин"
            >
                <Input placeholder="no-reply@example.com"/>
            </Form.Item>

            <Form.Item
                label="Тема писем формы обратной связи"
                name={["feedback", "subject"]}
                rules={[{required: true, message: "Введите тему письма"}]}
            >
                <Input placeholder="Запрос с формы обратной связи"/>
            </Form.Item>

            <Form.Item
                label="Отправить тестовое письмо обратной связи"
                name="testFeedback"
                rules={[{type: "email", message: 'Введите корректный адрес почты'}]}
            >
                <Space.Compact block>
                    <Input placeholder="destination@example.com" onChange={onTestFeedbackEmailChanged} disabled={sendTestFeedbackMutation.isLoading} />
                    <Button onClick={testFeedback} loading={sendTestFeedbackMutation.isLoading} disabled={testFeedbackBtnDisabled}>Отправить</Button>
                </Space.Compact>
            </Form.Item>

            <Divider />

            <Form.Item>
                <Space>
                    <Button type="dashed" onClick={saveAndTestConfig} loading={validateSettingsMutation.isLoading}>Сохранить
                        и проверить</Button>
                    <Button>Отмена</Button>
                    <Button type="primary" htmlType="submit" loading={saveSettingsMutation.isLoading}>Сохранить</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
