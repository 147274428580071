import React, {useCallback, useEffect} from 'react'
import {useState} from 'react'
import AdminTitle from '../ui/AdminTitle/AdminTitle'
import s from './Category.module.scss'
import DefaultForm from './DefaultForm/DefaultForm'
import Select from '../ui/Select/Select'
import Button from '../ui/Button/Button'
import img from '../img/backet.svg'
import activeImg from '../img/backet0.svg'
import SimpleForm from './SimpleForm/SimpleForm'
import {getCategories, getIndustries, getSubCategories} from '../../../store/async/asyncCategories'
import {useDispatch, useSelector} from 'react-redux'
import {
  removeCategory,
  removeIndustry,
  removeSubcategory,
  setIndustries,
  updateCategory
} from '../../../store/async/asyncAdmin'
import IndustryForm from './IndustryForm/IndustryForm'
import {Form} from "react-bootstrap";

const Category = ({jwt, part}) => {
  const [activeCategory, setActiveCategory] = useState(false)
  const [editCategory, setEditCategory] = useState(null);
  const [activeIndustry, setActiveIndustry] = useState(false)
  const [activeSubCategory, setActiveSubCategory] = useState(false)
  const [activeDeleteElement, setActiveDeleteElement] = useState(0)
  const [activeDeleteIndustry, setActiveDeleteIndustry] = useState(0)
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const dispatch = useDispatch()
  const {data, subCategories, industries} = useSelector(store => store.categories)

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getSubCategories())
    dispatch(getIndustries())
  }, [])

  const onEditCategoryChangeHandler = useCallback((e) => {
    const { value } = e?.target;
    if (!value) {
      setEditCategory(null);
      return;
    }

    const category = data?.find(cat => cat.id === value);
    setEditCategory(category);
  }, [data]);

  const onEditCategoryFormSubmit = useCallback((e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const originalCategory = data?.find((cat) => cat.id === editCategory.id);
    const newCategoryName = formData.get('newCategoryName');

    if (originalCategory?.name !== newCategoryName) {
      console.log(originalCategory);
      dispatch(updateCategory({ id: originalCategory.id, jwt, name: newCategoryName }));
    }
  }, [data, editCategory, dispatch, jwt]);
  
  const deleteCategory = () => {
    dispatch(removeCategory({id: activeDeleteElement, jwt})).then(() => dispatch(getCategories()))
    setActiveDeleteElement(0)
  }

  const deleteIndustry = () => {
    dispatch(removeIndustry({id: activeDeleteIndustry, jwt})).then(() => dispatch(getIndustries()))
    setActiveDeleteIndustry(0)
  }

  const deleteSubCategories = () => {
    console.log('x', activeSubCategory)
    dispatch(removeSubcategory({id: activeSubCategory, jwt})).then(() => dispatch(getSubCategories()));
    setActiveSubCategory(0);
  }

  return (
    <div className={s.container}>
      <div
        className={`${s.sheet} ${isSelectOpen ? s.active : ''}`}
        onClick={() => {
          setIsSelectOpen(false)
        }}
      ></div>
      <div className={s.block}>
        <AdminTitle title="Добавить категорию"/>
        <SimpleForm jwt={jwt}/>

        <AdminTitle title="Добавить подкатегорию"/>
        <DefaultForm jwt={jwt} values={data}/>

        <div className={s.margin}>
          <AdminTitle title="Редактировать категорию"/>
          <Form onSubmit={onEditCategoryFormSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Выберите категорию которую необходимо изменить</Form.Label>
              <Form.Select onChange={onEditCategoryChangeHandler} defaultValue={0}>
                <option disabled value={0}>Выберите категорию</option>
                {data?.map((category) => <option key={category.id} value={category.id}>{category.name}</option>)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Новое название категории</Form.Label>
              <Form.Control name="newCategoryName" id="newCategoryName" required disabled={!editCategory} defaultValue={editCategory?.name} />
            </Form.Group>
            <Form.Group>
              <Button text="Сохранить изменения" type="submit" disabled={!editCategory}/>
            </Form.Group>
          </Form>
        </div>

        <div className={s.margin}>
          <AdminTitle title="Удалить категорию"/>
          <Select
              activeValue={activeCategory}
              setActiveValue={setActiveCategory}
              values={data}
              placeholder={'Выберите категорию'}
              setIsSelectOpen={setIsSelectOpen}
              isSelectOpen={isSelectOpen}
              id={1}
              setActiveDeleteElement={setActiveDeleteElement}
          />
          <div onClick={() => deleteCategory()}>
            <Button text={'Удалить категорию'} img={img} activeImg={activeImg}/>
          </div>
        </div>
        <div className={s.margin}>
          <AdminTitle title="Удалить подкатегорию "/>
          <Select
              activeValue={activeSubCategory}
              setActiveValue={setActiveSubCategory}
              placeholder={'Выберите подкатегорию'}
              values={subCategories}
              setIsSelectOpen={setIsSelectOpen}
              isSelectOpen={isSelectOpen}
              id={2}
              setActiveDeleteElement={setActiveSubCategory}
          />
          <div onClick={deleteSubCategories}>
            <Button text={'Удалить подкатегорию'} img={img} activeImg={activeImg}/>
          </div>
        </div>

        <div className={s.padding}></div>
        <AdminTitle title="Добавить отрасль"/>
        <IndustryForm jwt={jwt} part={part}/>

        <div className={s.margin}>
          <AdminTitle title="Удалить отрасль"/>
          <Select
              activeValue={activeIndustry}
              setActiveValue={setActiveIndustry}
              values={!part ? industries.legal : industries.physical}
              placeholder={'Выберите отрасль'}
              setIsSelectOpen={setIsSelectOpen}
              isSelectOpen={isSelectOpen}
              id={3}
              setActiveDeleteElement={setActiveDeleteIndustry}
          />
          <div onClick={() => deleteIndustry()}>
            <Button text={'Удалить отрасль'} img={img} activeImg={activeImg}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Category
