import './Projects.css'
import {Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import {useEffect} from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

export default function Projects() {
  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])
  const projects = [
    {
      name: 'Хоккейный центр СКА',
      city: 'г. Санкт-Петербург',
      image: 'ledovyiy_hokkeynyiy_tsentr_vityaz 1.png',
    },
    {
      name: 'Хоккейный центр СКА',
      city: 'г. Санкт-Петербург',
      image: 'ledovyiy_hokkeynyiy_tsentr_vityaz 1.png',
    },
    {
      name: 'Хоккейный центр СКА',
      city: 'г. Санкт-Петербург',
      image: 'ledovyiy_hokkeynyiy_tsentr_vityaz 1.png',
    },
    {
      name: 'Хоккейный центр СКА',
      city: 'г. Санкт-Петербург',
      image: 'ledovyiy_hokkeynyiy_tsentr_vityaz 1.png',
    },
    {
      name: 'Хоккейный центр СКА',
      city: 'г. Санкт-Петербург',
      image: 'ledovyiy_hokkeynyiy_tsentr_vityaz 1.png',
    },
    {
      name: 'Хоккейный центр СКА',
      city: 'г. Санкт-Петербург',
      image: 'ledovyiy_hokkeynyiy_tsentr_vityaz 1.png',
    },
  ]
  return (
    <>
      <div className="project-page-conteiner">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">Главная</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">Компания</Link>
          </span>
          <Breadcrumb.Item active>Реализованные проекты</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">Реализованные проекты</div>
        <div className="project-page-row">
          {projects.map(project => (
            <div className="project-page-col">
              <div className="project">
                <img className="project-photo" variant="top" src={project.image} alt="project" />
                <div>
                  <p className="project-name">
                    <span>{project.name}</span>
                  </p>
                  <p>
                    <p>{project.city}</p>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
