import React from 'react'
import {useState} from 'react'
import AdminTitle from '../ui/AdminTitle/AdminTitle'
import Button from '../ui/Button/Button'
import Select from '../ui/Select/Select'
import s from './Goods.module.scss'
import GoodsForm from './GoodsForm/GoodsForm'
import img from '../img/backet.svg'
import activeImg from '../img/backet0.svg'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {getProducts} from '../../../store/async/asyncCategories'
import {removeGood} from '../../../store/async/asyncAdmin'

const Goods = ({jwt, part}) => {
  const [activeGood, setActiveGood] = useState('')
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const dispatch = useDispatch()
  const {data} = useSelector(store => store.products)
  // заглушка
  const [activeDeleteElement, setActiveDeleteElement] = useState(0)
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (firstLoad && data.length > 0) {
      setFirstLoad(false)
      return
    }

    dispatch(getProducts())
  }, [])

  return (
    <div className={s.container}>
      <AdminTitle title="Добавить товар" />
      <GoodsForm jwt={jwt} part={part} />

      <div
        className={`${s.sheet} ${isSelectOpen ? s.active : ''}`}
        onClick={() => {
          setIsSelectOpen(false)
        }}
      />

      <div className={s.wrapper}>
        <AdminTitle title="Удалить товар" />
        <Select
          activeValue={activeGood}
          setActiveValue={setActiveGood}
          values={data}
          placeholder={'Удалить товар'}
          setIsSelectOpen={setIsSelectOpen}
          isSelectOpen={isSelectOpen}
          id={1}
          setActiveDeleteElement={setActiveDeleteElement}
        />
        <div
          onClick={() => {
            dispatch(removeGood({id: activeDeleteElement, jwt})).then(() => dispatch(getProducts()))
          }}
        >
          <Button text={'Удалить товар'} img={img} activeImg={activeImg} />
        </div>
      </div>
    </div>
  )
}

export default Goods
