import React, {memo, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Button, Input, Modal, Result} from "antd";
import * as S from './FeedbackForm.module.scss';
import {useMutation} from "@tanstack/react-query";
import {sendFeedback} from "../../api/email.endpoint.js";
import {CheckCircleFilled, WarningFilled} from "@ant-design/icons";

const FeedbackForm = memo(() => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isOpen, setIsOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const sendFeedbackMutation = useMutation({
        mutationFn: sendFeedback,
        onMutate: () => {
            setIsSuccess(false);
            setIsError(false);
        },
        onSuccess: (data) => {
            if (data.success) {
                setIsSuccess(true);
                form.resetFields();
            } else {
                setIsSuccess(false);
            }
        },
        onError: (error) => {
            console.error(error);
            setIsError(true);
        },
    });

    const closeModal = useCallback(() => {
        setIsOpen(false);
        setIsSuccess(false);
        setIsError(false);
    }, []);

    return (
        <>
        <div className="feedback-button-container">
            <Button type="primary" size="large" onClick={() => setIsOpen(true)}>
                {t("Написать нам")}
            </Button>
        </div>
            <Modal open={isOpen} onCancel={closeModal} footer={null} width={700}>
                {isError ? (
                    <Result
                        status="Ошибка отправки сообщения"
                        icon={<WarningFilled style={{ color: '#faad14'}} />}
                        title="Возникла ошибка при попытке отправки сообщения. Попробуйте отправить сообщение позже"
                        extra={
                            <Button type="primary" key="console2" onClick={closeModal}>
                                Закрыть это окно
                            </Button>
                        }
                    />
                ) : null}
                {isSuccess ? (
                    <Result
                        status="Отправлено"
                        title="Ваше сообщение успешно отправлено!"
                        icon={<CheckCircleFilled style={{ color: '#78d803' }} />}
                        extra={[
                            <Button type="primary" key="console1" onClick={closeModal}>
                                Закрыть это окно
                            </Button>
                        ]}
                    />
                ) : (
                    <>
                        <p className={S.FormTitle}>Оставить заявку</p>
                        <Form size="large" form={form} className={S.FormContainer} onFinish={sendFeedbackMutation.mutate} disabled={sendFeedbackMutation.isLoading}>
                            <Form.Item name="company" rules={[
                                { required: true, message: 'Поле обязательное' }
                            ]}>
                                <Input placeholder="Компания" />
                            </Form.Item>
                            <Form.Item name="name" rules={[
                                { required: true, message: 'Поле обязательное' }
                            ]}>
                                <Input placeholder="Имя" />
                            </Form.Item>
                            <Form.Item name="email" rules={[
                                { required: true, message: 'Поле обязательное' },
                                { type: 'email', message: 'Введите корректный email' }
                            ]}>
                                <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item name="phone" rules={[
                                { required: true, message: 'Поле обязательное' }
                            ]}>
                                <Input placeholder="Номер телефона" />
                            </Form.Item>
                            <Form.Item name="text" rules={[
                                { required: true, message: 'Поле обязательное' }
                            ]}>
                                <Input.TextArea placeholder="Текст комментария" />
                            </Form.Item>

                            <Form.Item className={S.FormSubmitButtonContainer}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={S.FormSubmitButton}
                                    size="large"
                                    loading={sendFeedbackMutation.isLoading}>
                                    Отправить заявку
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )}
            </Modal>
        </>
    )
});

export default FeedbackForm;