import {memo, useMemo} from "react";
import {Tabs} from "antd";
import {CategoryTable} from "./components/CategoryTable.jsx";
import {SubCategoryTable} from "./components/SubCategoryTable.jsx";

const TAB_CATEGORY = 'category';
const TAB_SUB_CATEGORY = 'subCategory';

export const CategoryManagement = memo(({jwt}) => {
    const items = useMemo(() => ([
        {
            key: TAB_CATEGORY,
            label: 'Категории',
            children: <CategoryTable jwt={jwt} />,
        },
        {
            key: TAB_SUB_CATEGORY,
            label: 'Подкатегории',
            children: <SubCategoryTable jwt={jwt} />  ,
        },
    ]), [jwt]);

    return (
        <Tabs items={items} />
    )
});
