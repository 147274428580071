import './Clients.css'
import {Row, Col, Image, Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import scrollIntoView from 'scroll-into-view'
import {useTranslation} from 'react-i18next'

export default function Clients() {
  const {t} = useTranslation()
  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])
  const clients = [
    'client01.png',
    'client02.png',
    'client03.png',
    'client04.png',
    'client05.png',
    'client06.png',
    'client07.png',
    'client08.png',
    'client09.png',
  ]
  return (
    <>
      <div className="clients-page-conteiner">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Наши клиенты')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Наши клиенты')}</div>
        <Row className="clients-page-row">
          {clients.map(client => (
            <Col className="clients-page-col">
              <Image className="client-image" src={client} alt="image" />
            </Col>
          ))}
        </Row>
      </div>
    </>
  )
}
