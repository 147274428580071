import React, {useEffect, useState} from 'react'
import s from './Payment.module.scss'
import car from '../../media/main/car.jpg'
import scrollIntoView from 'scroll-into-view'
import {useOutletContext} from 'react-router-dom'

const Payment = props => {
  const [activeLanguage, setActiveLanguage] = useState(0)
  const {setBreadCrumb} = useOutletContext()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })

    setBreadCrumb([
      {
        id: 1,
        title: 'Главная',
        href: '/shop',
      },
      {
        id: 2,
        title: 'Оплата и доставка',
      },
    ])
  }, [])

  return (
    <div>
      <div className={s.body}>
        <div className={s.container}>
          {/* <div className={s.secondItem}>
            <div className={`${!activeLanguage ? s.left : s.right} ${s.changeLanguage}`}>
              <span
                onClick={() => setActiveLanguage(0)}
                className={`${!activeLanguage ? s.active : ''}`}
              >
                Оплата
              </span>
              <span
                onClick={() => setActiveLanguage(1)}
                className={`${activeLanguage ? s.active : ''}`}
              >
                Доставка
              </span>
            </div>
          </div> */}
    <div className="content">
    <h1>ВАРИАНТЫ ДОСТАВКИ И ОПЛАТЫ</h1>

    <h2>Доставка курьером по Мозырю и Мозырскому (Калинковичи) району (Понедельник-Пятница)</h2>
    <ul>
      <li>В пределах Мозыря и Мозырского района мы осуществляем доставку в будние дни с 08:00 до 17:00.</li>
      <li>Ваша покупка будет готова к доставке только после того, как заказ будет оформлен и согласован с менеджером!</li>
      <li>Доставка заказа осуществляется курьером, в оговоренный с менеджером временной интервал. Интересующие вопросы и необходимые характеристики уточняйте во время заказа у менеджеров магазина.</li>
      <li>Форма оплаты: наличный расчет, ЕРИП предварительно (принимаются только карты белорусских банков!).</li>
      <li><b>Минимальная сумма заказа: 40 руб</b></li>
      <li>Доставка не осуществляется в следующие микрорайоны : <br/>
<b>Стоимость доставки - 7 руб в пределах г. Мозыря. 15 руб – Мозырский район<br/>/
При сумме заказа свыше 150 руб - доставка бесплатная.</b></li>
    </ul>


<h2>Яндекс доставка (Ежедневно)</h2>
<ul>
  <li>Доставка осуществляется через Яндекс Такси. Стоимость в среднем от 5 до 20р. После оформления заказа, менеджер узнает стоимость доставки и выставит вам счёт в системе ЕРИП. После того, как заказ будет оплачен, будет оформлена доставка и передана водителю.</li>
  <li>Форма оплаты: ЕРИП предварительно (принимаются только карты белорусских банков!).</li>
  <li><b>Минимальная сумма заказа: 20 руб</b></li>
  <li><b>Стоимость доставки - 5-20 руб. Конечная стоимость будет определена приложением Яндекс Такси.</b></li>

</ul>



<h2>Доставка почтой по Беларуси</h2>
<ul>
  <li>Все заказы подтверждаются по телефону. После вашего заказа наш сотрудник позвонит вам и назовет точную сумму, которую вы заплатите при получении.</li>
  <li>Оплата товара производится в момент получения товара в почтовом отделении. По закону, квитанция оплаты наложенного платежа является кассовым чеком.</li>
  <li>Срок доставки почтой — 2-4 дня с момента согласования заказа с менеджером и отправки по почте.</li>
  <li><b>Срок хранения посылки на почте - 10 дней!</b> В случае не получения посылки, вам будет необходимо компенсировать стоимость доставки, согласно законодательства Республики Беларусь.</li>
  <li>Доставка товаров на почту осуществляется в течение 1-2 дней! На выходных доставка товаров на почту не осуществляется!</li>
  <li><b>Минимальная сумма заказа: 15 руб</b></li>
  <li>Форма оплаты: наличный, безналичный расчет.<br/>
Стоимость доставки почтой по Беларуси для посылок уточняйте у менеджера</li>
</ul>

<h2>Доставка Европочтой по Беларуси</h2>
<ul>
  <li>Все заказы подтверждаются по телефону. После вашего заказа наш сотрудник позвонит вам и назовет точную сумму, которую вы заплатите при получении.</li>
  <li>Оплата товара производится в момент получения товара в отделении европочты. По закону, квитанция оплаты наложенного платежа является кассовым чеком.</li>
  <li><b>Срок хранения посылки на почте - 7 дней!</b> Дальше платное хранение. В случае не получения посылки, вам будет необходимо компенсировать стоимость доставки, согласно законодательства Республики Беларусь.</li>
  <li><b>Минимальная сумма заказа: 15 руб</b></li>
  <li>Форма оплаты: наличный, безналичный расчет.<br/>
Стоимость доставки почтой по Беларуси для посылок уточняйте у менеджера</li>

</ul>


          <img src={car} alt="Машина" className={s.img} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
