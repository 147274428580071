import "./Contacts.css";
import { Col, Breadcrumb } from "react-bootstrap";
import {
  YMaps,
  Map,
  ZoomControl,
  TypeSelector,
  Placemark,
} from "react-yandex-maps";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import scrollIntoView from "scroll-into-view";
import { useTranslation } from "react-i18next";
import s from "./Contacts.module.scss";
import FeedbackForm from "../FeedbackForm/FeedbackForm.jsx";

export default function Contacts() {
  const { t } = useTranslation();

  useEffect(() => {
    scrollIntoView(document.body.querySelector("#root"), {
      time: 0,
      align: {
        top: 0.1,
      },
    });
  }, []);

  return (
    <>
      <div className="contacts-container">
        <Breadcrumb className="custom-breadcrumb" style={{ marginTop: "38px" }}>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">{t("Меню Главная")}</Link>
          </span>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">{t("Меню Компания")}</Link>
          </span>
          <Breadcrumb.Item active>{t("Меню Контакты")}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t("Меню Контакты")}</div>
        <div className="contacts-row">
          <Col>
            <div>
              <p>
                <span
                  style={{
                    color: "#E74137",
                    textTransform: "uppercase",
                    fontSize: "18px",
                  }}
                >
                  {t("ОАО «БЕЛАРУСКАБЕЛЬ»")}
                </span>
              </p>
              <div
                className="contacts-page-info"
                dangerouslySetInnerHTML={{ __html: `${t("Офис контакты")}` }}
              ></div>
              <div className={s.buttonWrapper}>
                <Link to="/shop" target="_blank" className={s.button}>
                  {t("Перейти в Интернет магазин")}
                </Link>
              </div>
              <FeedbackForm />
            </div>
          </Col>
          <Col>
            <div>
              <YMaps>
                <div className="contactsMap">
                  <Map
                    width={"100%"}
                    height={500}
                    defaultState={{
                      center: [52.053695, 29.241805],
                      zoom: 16,
                      controls: [],
                    }}
                  >
                    <ZoomControl options={{ float: "right" }} />
                    <TypeSelector options={{ float: "right" }} />
                    <Placemark geometry={[52.053695, 29.241805]} />
                  </Map>
                </div>
              </YMaps>
            </div>
          </Col>
        </div>
      </div>
      <hr></hr>
      <div className="contacts-container">
        <div className="contacts-row__wrapper">
          <span
            style={{ color: "#343753", fontWeight: "600", fontSize: "18px" }}
          >
            {t("Руководство")}
          </span>
          <div style={{ marginTop: "20px" }}>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Директор")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Шилов Виталий Петрович")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 24-77-29
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Главный инженер")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Барановский Сергей Григорьевич")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 24-77-29
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Заместитель директора по развитию")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Фиранчук Дмитрий Николаевич")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-41-62
                </span>
              </p>
            </div>
            <div>
              <p>
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "18px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {t(
                    "Заместитель директора по идеологической работе, кадрам и социальным вопросам"
                  )}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Фещенко Светлана Леонидовна")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-42-24
                </span>
              </p>
            </div>
            <div>
              <p>
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "18px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {t(
                    "Заместитель директора по снабжению и организации перевозок"
                  )}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Камеш Владимир Александрович")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-41-14
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Главный экономист")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Юницкая Инна Ивановна")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-43-25
                </span>
              </p>
            </div>

            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Главный бухгалтер")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Белько Анна Александровна")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 24-65-23
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="contacts-container">
        <div className="contacts-row__wrapper">
          <span
            style={{ color: "#343753", fontWeight: "600", fontSize: "18px" }}
          >
            {t("ОТДЕЛ ПРОДАЖ")}
          </span>
          <div style={{ marginTop: "20px" }}>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Начальник отдела продаж")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Сигай Татьяна Сергеевна")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-23-69
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (29) 314-59-26
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  zavod@belaruskabel.by
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Заместитель начальника отдела продаж")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Жилюк Михаил Александрович")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-47-42
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (29) 179-96-75
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  sbyt@belaruskabel.by
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Cпециалисты по продаже")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-48-59
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-47-42
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (29) 314-62-48
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (44) 713-80-83
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  info@belaruskabel.by
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  sbyt1@belaruskabel.by
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  sbyt5@belaruskabel.by
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("(внешнеэкономическая деятельность, экспорт)")}
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Специалисты по продаже")}
                </span>

                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-41-96
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-47-42
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (29) 314-58-83
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (29) 179-96-78
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  sbyt2@belaruskabel.by
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  sbyt3@belaruskabel.by
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  sbyt4@belaruskabel.by
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("(продажи Беларусь)")}
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Специалисты по маркетингу")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-42-97
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 21-07-55
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (44) 597-56-22
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (29) 179-97-35
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  marketing@belaruskabel.by
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  marketing2@belaruskabel.by
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="contacts-container">
        <div className="contacts-row__wrapper">
          <span
            style={{ color: "#343753", fontWeight: "600", fontSize: "18px" }}
          >
            {t("ОТДЕЛ МАТЕРИАЛЬНО-ТЕХНИЧЕСКОГО СНАБЖЕНИЯ")}
          </span>
          <div style={{ marginTop: "20px" }}>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Начальник отдела")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Гололобов Юрий Сергеевич")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 25-48-81
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("+375 (236) 25-41-59 (факс)")}
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Заместитель начальника")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Ивахно Тамара Яковлевна")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 24-91-52
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Группа химии")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Леоненко Нина Григорьевна")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 24-91-52
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Группа комплектации")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Бородина Татьяна Анатольевна")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 24-63-88
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  snab1@belaruskabel.by
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  os3600252@yandex.by
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Группа металлов")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Тимошков Алексей Владимирович")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 24-28-70
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  omts@belaruskabel.by
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="contacts-container">
        <div className="contacts-row__wrapper">
          <span
            style={{ color: "#343753", fontWeight: "600", fontSize: "18px" }}
          >
            {t("ОТДЕЛ КАДРОВ")}
          </span>
          <div style={{ marginTop: "20px" }}>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Начальник отдела кадров")}
                </span>
                {/*<span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Шилова Татьяна Анатольевна")}
                </span>*/}
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 24-08-03
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Слово Общий")}
                </span>
                {/*<span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Щербин Светлана Ивановна")}
                </span>*/}
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 37-25-66
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="contacts-container">
        <div className="contacts-row__wrapper">
          <span
            style={{ color: "#343753", fontWeight: "600", fontSize: "18px" }}
          >
            {t("ОТДЕЛ ТЕХНИЧЕСКОГО КОНТРОЛЯ")}
          </span>
          <div style={{ marginTop: "20px" }}>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Начальник отдела технического контроля")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Трояновская Олеся Александровна")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 37-25-16
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Инженер по качеству")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Пинчук Светлана Владимировна")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 37-25-95
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Инженер по стандартизации и сертификации")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Овада Наталья Ивановна")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 37-25-91
                </span>
              </p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  {t("Ведущий инженер по качеству")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  {t("Барановская Елена Алексеевна")}
                </span>
                <span style={{ fontWeight: "400", fontSize: "18px" }}>
                  +375 (236) 23-93-81
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>

      <div className="contacts-container">
        <div className="contacts-row__wrapper">
          <span
            style={{ color: "#343753", fontWeight: "600", fontSize: "18px" }}
          >
            {t("ЦЕНТРАЛЬНАЯ ЗАВОДСКАЯ ЛАБОРАТОРИЯ")}
          </span>
          <div style={{ marginTop: "20px" }}>
            <p className="contacts__wrapper">
              <span style={{ fontWeight: "700", fontSize: "18px" }}>
                {t("Начальник отдела")}
              </span>
              <span style={{ fontWeight: "400", fontSize: "18px" }}>
                {t("Шульга Алексей Васильевич")}
              </span>
              <span style={{ fontWeight: "400", fontSize: "18px" }}>
                +375 (236) 24-55-97
              </span>
              <span style={{ fontWeight: "400", fontSize: "18px" }}>
                czl@belaruskabel.by
              </span>
            </p>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="contacts-container">
        <div className="contacts-row__wrapper">
          <span
            style={{ color: "#343753", fontWeight: "600", fontSize: "18px" }}
          >
            {t("Слово КАНЦЕЛЯРИЯ")}
          </span>
          <div style={{ marginTop: "20px" }}>
            <p className="contacts__wrapper">
              <span style={{ fontWeight: "400", fontSize: "18px" }}>
                {t("Дубинина Людмила Сергеевна")}
              </span>
              <span style={{ fontWeight: "400", fontSize: "18px" }}>
                +375 (236) 25-42-80 (тел./факс)
              </span>
              <span style={{ fontWeight: "400", fontSize: "18px" }}>
                +375 (236) 22-95-48
              </span>
            </p>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="contacts-container">
        <div className="contacts-row__wrapper">
          <span
            style={{ color: "#343753", fontWeight: "600", fontSize: "18px" }}
          >
            {t("Слово ПРОФКОМ")}
          </span>
          <div style={{ marginTop: "20px" }}>
            <p className="contacts__wrapper">
              <span style={{ fontWeight: "400", fontSize: "18px" }}>
                {t("Председатель профкома")}
              </span>
              <span style={{ fontWeight: "400", fontSize: "18px" }}>
                {t("Сидоренко Светлана Ивановна")}
              </span>
              <span style={{ fontWeight: "400", fontSize: "18px" }}>
                +375 (236) 25-40-64
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="contacts-container">
        <div className="contacts-row__wrapper">
          <span
            style={{ color: "#343753", fontWeight: "800", fontSize: "30px" }}
          >
            {t("ВЫШЕСТОЯЩАЯ ОРГАНИЗАЦИЯ")}
          </span>
          <div style={{ marginTop: "40px", display: "block" }}>
            <p>
              <span
                style={{
                  color: "#343753",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {t("МИНИСТЕРСТВО ПРОМЫШЛЕННОСТИ РЕСПУБЛИКИ БЕЛАРУСЬ")}
              </span>
            </p>
            <p>
              <span
                style={{
                  color: "#343753",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {t(
                  "Адрес: Партизанский проспект, 2, корп. 4. 220033, Республика Беларусь, г. Минск"
                )}
              </span>
            </p>
            <p>
              <span
                style={{
                  color: "#343753",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {t("тел.: +375 (17) 223-93-96")}
              </span>
            </p>
            <p>
              <span
                style={{
                  color: "#343753",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {t("Канцелярия: +375 (17) 223-64-96")}
              </span>
            </p>
            <p>
              <span
                style={{
                  color: "#343753",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {t("факс: +375 (17) 328-30-48")}
              </span>
            </p>
            <p>
              <span
                style={{
                  color: "#343753",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                E-mail: minprom4@minprom.gov.by
              </span>
            </p>
            <p>
              <span
                style={{
                  color: "#343753",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {t("web-сайт: www.minprom.gov.by")}
              </span>
            </p>
            <p>
              <span
                style={{
                  color: "#343753",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {t(
                  "Режим работы: Ежедневно, кроме субботы и воскресенья с 9.00. до 18.00, обед с 13.00 до 14.00"
                )}{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
