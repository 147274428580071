import './Production.css'
import {Row, Col, Image, Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

export default function Production() {
  const {t} = useTranslation()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  return (
    <>
      <div className="production-page-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Меню производство')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Меню производство')}</div>
        <div className="production-page-info">
          <Row className="production-page-row">
            <Col>
              <Row>
                <div className="production-page-text">
                  <p>
                    <span>{t('Уже более 60 лет ОАО')}</span>
                    {t('которое изготавливает и поставляет кабельную продукцию')}
                  </p>
                  <p>
                    {t(
                      'Сегодня - это современное, универсальное предприятие, располагающее оборудованием для производства'
                    )}
                  </p>
                  <p>
                    {t(
                      'Мы единственная организация в Республике Беларусь специализирующаяся на выпуске импортозамещающей кабельно-проводниковой продукции'
                    )}
                  </p>
                </div>
              </Row>
            </Col>
            <Col>
              <Row>
                <Image className="production-page-images" src="prod1.png" alt="1.1.png" />
                <Image
                  style={{marginTop: '32px'}}
                  className="production-page-images"
                  src="prod2.png"
                  alt="1.1.png"
                />
              </Row>
            </Col>
          </Row>
          <Row className="production-page-row">
            <Row>
              <Image className="production-page-images" src="prod3.jpeg" alt="3.png" />
            </Row>
          </Row>
          <Row className="production-page-row">
            <Col>
              <Row>
                <Image className="production-page-images" src="prod4.jpeg" alt="5.png" />
              </Row>
            </Col>
            <Col>
              <div
                className="production-page-text"
                style={{marginTop: '5%'}}
                dangerouslySetInnerHTML={{
                  __html: `${t(
                    'производственными мощностями и площадями, высококвалифицированными кадрами'
                  )}`,
                }}
              ></div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
