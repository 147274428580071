import React from 'react'
import {useSearchParams} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import s from './Filter.module.scss'

const Filter = ({
  svgIconDefault,
  svgIconHover,
  name,
  id,
  activeIndustry,
  activeCategory,
  setActiveIndustry,
  setActiveCategory,
  industry,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  let activeParams = {}

  if (activeIndustry) activeParams.industry = searchParams.get('industry')
  if (activeCategory) activeParams.category = searchParams.get('category')

  return (
    <>
      {industry ? (
        <div
          className={`${s.flexFilters} ${
            activeIndustry && activeIndustry[0] === id ? s.active : ''
          } ${!svgIconDefault ? s.withoutPadding : ''}`}
          onClick={() => {
            setSearchParams({...activeParams, page: 1})
            setActiveIndustry([id, name])
          }}
        >
          {svgIconDefault ? (
            <>
              <img src={svgIconDefault} className={s.imageDefault} alt="Картинка" />
              <img src={svgIconHover} alt="Картинка при наведении" className={s.imageHover} />
            </>
          ) : (
            ''
          )}
          <p key={id} className={`${s.filter}`}>
            {name.split('')[0].toUpperCase() +
              name.split('').slice(1, name.split('').length).join('')}
          </p>
        </div>
      ) : (
        <div
          className={`${s.flexFilters} ${
            activeCategory && activeCategory[0] === id ? s.active : ''
          } ${!svgIconDefault ? s.withoutPadding : ''}`}
          onClick={() => {
            setSearchParams({...activeParams, page: 1})
            setActiveCategory([id, name])
          }}
        >
          {svgIconDefault ? (
            <>
              <img src={svgIconDefault} className={s.imageDefault} alt="Картинка" />
              <img src={svgIconHover} alt="Картинка при наведении" className={s.imageHover} />
            </>
          ) : (
            ''
          )}
          <p key={id} className={`${s.filter}`}>
            {name.split('')[0].toUpperCase() +
              name.split('').slice(1, name.split('').length).join('')}
          </p>
        </div>
      )}
    </>
  )
}

export default Filter
