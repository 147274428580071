import React, {useState} from 'react'

import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {https} from "../../../../config";
import Select from '../../ui/Select/Select'
import s from './GoodsForm.module.scss'
import Table from '../Table/Table'
import {useDispatch, useSelector} from 'react-redux'
import Button from '../../ui/Button/Button'
import {useEffect} from 'react'

const saveProduct = async (payload, jwt) => {
    try {
        const response = await https.post('/ru/products/', payload, {
            headers: {
                Authorization: jwt,
            },
        });
        return response?.data || null;
    } catch (e) {
        console.error(e);
    }
};


const schema = yup
  .object({
    name: yup.string().required('Строка должна быть заполнена'),
    purpose: yup.string().required('Строка должна быть заполнена'),
    construction: yup.string().required('Строка должна быть заполнена'),
    exploitation: yup.string().required('Строка должна быть заполнена'),
    specification: yup.string().required('Строка должна быть заполнена'),
    insulation: yup.string().required('Строка должна быть заполнена'),
    cableSheath: yup.string().required('Строка должна быть заполнена'),
    screen: yup.string().optional(),
  })
  .required()

const GoodsForm = ({jwt, part}) => {
  const [activeCategory, setActiveCategory] = useState(false)
  const [activeSubCategory, setActiveSubCategory] = useState(null)
  const [activeIndustry, setActiveIndustry] = useState(false)
  const [activeIndustryPublic, setActiveIndustryPublic] = useState(null)
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [values, setValues] = useState([['', '', '', '', '']])
  const {data, subCategories, industries} = useSelector(store => store.categories)
  const [validate, setValidate] = useState(false)
  const [exploitation, setExploitation] = useState('')
  const [construction, setConstruction] = useState('')
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = data => {
    setValidate(true)

    const formData = new FormData()

    formData.append('file', file)
    formData.append(
      'data',
      JSON.stringify({
        ...data,
        categoryId: activeCategory,
        subCategoryId: activeSubCategory,
        industry_legal: activeIndustry,
        industry_physical: activeIndustryPublic,

        // sizes: [
        //   ...values.map(val => ({
        //     nominalCrossSection: [...val[0]].length > 0 ? val[0] : null,
        //     numberOfCores: [...val[1]].length > 0 ? val[1] : null,
        //     maxDiameter: [...val[2]].length > 0 ? val[2] : null,
        //     weight: [...val[3]].length > 0 ? val[3] : null,
        //     coreConstruction: [...val[4]].length > 0 ? val[4] : null,
        //   })),
        // ],
      })
    )

    if (activeCategory && file) {
      void saveProduct(formData, jwt);
      //dispatch(addGoods(formData, jwt))
    }
  }

  useEffect(() => {
    if (exploitation.split('').length >= 1 && exploitation.split('')[0] !== '•')
      setExploitation(`• ${exploitation}`)
  }, [exploitation])

  useEffect(() => {
    if (construction.split('').length >= 1 && construction.split('')[0] !== '•')
      setConstruction(`• ${construction}`)
  }, [construction])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={`${s.sheet} ${isSelectOpen ? s.active : ''}`}
        onClick={() => {
          setIsSelectOpen(false)
        }}
      />

      <Select
        activeValue={activeCategory}
        setActiveValue={setActiveCategory}
        values={data}
        placeholder={'Выберите категорию'}
        setIsSelectOpen={setIsSelectOpen}
        isSelectOpen={isSelectOpen}
        id={1}
        setActiveDeleteElement={setActiveCategory}
      />
      {!activeCategory && validate && (
        <span className={s.margin}>Эта строка должна быть заполнена</span>
      )}
      <Select
        activeValue={activeSubCategory}
        setActiveValue={setActiveSubCategory}
        placeholder={'Выберите подкатегорию'}
        values={subCategories}
        setIsSelectOpen={setIsSelectOpen}
        isSelectOpen={isSelectOpen}
        id={2}
        setActiveDeleteElement={setActiveSubCategory}
      />

      <Select
        activeValue={activeIndustry}
        setActiveValue={setActiveIndustry}
        placeholder={'Выберите отрасль'}
        values={industries.legal}
        setIsSelectOpen={setIsSelectOpen}
        isSelectOpen={isSelectOpen}
        id={3}
        setActiveDeleteElement={setActiveIndustry}
        industry
      />
      {!activeIndustry && validate && (
        <span className={s.margin}>Эта строка должна быть заполнена</span>
      )}

      <Select
        activeValue={activeIndustryPublic}
        setActiveValue={setActiveIndustryPublic}
        placeholder={'Выберите отрасль'}
        values={industries.physical}
        setIsSelectOpen={setIsSelectOpen}
        isSelectOpen={isSelectOpen}
        id={4}
        setActiveDeleteElement={setActiveIndustryPublic}
        industry
      />

      <input
        type="text"
        className={s.input}
        {...register('name')}
        placeholder="Наименование товара"
      />
      {errors.name && errors.name.type === 'required' && (
        <span>Эта строка должна быть заполнена</span>
      )}
      <textarea
        {...register('purpose')}
        className={`${s.textarea} ${s.first}`}
        placeholder="Назначение"
      ></textarea>
      {errors.purpose && errors.purpose.type === 'required' && (
        <span>Эта строка должна быть заполнена</span>
      )}
      <textarea
        {...register('construction')}
        className={s.textarea}
        placeholder="Конструкция"
        value={construction.replace(/\n/g, ' • ')}
        onChange={e => setConstruction(e.target.value)}
      ></textarea>
      {errors.construction && errors.construction.type === 'required' && (
        <span>Эта строка должна быть заполнена</span>
      )}
      <textarea
        {...register('exploitation')}
        className={s.textarea}
        placeholder="Технические характеристики"
        value={exploitation.replace(/\n/g, ' • ')}
        onChange={e => setExploitation(e.target.value)}
      ></textarea>
      {errors.exploitation && errors.exploitation.type === 'required' && (
        <span>Эта строка должна быть заполнена</span>
      )}
      <textarea
        {...register('specification')}
        className={s.textarea}
        placeholder="Условия экспутации"
      ></textarea>
      {errors.specification && errors.specification.type === 'required' && (
        <span>Эта строка должна быть заполнена</span>
      )}

      <textarea
        {...register('insulation')}
        className={s.textarea}
        placeholder="Изоляция"
      ></textarea>
      {errors.insulation && errors.insulation.type === 'required' && (
        <span>Эта строка должна быть заполнена</span>
      )}
      <textarea
        {...register('cableSheath')}
        className={s.textarea}
        placeholder="Оболочка кабеля"
      ></textarea>
      {errors.cableSheath && errors.cableSheath.type === 'required' && (
        <span>Эта строка должна быть заполнена</span>
      )}
      <textarea
        {...register('screen')}
        className={s.textarea}
        placeholder="Экран (да/нет)"
      ></textarea>
      {errors.screen && errors.screen.type === 'required' && (
        <span>Эта строка должна быть заполнена</span>
      )}
      <div className={s.flex}>
        <div className={s.inputWrapper}>
          <input
            name="file"
            type="file"
            id="input__file"
            className={s.inputFile}
            accept=".png, .jpeg, .jpg"
            onChange={e => {
              setFile(e.target.files[0]) // file
            }}
          />
          <label htmlFor="input__file" className={`${s.inputFileButton} ${file ? s.active : ''}`}>
            <span className={`${s.inputFileWrapper} ${file ? s.active : ''}`}>
              <svg
                className={`${s.inputFileIcon} ${file ? s.active : ''}`}
                width="24"
                height="21"
                viewBox="0 0 24 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 2.625H5C3.89543 2.625 3 3.4085 3 4.375V16.625C3 17.5915 3.89543 18.375 5 18.375H19C20.1046 18.375 21 17.5915 21 16.625V4.375C21 3.4085 20.1046 2.625 19 2.625Z"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.5 8.75C9.32843 8.75 10 8.16237 10 7.4375C10 6.71263 9.32843 6.125 8.5 6.125C7.67157 6.125 7 6.71263 7 7.4375C7 8.16237 7.67157 8.75 8.5 8.75Z"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 13.125L16 8.75L5 18.375"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span className={`${s.inputIconText} ${file ? s.active : ''}`}>
              {file ? 'Изображение загружено' : 'Загрузите изображение'}
            </span>
          </label>
        </div>
      </div>

      <Table values={values} setValues={setValues} />

      <Button text="Добавить товар" />
    </form>
  )
}

export default GoodsForm
