import React from 'react'
import s from './NewProduction.module.scss'
import gif from './media/spin.gif'
import gif1 from './media/spin1.gif'
import gif2 from './media/spin2.gif'
import gif3 from './media/spin3.gif'
import gif4 from './media/spin4.gif'
import gif5 from './media/spin5.gif'
import {useTranslation} from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const NewProduction = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const initialState = [
    {
      id: 1,
      title: `${t('КУПД, КВПД, МКЭШВ')}`,
      paragraph: `${t('КАБЕЛИ УПРАВЛЕНИЯ')}`,
      link: 'bc3018d1-1848-4213-9cc5-49139db88721',
      img: gif3,
    },
    {
      id: 2,
      title: `${t('ВВГ, КВВГ, КВВГЭ')}`,
      paragraph: `${t('СИЛОВОЙ И КОНТРОЛЬНЫЙ КАБЕЛЬ')}`,
      link: "61cbbb4e-bf92-4111-a9fa-7d6c477eff8b",
      img: gif,
    },
    {
      id: 3,
      title: `${t('ТППэп, ТППШв, КВП')}`,
      paragraph: `${t('КАБЕЛИ И ПРОВОДА СВЯЗИ')}`,
      link: '3c46d789-b638-4a9a-be43-249e447f8f0c',
      img: gif2,
    },
    {
      id: 4,
      title: `${t('СБЗПу (для железной дороги)')}`,
      paragraph: `${t('СИГНАЛЬНО-БЛОКИРОВАЧНЫЙ')}`,
      link: '3c46d789-b638-4a9a-be43-249e447f8f0c',
      img: gif1,
    },
    {
      id: 5,
      title: `${t('БПДО, МСТП, МЛТП')}`,
      paragraph: `${t('ПРОВОДА ТЕПЛОСТОЙКИЕ')}`,
      link: '2c102d2e-d34d-4cea-a063-09f760e3fc3e',
      img: gif4,
    },
    {
      id: 6,
      title: `${t('МНВ, МГШВ, НВ')}`,
      paragraph: `${t('ПРОВОДА МОНТАЖНЫЕ')}`,
      link: '678b7c3d-99da-4c94-a6d6-eb655464ea8c',
      img: gif5,
    },
  ]
  return (
    <div className={s.container}>
      <p className="commonLabels">{t('Лидеры продаж')}</p>
      <div className={s.flex}>
        {initialState.map(state => (
          <div className={s.flexItem} key={state.id}>
            <img src={state.img} alt="Provod gif" className={s.image} />
            <div className={s.wrapper}>
              <p className={s.title}>{state.title}</p>
              <p className={s.paragraph}>{state.paragraph}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default NewProduction
