import {Breadcrumb} from 'react-bootstrap'
import './Cybersecurity.css'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import scrollIntoView from 'scroll-into-view'
import {useTranslation} from 'react-i18next'
import ModalImage from "react-modal-image"

export default function Cybersecurity() {
  const {t} = useTranslation()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])
  return (
    <>
      <div className="innovative-page-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Меню Кибербезопасность')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Меню Кибербезопасность')}</div>
        <div className="cybersecurity-items">
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/2. БелТА-01-s.jpg"
              large="/cybersecurity/2. БелТА-01.jpg"
              alt="2. БелТА-01.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/3. БелТА-02-s.jpg"
              large="/cybersecurity/3. БелТА-02.jpg"
              alt="3. БелТА-02.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/4. БелТА-03-s.jpg"
              large="/cybersecurity/4. БелТА-03.jpg"
              alt="4. БелТА-03.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/5. БелТА-04-s.jpg"
              large="/cybersecurity/5. БелТА-04.jpg"
              alt="5. БелТА-04.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/6. БелТА-05-s.jpg"
              large="/cybersecurity/6. БелТА-05.jpg"
              alt="6. БелТА-05.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/7. БПК_БелТА-s.jpg"
              large="/cybersecurity/7. БПК_БелТА.jpg"
              alt="7. БПК_БелТА.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/Атака госпредприятий-s.jpg"
              large="/cybersecurity/Атака госпредприятий.jpg"
              alt="Атака госпредприятий.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/Безопасный Интернет детям-s.jpg"
              large="/cybersecurity/Безопасный Интернет детям.jpg"
              alt="Безопасный Интернет детям.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/Безопасный Интернет для детей-s.jpg"
              large="/cybersecurity/Безопасный Интернет для детей.jpg"
              alt="Безопасный Интернет для детей.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/Безопасный Интернет для детей2-s.jpg"
              large="/cybersecurity/Безопасный Интернет для детей2.jpg"
              alt="Безопасный Интернет для детей2.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/Внимание мошенники-s.jpg"
              large="/cybersecurity/Внимание мошенники.jpg"
              alt="Внимание мошенники.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/Листовка детская_2024-s.jpg"
              large="/cybersecurity/Листовка детская_2024.jpg"
              alt="Листовка детская_2024.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/Мошеничество в сети-s.jpg"
              large="/cybersecurity/Мошеничество в сети.jpg"
              alt="Мошеничество в сети.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/Мошенники_1_2024-s.jpg"
              large="/cybersecurity/Мошенники_1_2024.jpg"
              alt="Мошенники_1_2024.jpg"
            /> 
          </div>
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/Мошенники_2_2024-s.jpg"
              large="/cybersecurity/Мошенники_2_2024.jpg"
              alt="Мошенники_2_2024.jpg"
            /> 
          </div> 
          <div class="cybersecurity-item">
            <ModalImage
              small="/cybersecurity/Вам звонят-s.jpg"
              large="/cybersecurity/Вам звонят.jpg"
              alt="Вам звонят.jpg"
            /> 
          </div>               
        </div>
      </div>
    </>
  )
}
