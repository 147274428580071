import React from 'react'
import {useState} from 'react'
import AdminTitle from '../ui/AdminTitle/AdminTitle'
import Button from '../ui/Button/Button'
import Select from '../ui/Select/Select'
import s from './Goods.module.scss'
import img from '../img/backet.svg'
import activeImg from '../img/backet0.svg'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {getProducts} from '../../../store/async/asyncCategories'
import {getProduct} from '../../../store/async/asyncAdmin'
import GoodsEditForm from './GoodsForm/GoodsForm'
import {removeEditProduct} from '../../../store/reducers/AdminReducer'

const EditGood = ({jwt, part}) => {
  const [activeGood, setActiveGood] = useState('')
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  // заглушка
  const [activeDeleteElement, setActiveDeleteElement] = useState(0)
  const dispatch = useDispatch()
  const {data} = useSelector(store => store.products)
  const {editProduct} = useSelector(store => store.admin)
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    dispatch(removeEditProduct())

    if (firstLoad && data.length > 0) {
      setFirstLoad(false)
      return
    }

    dispatch(getProducts())
  }, [])

  const handleClick = () => {
    dispatch(getProduct(activeDeleteElement))
  }

  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        {!editProduct ? (
          <>
            <AdminTitle title="Выбрать провод" />
            <Select
              activeValue={activeGood}
              setActiveValue={setActiveGood}
              values={data}
              placeholder={'Выбрать провод'}
              setIsSelectOpen={setIsSelectOpen}
              isSelectOpen={isSelectOpen}
              id={1}
              setActiveDeleteElement={setActiveDeleteElement}
            />
            <span onClick={() => handleClick()}>
              <Button text={'Изменить'} img={img} activeImg={activeImg} />
            </span>
          </>
        ) : (
          <>
            <AdminTitle title="Редактирование товара" />
            <GoodsEditForm initialData={editProduct} jwt={jwt} part={part} />
          </>
        )}
      </div>
      <div
        className={`${s.sheet} ${isSelectOpen ? s.active : ''}`}
        onClick={() => {
          setIsSelectOpen(false)
        }}
      />
    </div>
  )
}

export default EditGood
