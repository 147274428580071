import {memo, useCallback, useState} from "react";
import {Button, Divider, Modal, notification, Space} from "antd";
import MediaManagement from "../MediaManagement/MediaManagement.jsx";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {updateProductImage} from "../../../api/products.endpoint.js";

const ProductMediaManagement = memo(({ product }) => {
    const [api, contextHolder] = notification.useNotification();
    const queryClient = useQueryClient();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(undefined);

    const openModalHandler = useCallback(() => setIsOpen(true), []);
    const closeModalHandler = useCallback(() => setIsOpen(false), []);

    const updateProductImageMutation = useMutation({
        mutationFn: updateProductImage,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['products', 'all'] });
            api.success({ message: 'Данные успешно обновлены!'});
            setSelectedImage(undefined);
            closeModalHandler();
        },
        onError: () => {
            api.error({ message: 'Произошла ошибка. Попробуйте повторить запрос еще раз.' })
        },
    })

    const productHasOldImage = Boolean(!product.media && product.image);

    const saveChanges = async () => {
        if (selectedImage) {
            await updateProductImageMutation.mutateAsync({
                productId: product.id,
                mediaId: selectedImage._id
            });
        }
    };

    return (
        <>
            {contextHolder}
            <Button type="dashed" size="small" onClick={openModalHandler}>Изображение</Button>
            <Modal width={950} title={`Выбрать картинку для: "${product.name}"`} open={isOpen} onCancel={closeModalHandler} footer={null} destroyOnClose>
                <MediaManagement onChange={setSelectedImage} selectedMediaId={product.media} />
                <Divider />
                <Space direction="horizontal">
                    <Button type="primary" onClick={saveChanges} disabled={!selectedImage}>Сохранить</Button>
                    <Button onClick={closeModalHandler}>Отменить</Button>
                </Space>
            </Modal>
        </>
    )
});

export default ProductMediaManagement;
