import {https} from "../config/index.js";

export const validateEmailSettings = async (settings) => {
    const response = await https.get(`v2/feedback/healthcheck`, settings);
    return response.data || [];
}

export const sendFeedback = async (data) => {
    const response = await https.post(`v2/feedback/send`, data);
    return response.data || [];
}

