import React from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {Link} from 'react-router-dom'
import s from './LogIn.module.scss'

const schema = yup
  .object({
    email: yup.string().email('Неправильный email').required('Необходимо ввести email'),
    password: yup.string().required('Необходимо ввести пароль'),
  })
  .required()

const LogInForm = ({logIn}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  })
  const onSubmit = data => logIn(data)

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
      <h2 className={s.title}>Авторизация</h2>
      <input className={s.defaultInput} {...register('email')} placeholder="E-mail" />
      <input
        className={s.defaultInput}
        type="password"
        {...register('password')}
        placeholder="Пароль"
      />
      <button type="submit" className={s.button}>
        Авторизация
      </button>
    </form>
  )
}

export default LogInForm
