import './Certificates.css'
import {Row, Col, Image, Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import scrollIntoView from 'scroll-into-view'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

export default function Certificates() {
  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  return (
    <>
      <div className="certificates-page-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">Главная</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">Компания</Link>
          </span>
          <Breadcrumb.Item active>Сертификаты</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">Сертификаты</div>
        <div className="certificates-page-row-col">
          <Row md={3} className="certificates-page-image-list">
            <Col>
              <Row>
                <Image className="certificates-page-image" src="c1.png" alt="image" />
              </Row>
            </Col>
            <Col>
              <Row>
                <Image className="certificates-page-image" src="c2.png" alt="image" />
              </Row>
            </Col>
            <Col>
              <Row>
                <Image className="certificates-page-image" src="/qualityPolicy/certificate-csms.webp" alt="image" />
              </Row>
            </Col>
          </Row>
          <Row md={3} className="certificates-page-image-list">
            <Col>
              <Row>
                <Image className="certificates-page-image" src="c4.png" alt="image" />
              </Row>
            </Col>
            <Col>
              <Row>
                <Image className="certificates-page-image" src="c5.png" alt="image" />
              </Row>
            </Col>
            <Col>
              <Row>
                <Image className="certificates-page-image" src="c6.png" alt="image" />
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
