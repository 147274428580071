import { BoldText } from "./styled";

export const config = [
  {
    id: 1,
    text:
      "Уважаемые акционеры ОАО «Беларускабель»!",
    isBolded: true,
  },
  {
    id: 2,
    text: "Во исполнение пункта 6 статьи 85 Налогового кодекса Республики Беларусь, постановления Совета Министров Республики Беларусь от        7 апреля 2021 года № 201 «О представлении сведений о доходах физических лиц», постановления Министерства по налогам и сборам Республики Беларусь от 15 ноября 2021 года № 35 «О формах и перечне сведений», руководствуясь Законом Республики Беларусь от 7 мая       2021 года № 99-З «О защите персональных данных», Законом Республики Беларусь от 9 декабря 1992 года № 2020-XII «О хозяйственных обществах» ОАО «Беларускабель» просит Вас заполнить и предоставить следующий документ:",
  },
  {
    id: 3,
    text: <a href="/shareholders/откр СОГЛАСИЕ на обработку персональных данных.docx" target="_blank">Согласие на обработку персональных данных</a>,
  },  
  {
    id: 4,
    text: "Документ выслать по адресу: 247772, г.Мозырь, Гомельская область, ул. Октябрьская, 14, ОАО «Беларускабель» (с пометкой «Для специалиста по ценным бумагам») либо явиться лично.   Для входа на территорию предприятия необходимо оформить пропуск в бюро пропусков ОАО «Беларускабель».",
  },
  {
    id: 5,
    text:
      "По всем вопросам обращаться по телефону: ",
    subtext: [
      "+375(236)216819 (Татьяна Васильевна)",
    ],
  },

];
