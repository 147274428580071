import React, {useState} from 'react'
import Filter from '../Filter/Filter'
import s from '../TopicPage/Topic.module.scss'

const Filters = ({
  activeCategory,
  activeIndustry,
  setActiveCategory,
  setActiveIndustry,
  industries,
  categories,
}) => {
  return (
    <>
      {industries && categories && (
        <div>
          <div className={s.flexColumn}>
            <p className={s.title}>Категория</p>
            <div className={s.filters}>
              {categories.map(cat => (
                <Filter
                  {...cat}
                  activeCategory={activeCategory}
                  setActiveCategory={setActiveCategory}
                  key={cat.id}
                />
              ))}
            </div>
          </div>
          <div className={s.flexColumn}>
            <p className={s.title}>Отрасль</p>
            <div className={s.filters}>
              {industries.map(cat => (
                <Filter
                  {...cat}
                  activeIndustry={activeIndustry}
                  setActiveIndustry={setActiveIndustry}
                  industry
                  key={cat.id}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Filters
