import {https} from "../../../config/index.js";

export const getAllProducts = async () => {
    try {
        const response = await https.get('/products/all');
        return response.data?.data || [];
    } catch (e) {
        console.error(e);
    }
};

export const getProduct = async (id, lng = 'ru') => {
    try {
        const response = await https.get(`/${lng}/product/${id}`);
        return response?.data?.data;
    } catch (e) {
        console.error(e);
    }
};

export const updateProduct = async (payload) => {
    try {
        const response = await https.put(`/products/update`, payload);
        return response;
    } catch (e) {
        console.error(e);
    }
};

export const deleteProduct = async (id, jwt, lng = 'ru') => {
    try {
        const response = await https.delete(`/${lng}/product/${id}`, {
            data: {jwt: `Bearer ${jwt}`}
        });
        return response;
    } catch (e) {
        console.error(e);
    }
};

