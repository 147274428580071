export const cardpage = {
  ru: {
    translation: {
      "НАРУЖНАЯ ОБОЛОЧКА": "НАРУЖНАЯ ОБОЛОЧКА",
      "Изоляция": "Изоляция",
      "Оболочка кабеля": "Оболочка кабеля",
      "С экраном": "С экраном",
      "НАЗНАЧЕНИЕ": "НАЗНАЧЕНИЕ",
      "ОПИСАНИЕ": "ОПИСАНИЕ",
      "КОНСТРУКЦИЯ": "КОНСТРУКЦИЯ",
      "ИЗОЛЯЦИЯ": "ИЗОЛЯЦИЯ",
      "УСЛОВИЯ ЭКСПЛУАТАЦИИ": "УСЛОВИЯ ЭКСПЛУАТАЦИИ",
      "ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ": "ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ",
      "Номинальное сечение жил": "Номинальное сечение жил, мм²",
      "Число жил": "Число жил",
      "Максимальный диаметр": "Максимальный диаметр, мм",
      "Масса": "Масса, кг/км",
      "МАКРОРАЗМЕРЫ": "МАКРОРАЗМЕРЫ",
    },
  },
  en: {
    translation: {
      "НАРУЖНАЯ ОБОЛОЧКА": "OUTER SHELL",
      "Изоляция": "Insulation",
      "Оболочка кабеля": "Cable sheath",
      "С экраном": "With screen",
      "НАЗНАЧЕНИЕ": "PURPOSE",
      "ОПИСАНИЕ": "DESCRIPTION",
      "КОНСТРУКЦИЯ": "DESIGN",
      "ИЗОЛЯЦИЯ": "INSULATION",
      "УСЛОВИЯ ЭКСПЛУАТАЦИИ": "TERMS OF USE",
      "ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ": "SPECIFICATIONS",
      "Номинальное сечение жил": "Nominal cross-section of cores, mm²",
      "Число жил": "Number of cores",
      "Максимальный диаметр": "Maximum diameter, mm",
      "Масса": "Weight, kg/km",
      "МАКРОРАЗМЕРЫ": "MACRO SIZES",      
    },
  },
  bel: {
    translation: {
      "НАРУЖНАЯ ОБОЛОЧКА": "ЗНЕШНЯЯ АБАЛОНКА", 
      "Изоляция": "Ізаляцыя",
      "Оболочка кабеля": "Абалонка кабеля",
      "С экраном": "З экранам",
      "НАЗНАЧЕНИЕ": "ПРЫЗНАЧЭННЕ",
      "ОПИСАНИЕ": "АПІСАННЕ",
      "КОНСТРУКЦИЯ": "КАНСТРУКЦЫЯ",
      "ИЗОЛЯЦИЯ": "ІЗАЛЯЦЫЯ",
      "УСЛОВИЯ ЭКСПЛУАТАЦИИ": "УМОВЫ ЭКСПЛУАТАЦЫІ",
      "ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ": "ТЭХНІЧНЫЯ ХАРАКТАРЫСТЫКІ",
      "Номинальное сечение жил": "Намінальнае сячэнне жыў, мм²",
      "Число жил": "Лік жыў",
      "Максимальный диаметр": "Максімальны дыяметр, мм",
      "Масса": "Маса, кг/км",
      "МАКРОРАЗМЕРЫ": "МАКРАРАЗМЕРЫ",      
    },
  },
};
