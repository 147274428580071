import {https} from "../config/index.js";

export const getProducts = async () => {
    const response = await https.get(`v2/products/`);
    return response.data || [];
}

export const updateProductImage = async ({ productId, mediaId }) => {
    console.log(productId, mediaId);
    const response = await https.get(`v2/products/${productId}/image/${mediaId}`);
    return response.data;
}

export const getLegacyProduct = async (lng, id) => {
    const response = await https.get(`/${lng}/product/${id}`);
    return response.data?.data || [];
};