import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Breadcrumb} from 'react-bootstrap'
import PaginationModule from '../../ui/pagination/Pagination'
import s from './Photogallery.module.scss'
import scrollIntoView from 'scroll-into-view'
import {v4} from 'uuid'
import {useTranslation} from 'react-i18next'

const PhotoGalleryPage = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [activePage, setActivePage] = useState(0)
  const [activeLinks, setActiveLinks] = useState([])

  const links = [
    {href: '/1', text: '1'},
    {href: '/2', text: '2'},
    {href: '/3', text: '3'},
    {href: '/4', text: '4'},
    {href: '/5', text: '5'},
    {href: '/6', text: '6'},
    {href: '/7', text: '7'},
  ]

  const computeActiveLinks = page => {
    if (page <= 2) {
      setActiveLinks([...links.filter((link, idx) => idx < 4)])
    } else if (page >= links.length - 3) {
      setActiveLinks([...links.filter((link, idx) => idx > links.length - 5)])
    } else {
      setActiveLinks([...links.filter((link, idx) => idx >= page - 1 && idx <= page + 2)])
    }
  }

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })

    computeActiveLinks(0)
  }, [])

  const initialData = [
    {
      img: '/photo-gallery1.jpg',
      title: `${t(
        'День всенародной памяти жертв Великой Отечественной Войны и геноцида белорусского народа'
      )}`,
      href: 'day-of-war',
    },
    {
      img: '/photo-gallery2.jpg',
      title: `${t('Поездка в цирк')}`,
      href: 'circus',
    },
    {
      img: '/photo-gallery3.jpg',
      title: `${t('Водный поход на байдарках')}`,
      href: 'water-trip',
    },
    {
      img: '/photo-gallery4.jpg',
      title: `${t('Командноштабные учения 2022')}`,
      href: 'learning',
    },
    {
      img: '/photo-gallery5.jpg',
      title: `${t('Профориентация экскурсия фото')}`,
      href: 'proforientation',
    },
    {
      img: '/photo-gallery6.jpg',
      title: `${t('Белорускабель отметил 9 мая')}`,
      href: '9-may',
    },
    {
      img: '/photo-gallery7.jpg',
      title: `${t('День победы')}`,
      href: 'winner-day',
    },
    {
      img: '/photo-gallery8.jpg',
      title: `${t('Чернобыль - мы обязаны знать и помнить')}`,
      href: 'memory',
    },
  ]

  return (
    <>
      <div className={s.container}>
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Меню Фотогалерея')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Меню Фотогалерея')}</div>

        <div className={s.wrapper}>
          {initialData.map(data => (
            <div className={s.item} key={v4()}>
              <div onClick={() => navigate(data.href)}>
                <img src={data.img} className={s.img} alt="Фото" />

                <div className={s.flexWrapper}>
                  <span className={s.title}>{data.title}</span>
                  <span className={s.link}>{t('Кнопка Подробнее')}</span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* <PaginationModule
          activePage={activePage}
          links={links}
          activeLinks={activeLinks}
          setActivePage={setActivePage}
          computeActiveLinks={computeActiveLinks}
        /> */}
      </div>
    </>
  )
}

export default PhotoGalleryPage
