import {https} from "../config/index.js";

export const getSettings = async () => {
    const response = await https.get(`v2/settings/`);
    return response.data || [];
};

export const getPrivateSettings = async () => {
    try {
        const response = await https.get(`v2/settings/private`);
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
}

export const saveSettings = async (settings) => {
    try {
        const response = await https.post(`v2/settings/`, settings);
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
}
