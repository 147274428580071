import React, {useState} from 'react'
import AccordionItem from './Tab/Tab'
import s from './Tabs.module.scss'

const Tabs = ({data}) => {
  let initialState = []

        
  if (undefined!== data && data.construction) {
    initialState.push({
      id: 1,
      title: 'Конструкция',
      paragraph: data.construction,
    })
  }

  if (undefined!== data &&  data.specification) {
    initialState.push(
      data.specification && {
        id: 2,
        title: 'Технические характеристики',
        paragraph: data.specification,
      }
    )
  }

  if (undefined!== data &&  data.exploitation) {
    initialState.push({
      id: 3,
      title: 'Условия эксплутации',
      paragraph: data.exploitation,
    })
  }

  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div>
      {initialState.map(state => (
        <AccordionItem
          activeIndex={activeIndex}
          title={state.title}
          paragraph={state.paragraph}
          key={state.id}
          id={state.id}
          setActiveIndex={setActiveIndex}
        />
      ))}
    </div>
  )
}

export default Tabs
