import React, {useState} from 'react'
import s from './CardContent.module.scss'
import provod from '../../../../media/main/default.png'

const CardContent = ({data}) => {
  const [activeImg, setActiveImg] = useState({id: 1, url: '/publicPages/provod1.png'})

  const initialState = [
    {id: 1, url: '/publicPages/provod1.png'},
    {id: 2, url: '/publicPages/provod.png'},
    {id: 3, url: '/publicPages/provod.png'},
  ]

  return (
    <div>
      <div className={s.flex}>
        <div className={s.flexItem}>
          <img
            src={data.image && data.image.includes('undefined') ? provod : data.image}
            alt="Провод"
            className={`${s.img} ${s.active}`}
          />
          {/* <div className={s.staticImages}>
            {initialState.map(state => (
              <img
                src={state.url}
                alt="Провод"
                className={`${s.imgDefault} ${state.id === activeImg.id ? s.active : ''}`}
                onClick={() => setActiveImg(state)}
              />
            ))}
          </div> */}
        </div>
        <div className={s.flexItem}>
          <p className={s.title}>Назначение</p>
          <p className={s.paragraph}>{data.purpose}</p>
        </div>
      </div>
    </div>
  )
}

export default CardContent
