import {lazy, memo, Suspense, useMemo} from "react";
import {useSelector} from "react-redux";
import {useQuery} from "@tanstack/react-query";
import {getSettings} from "../../api/settings.endpoint.js";
import {getLegacyProduct} from "../../api/products.endpoint.js";
import {useParams} from "react-router-dom";

const DefaultTemplate = lazy(() => import("../CardPage/CardPage.js"));
const TemplateOne = lazy(() => import("../CardPage/TemplateOne.jsx"));

const ProductCardBuilder = memo(() => {
    const { id } = useParams();
    const { language } = useSelector((store) => store.language);
    const productQuery = useQuery({
        enabled: !!id,
        queryKey: ['product', language, id],
        queryFn: () => getLegacyProduct(language, id),
    });
    const settingsQuery = useQuery({
        queryFn: getSettings,
        queryKey: ['settings', 'all'],
    });

    const productTemplate = useMemo(() => {
        if (productQuery.data && productQuery.data.template) {
            return productQuery.data.template;
        }
        return 'default';
    }, [productQuery.data])

    if (productQuery.isLoading || settingsQuery.isLoading) {
        return (
            <div>
                <img src="/loading.gif" alt="Загрузка" />
            </div>
        );
    }

    if (productQuery.data) {
        return (
            <Suspense fallback={<img src="/loading.gif" alt="Загрузка" />}>
                {productTemplate === 'default' ? <DefaultTemplate product={productQuery.data} /> : <TemplateOne product={productQuery.data} />}
            </Suspense>
        );
    }

    return (<div />);
});

export default ProductCardBuilder;