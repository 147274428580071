export const calc = {
  ru: {
    translation: {
      'ТОКОПРОВОДЯЩАЯ ЖИЛА': 'ТОКОПРОВОДЯЩАЯ ЖИЛА',
      'ОП Алюминиевая': 'Алюминиевая',
      'Медная мягкая проволока класса 4 по ГОСТ 22483-77':
        'Медная мягкая проволока класса 4 по ГОСТ 22483-77',
      'ОП Медная': 'Медная',
      'Медная проволока': 'Медная проволока',
      'Медная луженая проволока': 'Медная луженая проволока',
      'Медная проволока, лужёная ПОСом': 'Медная проволока, лужёная ПОСом',
      'Медная мягкая проволока': 'Медная мягкая проволока',
      'Медная проволока, луженая оловом': 'Медная проволока, луженая оловом',
      'ЗАГ ИЗОЛЯЦИЯ': 'ИЗОЛЯЦИЯ',
      'Полимерные композиции не содержащие галогенов':
        'Полимерные композиции не содержащие галогенов',
      'Нить полиэфирная': 'Нить полиэфирная',
      'Полиэтилен низкого давления': 'Полиэтилен низкого давления',
      'Сшитый полиэтилен': 'Сшитый полиэтилен',
      'ОП Полиэтилен': 'Полиэтилен',
      'ПВХ пластикат пониженной пожароопасности': 'ПВХ пластикат пониженной пожароопасности',
      'Радиационносшитый полиэтилен': 'Радиационносшитый полиэтилен',
      'Термостабилизированный радиационносшитый полиэтилен':
        'Термостабилизированный радиационносшитый полиэтилен',
      'Внутренний слой - ПВХ': 'Внутренний слой - ПВХ',
      'Поливинилхлоридный пластикат': 'Поливинилхлоридный пластикат',
      'Радиационносшитый фторпласт 2М': 'Радиационносшитый фторпласт 2М',
      'Наружный слой - ПВХ повышенной теплостойкости':
        'Наружный слой - ПВХ повышенной теплостойкости',
      'ОП Полипропилен': 'Полипропилен',
      'ОП Стекловолокно': 'Стекловолокно',
      'ОБОЛОЧКА КАБЕЛЯ': 'ОБОЛОЧКА КАБЕЛЯ',
      'Полимерные композиции не содержащие галогенов':
        'Полимерные композиции не содержащие галогенов',
      'Поливинилхлоридный пластикат не распространяющий горение':
        'Поливинилхлоридный пластикат не распространяющий горение',
      'ПВХ пластикат пониженной горючести': 'ПВХ пластикат пониженной горючести',
      'Поливинилхлоридный пластикат повышенной теплостойкости':
        'Поливинилхлоридный пластикат повышенной теплостойкости',
      'ПВХ пластикат пониженной пожароопасности': 'ПВХ пластикат пониженной пожароопасности',
      'Светостабилизированный полиэтилен': 'Светостабилизированный полиэтилен',
      'Поливинилхлоридный пластикат': 'Поливинилхлоридный пластикат',
      'С ЭКРАНОМ': 'С ЭКРАНОМ',
      'ДА ОП': 'ДА',
      'НОМИНАЛЬНОЕ СЕЧЕНИЕ ЖИЛ, ММ²': 'НОМИНАЛЬНОЕ СЕЧЕНИЕ ЖИЛ, ММ²',
      'ЧИСЛО ЖИЛ': 'ЧИСЛО ЖИЛ',
      'ЧИСЛО ПАР': 'ЧИСЛО ПАР',
      'Кнопка Подобрать': 'Подобрать',
      'Кнопка Очистить': 'Очистить',
    },
  },
  en: {
    translation: {
      'ТОКОПРОВОДЯЩАЯ ЖИЛА': 'CONDUCTING CORE',
      'ОП Алюминиевая': 'Aluminum',
      'Медная мягкая проволока класса 4 по ГОСТ 22483-77':
        'Copper soft wire class 4 in accordance with GOST 22483-77',
      'ОП Медная': 'Copper',
      'Медная проволока': 'Copper wire',
      'Медная луженая проволока': 'Tinned copper wire',
      'Медная проволока, лужёная ПОСом': 'Copper wire, tinned with POS',
      'Медная мягкая проволока': 'Copper soft wire',
      'Медная проволока, луженая оловом': 'Copper wire, tin-plated',
      'ЗАГ ИЗОЛЯЦИЯ': 'INSULATION',
      'Полимерные композиции не содержащие галогенов': 'Halogen-free polymer compositions',
      'Нить полиэфирная': 'Polyester thread',
      'Полиэтилен низкого давления': 'Low-pressure polyethylene',
      'Сшитый полиэтилен': 'Cross-linked polyethylene',
      'ОП Полиэтилен': 'Polyethylene',
      'ПВХ пластикат пониженной пожароопасности': 'PVC compound of low fire hazard',
      'Радиационносшитый полиэтилен': 'Radiation crosslinked polyethylene',
      'Термостабилизированный радиационносшитый полиэтилен':
        'Heat stabilized radiation crosslinked polyethylene',
      'Внутренний слой - ПВХ': 'Inner layer - PVC',
      'Поливинилхлоридный пластикат': 'PVC compound',
      'Радиационносшитый фторпласт 2М': 'Radiation-crosslinked fluoroplast 2M',
      'Наружный слой - ПВХ повышенной теплостойкости': 'Outer layer - high heat resistant PVC',
      'ОП Полипропилен': 'Polypropylene',
      'ОП Стекловолокно': 'Fiberglass',
      'ОБОЛОЧКА КАБЕЛЯ': 'CABLE SHELL',
      'Поливинилхлоридный пластикат не распространяющий горение':
        'Flame retardant polyvinyl chloride compound',
      'ПВХ пластикат пониженной горючести': 'PVC compound of low flammability',
      'Поливинилхлоридный пластикат повышенной теплостойкости':
        'Polyvinylchloride compound of increased heat resistance',
      'Светостабилизированный полиэтилен': 'Light stabilized polyethylene',
      'С ЭКРАНОМ': 'WITH SCREEN',
      'ДА ОП': 'YES',
      'НОМИНАЛЬНОЕ СЕЧЕНИЕ ЖИЛ, ММ²': 'NOMINAL SECTION OF CORE, MM²',
      'ЧИСЛО ЖИЛ': 'NUMBER OF LIVES',
      'ЧИСЛО ПАР': 'NUMBER OF PAIRS',
      'Кнопка Подобрать': 'Pick up',
      'Кнопка Очистить': 'Clear',
    },
  },
  bel: {
    translation: {
      'ТОКОПРОВОДЯЩАЯ ЖИЛА': 'Токаправодная жыла',
      'ОП Алюминиевая': 'Алюмініевая',
      'Медная мягкая проволока класса 4 по ГОСТ 22483-77':
        'Медны мяккі дрот класа 4 па ДАСТ 22483-77.',
      'ОП Медная': 'Медная',
      'Медная проволока': 'Медны дрот',
      'Медная луженая проволока': 'Медны луджаны дрот',
      'Медная проволока, лужёная ПОСом': 'Медны дрот, лужаны пасам',
      'Медная мягкая проволока': 'Медны мяккі дрот',
      'Медная проволока, луженая оловом': 'Медны дрот, луджаны цынам.',
      'ЗАГ ИЗОЛЯЦИЯ': 'ІЗАЛЯЦЫЯ',
      'Полимерные композиции не содержащие галогенов':
        'Палімерныя кампазіцыі не змяшчаюць галагенаў',
      'Нить полиэфирная': 'Нітка поліэфірная',
      'Полиэтилен низкого давления': 'Поліэтылен нізкага ціску',
      'Сшитый полиэтилен': 'Пашыты поліэтылен',
      'ОП Полиэтилен': 'Поліэтылен',
      'ПВХ пластикат пониженной пожароопасности': 'ПВХ пластыкат паніжанай пажаранебяспекі',
      'Радиационносшитый полиэтилен': 'Радыяцыйна пашыты поліэтылен',
      'Термостабилизированный радиационносшитый полиэтилен':
        'Тэрмастабілізаваны радыяцыйна пашыты поліэтылен',
      'Внутренний слой - ПВХ': 'Унутраны пласт - ПВХ',
      'Поливинилхлоридный пластикат': 'Полівінілхларыдны пластыкат',
      'Радиационносшитый фторпласт 2М': 'Радыяцыйна пашыты фторпласт 2М',
      'Наружный слой - ПВХ повышенной теплостойкости':
        'Вонкавы пласт - ПВХ падвышанай цеплаўстойлівасці',
      'ОП Полипропилен': 'Поліпрапілен',
      'ОП Стекловолокно': 'Шкловалакно',
      'ОБОЛОЧКА КАБЕЛЯ': 'Абалонка кабеля',
      'Поливинилхлоридный пластикат не распространяющий горение':
        'Полівінілхларыдны пластыкат не распаўсюджвае гарэнне',
      'ПВХ пластикат пониженной горючести': 'ПВХ пластыкат паніжанай гаручасці',
      'Поливинилхлоридный пластикат повышенной теплостойкости':
        'Полівінілхларыдны пластыкат павышанай цеплаўстойлівасці',
      'Светостабилизированный полиэтилен': 'Святлостабілізаваны поліэтылен',
      'С ЭКРАНОМ': 'З ЭКРАНАМ',
      'ДА ОП': 'ТАК',
      'НОМИНАЛЬНОЕ СЕЧЕНИЕ ЖИЛ, ММ²': 'НАМІНАЛЬНАЯ ПАСЯНЕ ЖЫЛ, ММ²',
      'ЧИСЛО ЖИЛ': 'ЛІК ЖЫЛ',
      'ЧИСЛО ПАР': 'ЛІК ПАР',
      'Кнопка Подобрать': 'Падабраць',
      'Кнопка Очистить': 'Ачысціць',
    },
  },
}
