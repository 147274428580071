import React, {useEffect} from 'react'
import {Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import s from './Schedule.module.scss'
import {useTranslation} from 'react-i18next'

const SchedulePage = () => {
  const {t} = useTranslation()

  const initialData = [
    {
      name: `${t('Шилов Виталий Петрович')}`,
      work: `${t('Директор ОАО «Беларускабель»')}`,
      days: `${t('День Понедельник')}`,
      time: '15:30 - 17:00',
      phone: '+375 (236) 24-77-29',
    },

    {
      name: `${t('Барановский Сергей Григорьевич')}`,
      work: `${t('Главный инженер')}`,
      days: `${t('День Четверг')}`,
      time: '15:30 - 17:00',
      phone: '+375 (236) 25-23-04',
    },

    {
      name: `${t('Фиранчук Дмитрий Николаевич')}`,
      work: `${t('Зам. директора по развитию')}`,
      days: `${t('День Вторник')}`,
      time: '15:30 - 17:00',
      phone: '+375 (236) 25-41-62',
    },

    {
      name: `${t('Камеш Владимир Александрович')}`,
      work: `${t('Зам. директора по снабжению и организации перевозок')}`,
      days: `${t('День Четверг')}`,
      time: '15:30 - 17:00',
      phone: '+375 (236) 25-41-14',
    },

    {
      name: `${t('Фещенко Светлана Леонидовна')}`,
      work: `${t('Зам. директора по идеологической работе, кадрам и соц. вопросам')}`,
      days: `${t('День Ежедневно')}`,
      time: '',
      phone: '+375 (236) 24-77-29',
    },
  ]

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  return (
    <>
      <div className={s.container}>
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('График личного приема граждан меню')}</Breadcrumb.Item>
        </Breadcrumb>

        <div className="commonLabels">
          {t(
            'График личного приема граждан, индивидуальных предпринимателей и представителей юридических лиц руководством ОАО Беларускабель'
          )}
        </div>
        <div className={s.tableWrapper}>
          <table className={s.table}>
            <tr>
              <th>{t('Оформление ФИО')}</th>
              <th>{t('Должность')}</th>
              <th>{t('Дни приёма')}</th>
              <th>{t('Время приёма')}</th>
              <th>{t('Телефон')}</th>
            </tr>
            {initialData.map(data => (
              <tr>
                <td className={s.name}>{data.name}</td>
                <td>{data.work}</td>
                <td>{data.days}</td>
                <td>{data.time}</td>
                <td>{data.phone}</td>
              </tr>
            ))}
          </table>
        </div>

        <p className={s.paragraph}>{t('Запись на прием по телефону')} +375 (236) 24-77-29</p>
      </div>
    </>
  )
}

export default SchedulePage
