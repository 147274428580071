export const schedule = {
  ru: {
    translation: {
      'График личного приема граждан, индивидуальных предпринимателей и представителей юридических лиц руководством ОАО Беларускабель':
        'График личного приема граждан, индивидуальных предпринимателей и представителей юридических лиц руководством ОАО Беларускабель',
      'Слово Должность': 'Должность',
      'Дни приёма': 'Дни приёма',
      'Время приёма': 'Время приёма',
      'Слово Телефон': 'Телефон',
      'Запись на прием по телефону': 'Запись на прием по телефону',
      'Зам. директора по идеологической работе, кадрам и соц. вопросам':
        'Зам. директора по идеологической работе, кадрам и соц. вопросам',
      'День Понедельник': 'Понедельник',
      'День Четверг': 'Четверг',
      'День Вторник': 'Вторник',
      'День Ежедневно': 'Ежедневно',
    },
  },
  en: {
    translation: {
      'График личного приема граждан, индивидуальных предпринимателей и представителей юридических лиц руководством ОАО Беларускабель':
        'Schedule of personal reception of citizens, individual entrepreneurs and representatives of legal entities by the management of JSC Belaruskabel',
      'Слово Должность': 'Job title',
      'Дни приёма': 'Reception days',
      'Время приёма': 'Time of receipt',
      'Слово Телефон': 'Telephone',
      'Запись на прием по телефону': 'Making an appointment by phone',
      'Зам. директора по идеологической работе, кадрам и соц. вопросам':
        'Deputy director for ideological work, personnel and social. issues',
      'День Понедельник': 'Monday',
      'День Четверг': 'Thursday',
      'День Вторник': 'Tuesday',
      'День Ежедневно': 'Everyday',
    },
  },
  bel: {
    translation: {
      'График личного приема граждан, индивидуальных предпринимателей и представителей юридических лиц руководством ОАО Беларускабель':
        'Графік асабістага прыёму грамадзян, індывідуальных прадпрымальнікаў і прадстаўнікоў юрыдычных асоб кіраўніцтвам ААТ Беларускабель',
      'Слово Должность': 'Пасада',
      'Дни приёма': 'Дні прыёму',
      'Время приёма': 'Час прыёму',
      'Слово Телефон': 'Тэлефон',
      'Запись на прием по телефону': 'Запіс на прыём па тэлефоне',
      'Зам. директора по идеологической работе, кадрам и соц. вопросам':
        'Зам. дырэктара па ідэалагічнай рабоце, кадрах і сац. пытаннях',
      'День Понедельник': 'Панядзелак',
      'День Четверг': 'Чацвер',
      'День Вторник': 'Аўторак',
      'День Ежедневно': 'Штодня',
    },
  },
}
