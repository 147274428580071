import {createAsyncThunk} from "@reduxjs/toolkit";
import {https} from "../../config/index.js";

export const sendFeedbackEmail = createAsyncThunk(
    'feedback/sendEmail',
    async ({ data, lang = "ru" }) => {
        return await https
            .post(`/${lang}/feedbacks`, {
                commit: data.commit,
                company: data.company,
                email: data.email,
                full_name: data.name,
                phone: data.phone,
            });
})