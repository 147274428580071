import {memo, useCallback, useMemo, useState} from "react";
import {Button, Form, Modal, notification} from "antd";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {CategoryForm} from "./CategoryForm.jsx";
import {updateCategory} from "../api.js";

export const EditCategoryModal = memo(({ category, subCategories = [], jwt }) => {
    const [api, contextHolder] = notification.useNotification();
    const queryClient = useQueryClient();
    const [form] = Form.useForm();

    const [isOpen, setIsOpen] = useState(false);

    const initialValues = useMemo(() => ({
        ...category,
        subCategories: category.subCategories.map((subCategory) => subCategory.id),
    }), [category]);

    const openModalHandler = useCallback(() => setIsOpen(true), []);
    const closeModalHandler = useCallback(() => setIsOpen(false), []);

    const updateCategoryMutation = useMutation({
        mutationFn: updateCategory,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['category', 'all']})
    });

    const onFormSubmit = useCallback(async (values) => {
        try {
            await updateCategoryMutation.mutateAsync({
                ...category,
                ...values,
            });
            api.success({ message: 'Категория обновлена!' });
            closeModalHandler();
        } catch (e) {
            console.error(e);
            api.error({  message: 'Произошла ошибка при редактировании категории' });
        }
    }, [api, category, closeModalHandler]);

    return (
        <>
            {contextHolder}
            <Button type="primary" size="small" onClick={openModalHandler}>Редактировать</Button>
            <Modal width={1000} title="Редактировать категорию" open={isOpen} onCancel={closeModalHandler} footer={null} destroyOnClose>
                <CategoryForm
                    jwt={jwt}
                    formRef={form}
                    category={initialValues}
                    subCategories={subCategories}
                    isLoading={updateCategoryMutation.isLoading}
                    onCancel={closeModalHandler}
                    onSubmit={onFormSubmit}
                />
            </Modal>
        </>
    );
});

