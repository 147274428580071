import React from 'react'
import {Link} from 'react-router-dom'
import s from './Advantages.module.scss'
import {useTranslation} from 'react-i18next'
import {changeCommersModalShow} from '../../../store/reducers/AuthorizeReducer'
import {useDispatch} from 'react-redux'

const Advantages = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  return (
    <div className={s.container}>
      
      <div className={s.flex}>
        <div className={s.flexItem}>
          <div className={s.flexImage}>
            <img src="monitor-with-backet.png" alt="Монитор с корзиной" className={s.monitor} />
          </div>

          <p className={s.title}>{t('Розничная торговля от 1 метра, напрямую от производителя')}</p>
          <p className={s.paragraph}>
            <Link to="/shop" target={'_blank'}>{t('Интернет-магазин розничной торговли')}</Link>
          </p>
        </div>

        {/* <div className={s.flexItem}>
          <div className={s.flexImage}>
            <img src="notebook-with-pensil.png" alt="Блокнот с карандашом" />
          </div>

          <p className={s.title}>{t('Оптовые цены для юр лиц')}</p>
          <p className={s.paragraph}>
            <span onClick={() => dispatch(changeCommersModalShow())}>
              {t('Запросить коммерческое предложение')}
            </span>
          </p>
        </div> */}

        <div className={s.flexItem}>
          <div className={s.flexImage}>
            <img src="hands.jpg" alt="Два человека пожимают друг другу руки" />
          </div>
          <p className={s.title}>{t('История становления ОАО «Беларускабель»')}</p>
          <p className={s.paragraph}>
            <Link to="/history">{t('Познакомиться с организацией')}</Link>
          </p>
        </div>

        {/*<div className={s.flexItem}>
          <div className={s.flexImage}>
            <img src="calculator.png" alt="Калькулятор" />
          </div>
          
          <p className={s.title}>{t('Подбор продукции по характеристикам')}</p>
          <p className={s.paragraph}>
            <Link to="/calc">{t('Калькулятор кабеля')}</Link>
          </p>
      </div>*/}
        
        <div className={s.flexItem}>
          <div className={s.flexImage}>
            <img src="svg/box.png" alt="Package return icon" />
          </div>

          <p className={s.title}>{t('Свободные остатки готовой продукции на складе')}</p>
          <p className={s.paragraph}>
            <Link to="reports/product-remainders.xlsx" target="_blank">
              {t('Посмотреть остатки')}
            </Link>
          </p>
        </div>

      </div>
    </div>
  )
}
export default Advantages
