import './Header.css'
import s from './extra-styles.module.scss'
import {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import user from '../../media/main/user1.svg'
import telephone from '../../media/main/phone-red.svg'
import pin from '../../media/main/map-pin-red.svg'
import DesktopBurger from './DesktopBurger/DesktopBurger'
import {useDispatch, useSelector} from 'react-redux'
import message from '../../media/main/message.svg'
import OrderBacket from './OrderBacket'
import {MyForm, MyVerticallyCenteredModal} from './ModalWindow'
import {useTranslation} from 'react-i18next'
import {setLanguage} from '../../store/reducers/LanguageReducer'
import {auth} from '../../store/async/asyncAuth'
import {changeModalShow} from '../../store/reducers/AuthorizeReducer'

export default function Header() {
  const {t, i18n} = useTranslation()
  let [isActive, setIsActive] = useState(false)
  const dispatch = useDispatch()
  const {language} = useSelector(store => store.language)
  const {authenticated, email, password, showModal, showCommersModal} = useSelector(
    store => store.auth
  )
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [activeLanguage, setActiveLanguage] = useState(() => {
    switch (language) {
      case 'en':
        return 1;
      case 'be':
        return 2;
      case 'ru':
      default:
        return 0;
    }
  });
  const [isDesktopActive, setIsDesktopActive] = useState(false)
  const changeLanguage = id => setActiveLanguage(id)
  const [form, setForm] = useState(showCommersModal)

  useEffect(() => {
    setForm(showCommersModal)
  }, [showCommersModal])

  useEffect(() => {
    localStorage.setItem('lng', language);
  }, [language]);

  const setModalShow = args => {
    if (!args) return
    dispatch(changeModalShow())
  }

  useEffect(() => {
    if (!activeLanguage) {
      i18n.changeLanguage('ru')
      dispatch(setLanguage('ru'))
    } else if (activeLanguage === 1) {
      i18n.changeLanguage('en')
      dispatch(setLanguage('en'))
    } else {
      i18n.changeLanguage('be')
      dispatch(setLanguage('be'))
    }
  }, [activeLanguage])

  useEffect(() => {
    if (language === 'ru') setActiveLanguage(0)
    else if (language === 'en') setActiveLanguage(1)
    else setActiveLanguage(2)
  }, [])

  const showDropdown1 = e => {
    setShow1(!show1)
  }

  const showDropdown2 = e => {
    setShow2(!show2)
  }

  const hideDropdown = e => {
    setShow1(false)
    setShow2(false)
  }

  useEffect(() => {
    setModalShow(false)
  }, [authenticated])

  useEffect(() => {
    if (document.body.clientWidth < 1301) {
      if (isActive) {
        setTimeout(() => {
          if (document.querySelector('#containerWrapper').children[2])
            document.querySelector('#containerWrapper').children[2].style.display = 'none'
          document.querySelector('footer').style.display = 'none'
        }, 300)
      } else {
        if (document.querySelector('#containerWrapper').children[2])
          document.querySelector('#containerWrapper').children[2].style.display = 'block'
        document.querySelector('footer').style.display = 'block'

        setTimeout(() => {
          try {
            document.querySelector('#containerWrapper').children[2].style.display = 'block'
            document.querySelector('footer').style.display = 'block'
          } catch {
            return
          }
        }, 300)
      }
    }
  }, [isActive])

  useEffect(() => {
    if (authenticated) {
      setModalShow(false)
    }
  }, [authenticated])

  useEffect(() => {
    if (email && password) {
      dispatch(auth({email, password}))
    }
  }, [email, password])

  return (
    <>
      {document.body.clientWidth > 1150 ? (
        <DesktopBurger isActive={isDesktopActive} setIsActive={setIsDesktopActive} />
      ) : (
        ''
      )}
      <div className="_container">
        <div className="banner">
          <div className={`${s.flexWrap}`}>
            <div>
              <div className={`${s.flexHeader}`} >
                {/* 1 */}
                <div className={`${s.flex} ${s.flexLogo} ${s.flexUnique}`}>
                  <div className={`${s.width}`}>
                    <Link to="/">
                      <img className={s.logo} src="/lbl.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className={s.logoText}>
                    {t('Производим кабельную продукцию c 1959 года')}
                  </div>
   
                </div>
                {/* 2 */}
                <div className={`${s.flex} ${s.modify} ${s.phonesLaptop}`}>
                  <div className={`${s.flex} ${s.changed}`}>
                    <div>
                      <img src={telephone} alt="Telephone" />
                    </div>
                    <div>
                      <p className={s.phonesHeader}>
                        <a href="tel:+375236247729">+375 (236) 24-77-29</a>
                        {} &ndash; {t('номер приёмной')}
                      </p>
                      <p className={s.phonesHeader}>
                        <a href="tel:+375236254742">+375 (236) 25-47-42</a>
                        {} &ndash; {t('отдел продаж')}
                      </p>
                    </div>
                  </div>
                </div>
                {/* 3 */}
                <div className={`${s.flex} ${s.modify}`} >

                <div className={`${s.flex} ${s.iconSpace}`}>
                    <div>
                      <img src={pin} width="22px" alt="Location-Mark" />
                    </div>
                    <div>
                      <p className={s.phone}>{t('г. Мозырь, ул. Октябрьская, 14.')}</p>
                    </div>
                  </div>

                  <div className={`${s.flex} ${s.iconSpace}`}>
                    <div>
                      <img src={message} width="22px" alt="Telephone" />
                    </div>
                    <div>
                      <p className={s.phone}>e-mail: vc@belaruskabel.by</p>
                    </div>
                  </div>


                </div>
                

              </div>
              <div className={s.flexFooterWrapper}>
                <div className={s.flexFooter}>
                  <div className={s.flexFooterMainWrapper}>
                    {/* <button className={s.btn} onClick={() => setForm(true)}>
                      {t('Оставить заявку')}
                    </button> */}

                    <div className={s.secondItem}>
                      <div
                        className={`${
                          !activeLanguage ? s.left : activeLanguage === 1 ? s.center : s.right
                        } ${s.changeLanguage}`}
                      >
                        <span
                          onClick={() => {
                            changeLanguage(0)
                          }}
                        >
                          <div>РУС</div>
                        </span>
                        <span
                          onClick={() => {
                            changeLanguage(1)
                          }}
                        >
                          <div>ENG</div>
                        </span>
                        <span
                          onClick={() => {
                            changeLanguage(2)
                          }}
                        >
                          <div>БЕЛ</div>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className={s.itemWrapperContainer}>
                    <OrderBacket />
                    <div className={`col-md ${s.itemWrapper}`}>
                      <img src={user} alt="user" />
                      <p className={s.button}>
                        {authenticated ? (
                          <Link to="/account" className="profile-button" variant="link">
                            {t('Личный кабинет')}
                          </Link>
                        ) : (
                          <Button
                            className="profile-button"
                            variant="link"
                            onClick={() => {
                              setModalShow(true)
                            }}
                          >
                            {t('Войти в аккаунт')}
                          </Button>
                        )}
                      </p>
                      {showModal && (
                        <MyVerticallyCenteredModal show={showModal} setModalShow={setModalShow} />
                      )}
                      {form && <MyForm form={form} setForm={setForm} />}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <button
              className={`${s.hamburgerLines} ${isActive ? s.active : ''}`}
              onClick={() => setIsActive(!isActive)}
            >
              <span className={`${s.line} ${s.line1}`}></span>
              <span className={`${s.line} ${s.line2}`}></span>
              <span className={`${s.line} ${s.line3}`}></span>
            </button>
          </div>
        </div>
      </div>

      <header>
        <div className={s.backgroundWrapper}>
          <div className={s.firstBackground}></div>
          <div className="_container">
            <nav expand="lg" className="header-nav">
              <ul className={`w-100 justify-content-between ${s.itemMenu} ${s.flex} ${s.navbar}`}>
                <li>
                  <button
                    className={`${s.hamburgerLines} ${s.modify} ${isDesktopActive ? s.active : ''}`}
                    onClick={() => setIsDesktopActive(!isDesktopActive)}
                  >
                    <span className={`${s.line} ${s.line1}`}></span>
                    <span className={`${s.line} ${s.line2}`}></span>
                    <span className={`${s.line} ${s.line3}`}></span>
                  </button>
                </li>
                <li>
                  <Link to="/products">{t('Меню каталог')}</Link>
                </li>
                <li>
                  <div
                    className={s.menuItem}
                    onMouseEnter={showDropdown2}
                    onMouseLeave={hideDropdown}
                  >
                    <p id="dropdown-basic">{t('Меню производство')}</p>
                    {show2 && (
                      <div className={s.dropdown} onClick={() => hideDropdown()}>
                        <p className={s.dropdownItem}>
                          <Link to="/qualitypolicy">{t('Менеджмент качества')}</Link>
                        </p>
                        <p className={s.dropdownItem}>
                          <Link to="/testing">{t('Центральная заводская лаборатория')}</Link>
                        </p>
                        <p className={s.dropdownItem}>
                          <Link to="/innovative">{t('Инновационная продукция')}</Link>
                        </p>
                      </div>
                    )}
                  </div>
                </li>
                <li>
                  <div
                    className={s.menuItem}
                    onMouseEnter={showDropdown1}
                    onMouseLeave={hideDropdown}
                  >
                    <p id="dropdown-basic">{t('Меню О компании')}</p>
                    {show1 && (
                      <div className={s.dropdown} onClick={() => hideDropdown()}>
                        <p className={s.dropdownItem}>
                          <Link to="/history">{t('Меню История')}</Link>
                        </p>
                        <p className={s.dropdownItem}>
                          <Link to="/management">{t('Меню Руководство')}</Link>
                        </p>
                        <p className={s.dropdownItem}>
                          <Link to="/smi-about-us">{t('СМИ о нас')}</Link>
                        </p>
                        <p className={s.dropdownItem}>
                          <Link to="/photogallery">{t('Меню Фотогалерея')}</Link>
                        </p>
                        <p className={s.dropdownItem}>
                          <Link to="/awards">{t('Наши достижения')}</Link>
                        </p>
                      </div>
                    )}
                  </div>
                </li>
                <li>
                  <Link to="/dillers">{t('Меню Дилеры')}</Link>
                </li>
                <li>
                  <Link to="/contacts">{t('Меню Контакты')}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div
          className={`${s.mobileMenu} ${isActive ? s.active : ''} extra-mobile-menu`}
          onClick={() => setIsActive(false)}
        >
          <nav className={`${s.mobileWrapper} ${isActive ? s.active : ''}`}>
            <ul>
              <li className={`${s.defaultLink} ${s.mobileLink}`}>
                <Link to="/">{t('Меню Главная')}</Link>
              </li>
              <li className={`${s.defaultLink} ${s.mobileLink}`}>
                <Link to="/products">{t('Меню каталог')}</Link>
              </li>
              <li className={`${s.defaultLink} ${s.mobileLink}`}>
                <Link to="/production">{t('Меню производство')}</Link>
                <div className={s.dropdown}>
                  <p className={s.dropdownItem}>
                    <Link to="/qualitypolicy">{t('Менеджмент качества')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/testing">{t('Центральная заводская лаборатория')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/innovative">{t('Инновационная продукция')}</Link>
                  </p>
                </div>
              </li>
              <li>
                <div className={s.menuItem}>
                  <p id="dropdown-basic" className={s.mobileLink}>
                    {t('Меню О компании')}
                  </p>
                  <div className={s.dropdown}>
                    <p className={s.dropdownItem}>
                      <Link to="/history">{t('Меню История')}</Link>
                    </p>
                    <p className={s.dropdownItem}>
                      <Link to="/management">{t('Меню Руководство')}</Link>
                    </p>
                    <p className={s.dropdownItem}>
                      <Link to="/smi-about-us">{t('СМИ о нас')}</Link>
                    </p>
                    <p className={s.dropdownItem}>
                      <Link to="/photogallery">{t('Меню Фотогалерея')}</Link>
                    </p>
                    <p className={s.dropdownItem}>
                      <Link to="/dillers">{t('Меню Дилеры')}</Link>
                    </p>
                    <p className={s.dropdownItem}>
                      <Link to="/awards">{t('Наши достижения')}</Link>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className={s.menuItem}>
                  <div className={s.dropdown}>
                    <p className={s.dropdownItem}>
                      <Link to="/history">{t('Электронный каталог')}</Link>
                    </p>
                    <p className={s.dropdownItem}>
                      <Link to="/management">{t('Предложения по импортозамещению')}</Link>
                    </p>
                    {/*<p className={s.dropdownItem}>
                      <Link to="/calc">{t('Калькулятор кабеля, база для AUTOCAD')}</Link>
                  </p>*/}
                  </div>
                </div>
              </li>
              <li className={`${s.defaultLink} ${s.mobileLink}`}>
                <Link to="/contacts">{t('Меню Контакты')}</Link>
              </li>
              <li className={`${s.defaultLink} ${s.mobileLink}`}>
                <Link to="/presscenter">{t('Меню Новости')}</Link>
              </li>
              <li>
                <div className={s.menuItem}>
                  <p id="dropdown-basic" className={s.mobileLink}>
                    {t('Меню Общее')}
                  </p>
                  <div className={s.dropdown}>
                    <p className={s.dropdownItem}>
                      <Link to="/import">{t('Предложения по импортозамещению')}</Link>
                    </p>
                    <p className={s.dropdownItem}>
                      <Link to="/vacancies">{t('Меню Вакансии')}</Link>
                    </p>
                    <p className={s.dropdownItem}>
                      <Link to="/schedule">{t('График личного приема граждан меню')}</Link>
                    </p>
                    <p className={s.dropdownItem}>
                      <Link to="/against-corruption">{t('Противодействие коррупции')}</Link>
                    </p>
                    <p className={s.dropdownItem}>
                      <Link to="/administration-procedures">{t('Административные процедуры')}</Link>
                    </p>
                    <p className={s.dropdownItem}>
                      <Link to="/for-investors">{t('Меню Акционерам')}</Link>
                    </p>
                  </div>
                </div>
              </li>
              <li className={`${s.defaultLink} ${s.mobileLink}`}>
                <Link to="/dillers">{t('Меню Дилеры')}</Link>
              </li>
              <li className={`${s.defaultLink} ${s.mobileLink}`}>
                <Link to="/cybersecurity">{t('Меню Кибербезопасность')}</Link> 
              </li>              
            </ul>
            {/* <div className={`${s.flexMobile}`}>
              <div className={`${s.flex} ${s.changed} ${s.first}`}>
                <div>
                  <img src={pin} alt="Telephone" />
                </div>
                <div>
                  <p className={s.phone}>{t('г. Мозырь, ул. Октябрьская, 14.')}</p>
                </div>
              </div>
              <div className={`${s.flex} ${s.changed}`}>
                <div>
                  <img src={telephone} alt="Telephone" />
                </div>
                <div>
                  <p className={s.phone}>
                    <a href="tel:+375236247729">+375 (236) 24-77-29</a>
                    &ndash; {t('номер приёмной')}
                  </p>
                  <p className={s.phone}>
                    <a href="tel:+375236254742">+375 (236) 25-47-42</a>
                    &ndash; {t('отдел продаж')}
                  </p>
                </div>
              </div>
            </div> */}
          </nav>
        </div>
      </header>
    </>
  )
}
