import {createSlice} from '@reduxjs/toolkit'
import { publicSearch } from 'store/async/asyncSearch'
import {getAllProducts, getProductForSite} from '../async/asyncCategories'

const initialState = {
  isLoading: true,
  data: [],
  allProducts: [],
}

export const PublicCatalog = createSlice({
  name: 'public_catalog',
  initialState,
  reducers: {},
  extraReducers: {
    [getProductForSite.pending]: state => {
      state.isLoading = true
    },
    [getProductForSite.fulfilled]: (state, action) => {
      state.isLoading = false
      state.data = action.payload
    },
    [getProductForSite.rejected]: state => {
      state.isLoading = false
    },
    [getAllProducts.pending]: state => {
      state.isLoading = true
    },
    [getAllProducts.fulfilled]: (state, action) => {
      state.isLoading = false
      state.allProducts = action.payload
    },
    [getAllProducts.rejected]: state => {
      state.isLoading = false
    },
  },
})

// Action creators are generated for each case reducer function
export const {} = PublicCatalog.actions

export default PublicCatalog.reducer
