import { BoldText } from "./styled";

export const config = [
  {
    id: 1,
    text:
      "Органами Общества являются органы управления Общества и его контрольный орган.",
    isBolded: true,
  },
  {
    id: 2,
    text: "Органами управления ОАО «Беларускабель» являются:",
    isBolded: true,
    subtext: ["общее собрание акционеров;", "наблюдательный совет."],
  },
  {
    id: 3,
    text: (
      <>
        <BoldText>Исполнительный орган</BoldText> - директор (единоличный
        исполнительный орган).
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        <BoldText>Контрольным органом</BoldText> Общества является ревизионная
        комиссия.
      </>
    ),
  },
  {
    id: 5,
    text:
      "В соответствии с решением годового общего собрания акционеров ОАО «Беларускабель» (Протокол от 24.03.2023 № 39):",
    subtext: [
      "наблюдательный совет сформирован в количестве 7 человек;",
      "ревизионная комиссия сформирована в количестве 3 человек.",
    ],
  },
  {
    id: 6,
    text:
      "Общее собрание акционеров является высшим органом управления Общества.",
  },
  {
    id: 7,
    text:
      "Общее руководство деятельностью Общества осуществляет наблюдательный совет.",
  },
  {
    id: 8,
    text:
      "Наблюдательный совет, исполнительный и контрольный органы подотчетны общему собранию акционеров.",
  },
  {
    id: 9,
    text:
      "Исполнительный орган подотчетен общему собранию акционеров и наблюдательному совету и организуют выполнение решений этих органов.",
  },
];
