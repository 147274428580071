import React, {useCallback} from 'react'
import {useState} from 'react'
import {useEffect} from 'react'
import {generatePathLocation} from '../filterLogic'
import s from './PaginationProducts.module.scss'

const PaginationProducts = ({
  activeProducts,
  activePage,
  setActivePage,
  setSearchParams,
  searchParams,
}) => {
  const [length, setLength] = useState([0])
  const [activeLinks, setActiveLinks] = useState([])

  useEffect(() => {
    let maxLength = Math.ceil(activeProducts.length / 10)

    try {
      setLength([...Array(maxLength - 1).keys()].map(i => i + 1))
    } catch (e) {
      return
    }
  }, [activeProducts])

  const computeActiveLinks = useCallback(
    page => {
      if (page <= 2) {
        setActiveLinks([...length.filter(link => link < 4)])
      } else if (page >= length.length - 3) {
        setActiveLinks([...length.filter(link => link > length.length - 5)])
      } else {
        setActiveLinks([...length.filter(link => link >= page - 2 && link <= page + 2)])
      }
    },
    [length]
  )

  useEffect(() => {
    computeActiveLinks(activePage + 1)
  }, [activePage, length])

  return (
    <div className={s.pagination}>
      {activeLinks && activeProducts.length
        ? activeLinks.map((link, idx) => (
            <div
              className={`${s.paginationItem} ${link === activePage + 1 ? s.active : ''}`}
              key={link}
              onClick={() => {
                setActivePage(link - 1)
                generatePathLocation(link, setSearchParams, searchParams)
              }}
            >
              {link}
            </div>
          ))
        : ''}
    </div>
  )
}
export default PaginationProducts
