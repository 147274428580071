export const importProd = {
  ru: {
    translation: {
      'Медная катанка КМ М001': 'Медная катанка КМ М001',
      'Алюминиевая катанка': 'Алюминиевая катанка',
      'ПВХ пластикат марки <br/><br/> И40-13А, рец.8/2':
        'ПВХ пластикат марки <br/><br/> И40-13А, рец.8/2',
      'ПВХ пластикат марки <br/><br/> О-40, рец.ОМ-40':
        'ПВХ пластикат марки <br/><br/> О-40, рец.ОМ-40',
      'ПВХ пластикат <br/><br/> ИТ-105': 'ПВХ пластикат <br/><br/> ИТ-105',
      'ПВХ пластикат <br/><br/> ППИ 20-28 ': 'ПВХ пластикат <br/><br/> ППИ 20-28 ',
      'ПВХ пластикат марки <br/><br/> НГП 30-32': 'ПВХ пластикат марки <br/><br/> НГП 30-32',
      'ПВХ пластикат марки <br/><br/> НГП 50-32 ': 'ПВХ пластикат марки <br/><br/> НГП 50-32 ',
      'ПВХ пластикат марки <br/><br/> НГП ППВ-28 ': 'ПВХ пластикат марки <br/><br/> НГП ППВ-28 ',
      'ПВХ пластикат марки <br/><br/> ППО 20-35': 'ПВХ пластикат марки <br/><br/> ППО 20-35',
      'Силиконовая резиновая смесь ': 'Силиконовая резиновая смесь ',
      'Композиция безгалогенная ': 'Композиция безгалогенная ',
      'Полиэтилен 153-10К': 'Полиэтилен 153-10К',
      'Полиэтилен 153-02К': 'Полиэтилен 153-02К',
      'Полиэтилен <br/><br/> 271-274К; 273-81К': 'Полиэтилен <br/><br/> 271-274К; 273-81К',
      'Полиэтилен термостабилизиро-ванный 102-57': 'Полиэтилен термостабилизиро-ванный 102-57',
      'Фторопласт 2М <br/><br/> марки А ': 'Фторопласт 2М <br/><br/> марки А ',
      'Суперконцентрат пигментов для окраски полиолефинов (краситель для полиэтилена)':
        'Суперконцентрат пигментов для окраски полиолефинов (краситель для полиэтилена)',
      'ПВХ пластикат концентрированно-окрашенный <br/><br/> (краситель для ПВХ)':
        'ПВХ пластикат концентрированно-окрашенный <br/><br/> (краситель для ПВХ)',
      'Сл Гидрофобинол-1': 'Гидрофобинол-1',
      'Нить стеклянная крученная комплексная <br/><br/> ЕС 6 6,8х2Z100':
        'Нить стеклянная крученная комплексная <br/><br/> ЕС 6 6,8х2Z100',
      'Пленка полиэтилентерефта-латная  марки ПЭТ-Э':
        'Пленка полиэтилентерефта-латная  марки ПЭТ-Э',
      'Фольга алюминиевая': 'Фольга алюминиевая',
      'Сл Олово': 'Олово',
      'Сл Алюмофлекс': 'Алюмофлекс',
      'Проволока стальная оцинкованная диаметром 0,3 мм. ':
        'Проволока стальная оцинкованная диаметром 0,3 мм. ',
      'Лента для бронирования кабеля': 'Лента для бронирования кабеля',
      'Лента <br/><br/> Элмикатекс 54509': 'Лента <br/><br/> Элмикатекс 54509',
      'Алюмополиэтиленовая лента АПЛ': 'Алюмополиэтиленовая лента АПЛ',
      'Водоблокирующая лента': 'Водоблокирующая лента',
      'Наименование сырья': 'Наименование сырья',
      'ГОСТ или ТУ': 'ГОСТ или ТУ',
      'Сл Объём': 'Сл Объём',
      'Сл ГОСТ': 'ГОСТ',
      'ТНПА изготовителя': 'ТНПА изготовителя',
    },
  },
  en: {
    translation: {
      'Медная катанка КМ М001': 'Copper rod KM M001',
      'Алюминиевая катанка': 'Aluminum wire rod',
      'ПВХ пластикат марки <br/><br/> И40-13А, рец.8/2':
        'PVC compound brand <br/><br/> I40-13A, rec.8/2',
      'ПВХ пластикат марки <br/><br/> О-40, рец.ОМ-40':
        'PVC compound brand <br/><br/> О-40, rec.OM-40',
      'ПВХ пластикат <br/><br/> ИТ-105': 'PVC compound <br/><br/> IT-105',
      'ПВХ пластикат <br/><br/> ППИ 20-28 ': 'PVC compound <br/><br/> PPI 20-28',
      'ПВХ пластикат марки <br/><br/> НГП 30-32': 'PVC compound grade <br/><br/> NGP 30-32',
      'ПВХ пластикат марки <br/><br/> НГП 50-32 ': 'PVC compound grade <br/><br/> NGP 50-32',
      'ПВХ пластикат марки <br/><br/> НГП ППВ-28 ': 'PVC compound grade <br/><br/> NGP PPV-28',
      'ПВХ пластикат марки <br/><br/> ППО 20-35': 'PVC compound grade <br/><br/> PPO 20-35',
      'Силиконовая резиновая смесь ': 'Silicone rubber compound',
      'Композиция безгалогенная ': 'Composition halogen-free',
      'Полиэтилен 153-10К': 'Polyethylene 153-10K',
      'Полиэтилен 153-02К': 'Polyethylene 153-02K',
      'Полиэтилен <br/><br/> 271-274К; 273-81К': 'Polyethylene <br/><br/> 271-274K; 273-81K',
      'Полиэтилен термостабилизиро-ванный 102-57': 'Heat stabilized polyethylene 102-57',
      'Фторопласт 2М <br/><br/> марки А ': 'Fluoroplast 2M <br/><br/> grade A',
      'Суперконцентрат пигментов для окраски полиолефинов (краситель для полиэтилена)':
        'Pigment superconcentrate for coloring polyolefins (dye for polyethylene)',
      'ПВХ пластикат концентрированно-окрашенный <br/><br/> (краситель для ПВХ)':
        'PVC compound concentrated-dyed <br/><br/> (dye for PVC)',
      'Сл Гидрофобинол-1': 'Hydrophobinol-1',
      'Нить стеклянная крученная комплексная <br/><br/> ЕС 6 6,8х2Z100':
        'Thread glass twisted complex <br/><br/> EU 6 6,8х2Z100',
      'Пленка полиэтилентерефта-латная  марки ПЭТ-Э': 'PET-E grade polyethylene terephthalate film',
      'Фольга алюминиевая': 'aluminum foil',
      'Сл Олово': 'Tin',
      'Сл Алюмофлекс': 'Alumoflex',
      'Проволока стальная оцинкованная диаметром 0,3 мм. ':
        'Steel wire galvanized with a diameter of 0.3 mm.',
      'Лента для бронирования кабеля': 'Cable Armor Tape',
      'Лента <br/><br/> Элмикатекс 54509': 'Tape <br/><br/> Elmicatex 54509',
      'Алюмополиэтиленовая лента АПЛ': 'Aluminum-polyethylene tape APL',
      'Водоблокирующая лента': 'Water blocking tape',
      'Наименование сырья': 'name of raw materials',
      'ГОСТ или ТУ': 'GOST or TU',
      'Сл Объём': 'Volume',
      'Сл ГОСТ': 'GOST',
      'ТНПА изготовителя': 'ROV manufacturer',
    },
  },
  bel: {
    translation: {
      'Медная катанка КМ М001': 'Медная катанка КМ М001',
      'Алюминиевая катанка': 'Алюмініевая катанка',
      'ПВХ пластикат марки <br/><br/> И40-13А, рец.8/2':
        'ПВХ пластыкат маркі <br/><br/> І40-13А, рец.8/2',
      'ПВХ пластикат марки <br/><br/> О-40, рец.ОМ-40':
        'ПВХ пластыкат маркі <br/><br/> Аб-40, рец.ОМ-40',
      'ПВХ пластикат <br/><br/> ИТ-105': 'ПВХ пластыкат <br/><br/> ІТ-105',
      'ПВХ пластикат <br/><br/> ППИ 20-28 ': 'ПВХ пластыкат <br/><br/> ППІ 20-28',
      'ПВХ пластикат марки <br/><br/> НГП 30-32': 'ПВХ пластыкат маркі НГП 30-32',
      'ПВХ пластикат марки <br/><br/> НГП 50-32 ': 'ПВХ пластыкат маркі НГП 50-32',
      'ПВХ пластикат марки <br/><br/> НГП ППВ-28 ': 'ПВХ пластыкат маркі <br/><br/> НГП ППВ-28',
      'ПВХ пластикат марки <br/><br/> ППО 20-35': 'ПВХ пластыкат маркі <br/><br/> ППА 20-35',
      'Силиконовая резиновая смесь ': 'Сіліконавая гумавая сумесь',
      'Композиция безгалогенная ': 'Кампазіцыя безгалагенная',
      'Полиэтилен 153-10К': 'Поліэтылен 153-10К',
      'Полиэтилен 153-02К': 'Поліэтылен 153-02К',
      'Полиэтилен <br/><br/> 271-274К; 273-81К': 'Поліэтылен <br/><br/> 271-274К; 273-81К',
      'Полиэтилен термостабилизиро-ванный 102-57': 'Поліэтылен термостабилизиро-ванны 102-57',
      'Фторопласт 2М <br/><br/> марки А ': 'Фтарапласт 2М <br/><br/> маркі А',
      'Суперконцентрат пигментов для окраски полиолефинов (краситель для полиэтилена)':
        'Суперканцэнтрат пігментаў для афарбоўкі поліалефінаў (фарбавальнік для поліэтылену)',
      'ПВХ пластикат концентрированно-окрашенный <br/><br/> (краситель для ПВХ)':
        'ПВХ пластыкат канцэнтравана-афарбаваны <br/><br/> (фарбавальнік для ПВХ)',
      'Сл Гидрофобинол-1': 'Гідрафабінол-1',
      'Нить стеклянная крученная комплексная <br/><br/> ЕС 6 6,8х2Z100':
        'Нітка шкляная кручаная комплексная <br/><br/> ЕС 6 6,8х2Z100',
      'Пленка полиэтилентерефта-латная  марки ПЭТ-Э': 'Плёнка поліэтылентэрэфта-латная маркі ПЭТ-Э',
      'Фольга алюминиевая': 'Фальга алюмініевая',
      'Сл Олово': 'Волава',
      'Сл Алюмофлекс': 'Алюмафлекс',
      'Проволока стальная оцинкованная диаметром 0,3 мм. ':
        'Дрот стальны ацынкаваны дыяметрам 0,3 мм.',
      'Лента для бронирования кабеля': 'Стужка для браніравання кабеля',
      'Лента <br/><br/> Элмикатекс 54509': 'Стужка <br/><br/> Элмікатэкс 54509',
      'Алюмополиэтиленовая лента АПЛ': 'Алюмаполіэтыленавая стужка АПЛ',
      'Водоблокирующая лента': 'Водаблакавальная стужка',
      'Наименование сырья': 'Найменне сыравіны',
      'ГОСТ или ТУ': 'ДАСТ ці ТУ',
      'Сл Объём': "Сл Аб'ём",
      'Сл ГОСТ': 'ДАСТ',
      'ТНПА изготовителя': 'ТНПА вытворцы',
    },
  },
}
