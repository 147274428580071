import {memo, useCallback, useState} from "react";
import {Button, Form, Modal, notification} from "antd";
import {SubCategoryForm} from "./SubCategoryForm.jsx";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {createSubCategory} from "../api.js";

export const CreateSubCategoryModal = memo(({jwt}) => {
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const [isOpen, setIsOpen] = useState(false);
    const queryClient = useQueryClient();
    const createSubCategoryMutation = useMutation({
        mutationFn: createSubCategory,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['subCategory', 'all']})
    });

    const openModalHandler = useCallback(() => setIsOpen(true), []);
    const closeModalHandler = useCallback(() => setIsOpen(false), []);

    const onFormSubmitHandler = useCallback(async (values) => {
        try {
            await createSubCategoryMutation.mutateAsync(values);
            api.success({ message: 'Подкатегория создана!' });
            await form.resetFields();
            closeModalHandler();
        } catch (e) {
            console.error(e);
            api.error({  message: 'Произошла ошибка при сохранении категории' });
        }
    }, [api, closeModalHandler, form]);

    return (
        <>
            {contextHolder}
            <Button type="primary" onClick={openModalHandler}>Добавить категорию</Button>
            <Modal width={600} title="Создать подкатегорию" open={isOpen} onCancel={closeModalHandler} footer={null} destroyOnClose>
                <SubCategoryForm
                    form={form}
                    isLoading={createSubCategoryMutation.isLoading}
                    onCancel={closeModalHandler}
                    onSubmit={onFormSubmitHandler}
                />
            </Modal>
        </>
    );
});
