import {Row, Col, Form} from 'react-bootstrap'
import './Search.css'
import s from './Search.module.scss'
import searchBlack from '../../media/main/search-black.svg'
import {useTranslation} from 'react-i18next'
import {useCallback, useEffect, useRef} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

export default function Search() {
    const [searchParams] = useSearchParams();
    const {t} = useTranslation()
    const navigate = useNavigate()
    const searchInputRef = useRef(null);

    useEffect(() => {
        const searchQuery = searchParams.get('q');
        if (searchInputRef.current && searchQuery?.length) {
            searchInputRef.current.value = searchQuery;
        }
    }, [searchInputRef, searchParams]);

    const onFormSubmitHandler = useCallback(async (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const searchQuery = data.get('search-query');
        if (searchQuery.length > 0) {
            navigate(`/products?q=${encodeURIComponent(searchQuery)}`);
        }
    }, [navigate]);

    return (
        <Form name="search-form" onSubmit={onFormSubmitHandler}>
            <div className="search-container-wrapper">
                <div className="search-container">
                    <div className={'search-inputs-wrapper'}>
                        <Row className="search-inputs">
                            <Col className={s.flex}>
                                <img src={searchBlack} alt="search button" className={s.searchIcon}/>
                                <input
                                    type="text"
                                    placeholder={`${t('Поиск по марке кабеля')}`}
                                    className="seacrh-text-block"
                                    name="search-query"
                                    id="search-query"
                                    ref={searchInputRef}
                                ></input>
                            </Col>
                            <Col className="col-md-2">
                                <button className="search-button" variant="danger" type="submit">
                                    {t('Кнопка Искать')}
                                </button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Form>

    )
}
