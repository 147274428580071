import {memo, useCallback, useState} from "react";
import {Button, Form, Modal, notification} from "antd";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {createCategory} from "../api.js";
import {CategoryForm} from "./CategoryForm.jsx";


export const CreateCategoryModal = memo(({jwt, subCategories}) => {
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const createCategoryMutation = useMutation({
        mutationFn: createCategory,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['category', 'all']})
        }
    });
    const [isOpen, setIsOpen] = useState(false);

    const openModalHandler = useCallback(() => setIsOpen(true), []);
    const closeModalHandler = useCallback(() => setIsOpen(false), []);

    const onFormSubmitHandler = useCallback(async (values) => {
        try {
            await createCategoryMutation.mutateAsync(values);
            api.success({ message: 'Категория создана!' });
            await form.resetFields();
            closeModalHandler();
        } catch (e) {
            console.error(e);
            api.error({  message: 'Произошла ошибка при сохранении категории' });
        }
    }, [api, closeModalHandler, form]);

    return (
        <>
            {contextHolder}
            <Button type="primary" onClick={openModalHandler}>Добавить категорию</Button>
            <Modal width={1000} title="Создать категорию" open={isOpen} onCancel={closeModalHandler} footer={null} destroyOnClose>
                <CategoryForm
                    jwt={jwt}
                    formRef={form}
                    isLoading={createCategoryMutation.isLoading}
                    subCategories={subCategories}
                    onCancel={closeModalHandler}
                    onSubmit={onFormSubmitHandler}
                />
            </Modal>
        </>
    )
});