import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import s from './DesktopBurger.module.scss'
import pin from '../../../media/main/map-pin-red.svg'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

const DesktopBurger = ({isActive, setIsActive}) => {
  const [isHold, setIsHold] = useState(false)
  const [scrollWidth, setScrollWidth] = useState(0)
  const {t} = useTranslation()

  useEffect(() => {
    if (isActive) {
      document.body.classList.add('hidden')
      document.querySelector('#containerWrapper').classList.add('hidden')
      document.body.style.paddingRight = scrollWidth + 'px'
    } else {
      document.body.classList.remove('hidden')
      document.querySelector('#containerWrapper').classList.remove('hidden')

      document.body.style.paddingRight = 0
    }
  }, [isActive])

  useEffect(() => {
    // узнаём ширину скроллбара в браузере
    let div = document.createElement('div')

    div.style.overflowY = 'scroll'
    div.style.width = '50px'
    div.style.height = '50px'

    document.body.append(div)

    setScrollWidth(div.offsetWidth - div.clientWidth)

    div.remove()
  }, [])

  return (
    <>
      <div
        className={`${s.background} ${isActive && s.active}`}
        onClick={() => setIsActive(false)}
      ></div>
      <div
        className={`${s.mobileMenu} ${isActive ? s.active : ''}  ${
          isHold ? s.hold : ''
        } extra-mobile-menu`}
        onClick={() => setIsActive(false)}
      >
        <button
          className={`${s.hamburgerLines} ${isActive ? s.active : ''}`}
          onClick={() => setIsActive(!isActive)}
        >
          <span className={`${s.line} ${s.line1}`}></span>
          <span className={`${s.line} ${s.line2}`}></span>
          <span className={`${s.line} ${s.line3}`}></span>
        </button>
        <nav className={`${s.mobileWrapper} ${isActive ? s.active : ''}`}>
          <ul>
            <li className={`${s.defaultLink} ${s.mobileLink}`}>
              <Link to="/">{t('Меню Главная')}</Link>
            </li>
            <li className={`${s.defaultLink} ${s.mobileLink}`}>
              <Link to="/products">{t('Меню каталог')}</Link>
            </li>
            <p className={`${s.defaultLink} ${s.mobileLink}`}>
              <Link to="/digital-catalog">{t('Электронный каталог')}</Link>
            </p>
            <li className={`${s.defaultLink} ${s.mobileLink}`}>
              <Link to="/production">{t('Меню производство')}</Link>

              <div className={s.dropdown}>
                <p className={s.dropdownItem}>
                  <Link to="/qualitypolicy">{t('Менеджмент качества')}</Link>
                </p>
                <p className={s.dropdownItem}>
                  <Link to="/testing">{t('Центральная заводская лаборатория')}</Link>
                </p>
                <p className={s.dropdownItem}>
                  <Link to="/innovative">{t('Инновационная продукция')}</Link>
                </p>
              </div>
            </li>
            <li>
              <div className={s.menuItem}>
                <p id="dropdown-basic" className={s.mobileLink}>
                  {t('Меню О компании')}
                </p>
                <div className={s.dropdown}>
                  <p className={s.dropdownItem}>
                    <Link to="/history">{t('Меню История')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/management">{t('Меню Руководство')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/smi-about-us">{t('СМИ о нас')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/photogallery">{t('Меню Фотогалерея')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/clients">{t('Наши клиенты')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/awards">{t('Наши достижения')}</Link>
                  </p>
                  {/*<p className={s.dropdownItem}>
                    <Link to="/calc">{t('Калькулятор кабеля, база для AUTOCAD')}</Link>
                  </p>*/}
              </div>
              </div>
            </li>
            <li className={`${s.defaultLink} ${s.mobileLink}`}>
              <Link to="/contacts">{t('Меню Контакты')}</Link>
            </li>
            <li className={`${s.defaultLink} ${s.mobileLink}`}>
              <Link to="/presscenter">{t('Меню Новости')}</Link>
            </li>
            <li>
              <div className={s.menuItem}>
                <p id="dropdown-basic" className={s.mobileLink}>
                  {t('Меню Общее')}
                </p>
                <div className={s.dropdown}>
                  <p className={s.dropdownItem}>
                    <Link to="/import">{t('Предложения по импортозамещению')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/vacancies">{t('Меню Вакансии')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/schedule">{t('График личного приема граждан меню')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/against-corruption">{t('Противодействие коррупции')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/administration-procedures">{t('Административные процедуры')}</Link>
                  </p>
                  <p className={s.dropdownItem}>
                    <Link to="/for-investors">{t('Меню Акционерам')}</Link>
                  </p>
                </div>
              </div>
            </li>
            <li className={`${s.defaultLink} ${s.mobileLink}`}>
              <Link to="/dillers">{t('Меню Дилеры')}</Link>
            </li>
            <li className={`${s.defaultLink} ${s.mobileLink}`}>
              <Link to="/cybersecurity">{t('Меню Кибербезопасность')}</Link>
            </li>            
          </ul>
          <div className={`${s.flexMobile}`}>
            <div className={`${s.flex} ${s.changed} ${s.first}`}>
              <div>
                <img src={pin} alt="Telephone" />
              </div>
              <div>
                <p className={s.phone}>{t('г. Мозырь, ул. Октябрьская, 14.')}</p>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
export default DesktopBurger
