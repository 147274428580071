export const config = [
  {
    id: 1,
    title: "Устав открытого акционерного общества «Беларускабель»",
    link: "/shareholders/УСТАВ 2022.pdf",
    
  },
  {
    id: 2,
    title: "Регламент работы общего собрания акционеров ОАО «Беларускабель»",
    link: "/shareholders/Регламент работы общего собрания акционеров.pdf",
  },
  {
    id: 3,
    title: "Положение о наблюдательном совете ОАО «Беларускабель»",
    link:
      "/shareholders/Положение о наблюдательном совете ОАО Беларускабель.pdf",
  },
  {
    id: 4,
    title:
      "Положение о секретаре наблюдательного совета совета ОАО «Беларускабель»",
    link:
      "/shareholders/Положение о секретаре наблюдательного совета ОАО Беларускабель.pdf",
  },
  {
    id: 5,
    title: "Положение о ревизионной комиссии ОАО «Беларускабель»",
    link:
      "/shareholders/Положение о ревизионной комиссии ОАО Беларускабель.pdf",
  },
  {
    id: 6,
    title: "Положение об аффилированных лицах ОАО «Беларускабель»",
    link:
      "/shareholders/Положение о порядке учета аффилированных лиц ОАО Беларускабель.pdf",
  },
  {
    id: 7,
    title: "Корпоративный кодекс ОАО «Беларускабель»",
    link: "/shareholders/Корпоративный кодекс ОАО Беларускабель.pdf",
  },
  {
    id: 8,
    title: "Положение о дивидендной политике ОАО «Беларускабель»",
    link:
      "/shareholders/Положение о дивидендной политике ОАО Беларускабель.pdf",
  },
  {
    id: 9,
    title:
      "Положение о порядке предоставления акционерам информации и документов акционерного общества",
    link:
      "/shareholders/Положение о порядке предоставления акционерам информации.pdf",
  },
  {
    id: 10,
    title:
      "Регламент работы ОАО «Беларускабель» с реестром владельцев ценных бумаг",
    link:
      "/shareholders/Регламент работы ОАО Беларускабель с реестром владельцев ценных бумаг.pdf",
  },
];
