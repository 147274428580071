import styled from "styled-components";
import { Link } from "react-router-dom";

export const Layout = styled.div`
  font-family: "Raleway";
  margin: 6px 0;
  display: flex;
  width: 100%;

  .faq-active-bar {
    opacity: ${(props) => (props.isOpen ? "1" : "0")};
  }

  .faq-arrow-icon {
    transform: ${(props) => (props.isOpen ? "rotate(90deg)" : "rotate(0)")};
    transition: all 0.5s ease;
  }

  .faq-question {
    color: ${(props) => (props.isOpen ? "#e74137" : "initial")};
    width: 100%;

    &:hover {
      color: #e74137;
    }
  }

  ul {
    padding-left: 24px;
  }

  font-size: 18px;
`;
export const ArrowIcon = styled.img`
  width: 32px;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

export const ActiveBar = styled.div`
  opacity: 0;
  display: none;
  width: 8px;
  background: #e74137;
  margin-right: 6px;
`;

export const FaqQuestion = styled.div`
  font-size: 28px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  &::before {
    content: "";
    display: block;
    background: blue;
    width: 4px;
    padding-right: 12px;
  }
`;

export const FaqAnswer = styled.li`
  padding: 16px 0 16px 16px;
`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;

  &:hover {
    color: red;
  }
`;
