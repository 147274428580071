import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import PaginationModule from "../../ui/pagination/Pagination";
import s from "./News.module.scss";
import scrollIntoView from "scroll-into-view";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const NewsPage = () => {
  const { t } = useTranslation();

  const smi = useSelector((store) => store.smi);

  // нумерация по страницам

  // const links = [
  //   {href: '/1', text: '1'},
  //   {href: '/2', text: '2'},
  //   {href: '/3', text: '3'},
  //   {href: '/4', text: '4'},
  //   {href: '/5', text: '5'},
  //   {href: '/6', text: '6'},
  //   {href: '/7', text: '7'},
  // ]

  // const [activePage, setActivePage] = useState(0)
  // const [activeLinks, setActiveLinks] = useState([])

  // const computeActiveLinks = page => {
  //   if (page <= 2) {
  //     setActiveLinks([...links.filter((link, idx) => idx < 4)])
  //   } else if (page >= links.length - 3) {
  //     setActiveLinks([...links.filter((link, idx) => idx > links.length - 5)])
  //   } else {
  //     setActiveLinks([...links.filter((link, idx) => idx >= page - 1 && idx <= page + 2)])
  //   }
  // }

  useEffect(() => {
    scrollIntoView(document.body.querySelector("#root"), {
      time: 0,
      align: {
        top: 0.1,
      },
    });

    // computeActiveLinks(0)
  }, []);

  return (
    <>
      <div className={s.container}>
        <Breadcrumb className="custom-breadcrumb" style={{ marginTop: "38px" }}>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">{t("Меню Главная")}</Link>
          </span>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">{t("Меню Компания")}</Link>
          </span>
          <Breadcrumb.Item active>{t("СМИ о нас")}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t("СМИ о нас")}</div>
        <div className={s.news}>
          {smi.map((item) => (
            <div className={s.newsItem}>
              <p className={s.time}>{item.date}</p>
              <p className={s.title}>
                <Link to={"/smi-about-us/" + item.id}>{item.title}</Link>
              </p>
              <p className={s.paragraph}>{item.preParagraph}</p>
              <Link to={"/smi-about-us/" + item.id}>
                <span className={s.link}>Подробнее</span>
              </Link>
            </div>
          ))}
        </div>
        {/* <PaginationModule
          activePage={activePage}
          links={links}
          activeLinks={activeLinks}
          setActivePage={setActivePage}
          computeActiveLinks={computeActiveLinks}
        /> */}
      </div>
    </>
  );
};

export default NewsPage;
