import PersonalInformation from "./PersonalInformation";
import ShareholderData from "./ShareholderData";
import CorporateGovernance from "./CorporateGovernance";
import LocalLegalActs from "./LocalLegalActs";
import ShareholdersNote from "./ShareholdersNote";
import Dividends from "./Dividends";
import ActivitiesResults from "./ActivitiesResults";

export const config = [  
  {
    id: 1,
    question: "Согласие на обработку персональных данных",
    answer: <PersonalInformation />,
  }, 
  {
    id: 2,
    question: "Об актуализации данных акционеров ОАО Беларускабель",
    answer: <ShareholderData />,
  },     
  {
    id: 3,
    question: "Корпоративное управление",
    answer: <CorporateGovernance />,
  },
  {
    id: 4,
    question: "Локальные правовые акты",
    answer: <LocalLegalActs />,
  },
  {
    id: 5,
    question: "К сведению акционеров",
    answer: <ShareholdersNote />,
  },
  {
    id: 6,
    question: "Дивиденды",
    answer: <Dividends />,
  },
  {
    id: 7,
    question: "Результаты финансово-хозяйственной деятельности",
    answer: <ActivitiesResults />,
  },
];
