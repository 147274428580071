import React, {useState} from 'react'
import {useEffect} from 'react'
import s from './Select.module.scss'

const Select = ({
  activeValue,
  setActiveValue,
  values,
  placeholder,
  isSelectOpen,
  setIsSelectOpen,
  id,
  setActiveDeleteElement,
  editMode,
  industry,
}) => {
  const [activeFilter, setActiveFilter] = useState([0, ''])

  useEffect(() => {
    if (setActiveDeleteElement) {
      if (!activeValue) setActiveDeleteElement(activeFilter[0])

      if (industry) setActiveDeleteElement(activeFilter[1])
    }
  }, [activeFilter[0]])

  useEffect(() => {
    setActiveFilter([0, ''])
  }, [values])

  useEffect(() => {
    let info = ''

    if (editMode) {
      values.forEach(val => {
        if (val.id === activeValue) {
          info = val.name
        }
      })
    }

    setActiveFilter([0, info])
  }, [])

  return (
    <div
      className={s.wrapper}
      onClick={() => {
        if (isSelectOpen !== id) {
          setIsSelectOpen(id)
        } else {
          setIsSelectOpen(false)
        }
      }}
    >
      <input
        type="text"
        value={activeFilter[1] || activeValue || ''}
        onChange={e => {
          setActiveFilter([activeFilter[0], e.target.value])

          setActiveValue('')
        }}
        className={`${s.last} ${s.input}`}
        placeholder={placeholder}
      />
      <div className={`${s.arrow} ${isSelectOpen === id ? s.active : ''}`}>
        <svg
          width="14"
          height="6"
          viewBox="0 0 14 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L7 5L13 1"
            stroke="#D9D9D9"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {isSelectOpen === id ? (
        <div className={s.select}>
          {activeFilter[1] ? (
            <>
              {values
                .filter(val => val.name.toLowerCase().includes(activeFilter[1].toLowerCase()))
                .map(value => (
                  <div
                    key={value.id}
                    className={s.selectItem}
                    onClick={() => {
                      setActiveFilter([
                        value.id,
                        value.name.split('')[0].toUpperCase() +
                          value.name.split('').slice(1, value.name.split('').length).join(''),
                      ])

                      setIsSelectOpen(0)
                    }}
                  >
                    {`${value.name.split('')[0].toUpperCase()}${value.name
                      .split('')
                      .slice(1, value.name.split('').length)
                      .join('')}`}
                  </div>
                ))}
            </>
          ) : (
            <>
              {values &&
                values.map(value => (
                  <div
                    key={value.id}
                    className={s.selectItem}
                    onClick={() => {
                      setActiveFilter([
                        value.id,
                        value.name.split('')[0].toUpperCase() +
                          value.name.split('').slice(1, value.name.split('').length).join(''),
                      ])
                      setIsSelectOpen(0)
                    }}
                  >
                    {`${value.name.split('')[0].toUpperCase()}${value.name
                      .split('')
                      .slice(1, value.name.split('').length)
                      .join('')}`}
                  </div>
                ))}
            </>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Select
