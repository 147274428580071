export const config = {
    title: 'Электронный каталог',
    catalogs: [
        {
            id: 1,
            image: {
                src: '/catalogs/pdf-1.png',
                width: '80px',
                height: '80px',
            },
            link: '/catalogs/catalog-aviation-defense.pdf',
            title: 'Каталог авиационная и оборонная промышленность 2023',
        },
        {
            id: 2,
            image: {
                src: '/catalogs/pdf-1.png',
                width: '80px',
                height: '80px',
            },
            link: '/catalogs/booklet-elevator-cable.pdf',
            title: 'Буклет лифтовой кабель в круглом и плоском исполнении 2023',
        },
        {
            id: 3,
            image: {
                src: '/catalogs/pdf-1.png',
                width: '80px',
                height: '80px',
            },
            link: '/catalogs/catalog-plags-cords.pdf',
            title: 'Перечень удлинители, вилки, шнуры 2023',
        },
        {
            id: 4,
            image: {
                src: '/catalogs/pdf-1.png',
                width: '80px',
                height: '80px',
            },
            link: '/catalogs/catalog-in-russian.pdf',
            title: 'Перечень продукции на русском языке 2023',
        },
        {
            id: 5,
            image: {
                src: '/catalogs/pdf-1.png',
                width: '80px',
                height: '80px',
            },
            link: '/catalogs/catalog-in-english.pdf',
            title: 'Catalog in English 2023',
        },
        {
            id: 6,
            image: {
                src: '/catalogs/pdf-1.png',
                width: '80px',
                height: '80px',
            },
            link: '/catalogs/kcpa-2024.pdf',
            title: 'Каталог КСПА 2024',
        },        
    ]
}
