import {Card} from "antd";
import MediaManagement from "./MediaManagement.jsx";

const MediaManagementPage = () => {
    return (
        <Card title="Управление медиа-файлами">
            <MediaManagement isFullPage />
        </Card>
    );
};

export default MediaManagementPage;
