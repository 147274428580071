export const work = {
  ru: {
    translation: {
      "наладчик контрольно-измерительных приборов и автоматики 5 разряда": `<p className={s.vacancy} style="margin-top: 24px" >
      <u>В связи с увеличением объемов производства ОАО «Беларускабель» приглашает на постоянную работу:</u>
          <ul style="padding: 0">          
            <li>- инженера-энергетика;</li>
            <li>- электромонтера по ремонту и обслуживанию электрооборудования 4-5 разряда;</li>
            <li>- инженера центральной заводской лаборатории (физические испытания);</li>
            <li>- опрессовщика кабелей и проводов пластикатами и резиной (мужчина, возможно обучение на производстве);</li>
            <li>- тракториста;</li>
            <li>- скрутчика изделий кабельного производства (мужчина, возможно обучение на производстве);</li>
            <li>- оплетчика проводов и кабелей (женщина, возможно обучение на производстве);</li>
            <li>- контролера кабельных изделий (мужчина, возможно обучение на производстве);</li>
            <li>- испытателя проводов и кабелей (женщина, возможно обучение на производстве);</li>
            <li>- инженера по автоматизированным системам управления производством;</li>
            <li>- электрогазосварщика 5 разряда;</li>
            <li>- мастера в ремонтно-механический участок</li>
          </ul>
          </p>

          <div>
            <p>Вакансии размещены на портале ГСЗ.</p>
            <b>Обращаться по адресу:</b>
            <p>г. Мозырь, ул. Октябрьская, 14.</p>
            <b>Контактные телефоны:</b>
            <p>тел. 372566, 240803, 8(029)3702405, 8(029)1108162</p>
          </div>
          <div>
            <b>Работа в ОАО "БЕЛАРУСКАБЕЛЬ" это:</b>
            <p style="width: 100%; max-width: 670px;">хорошие условия труда, полный соцпакет, возможность обучения и карьерного роста, стабильная заработная плата и все социальные гарантии в соответствии с ТК РБ.<br/> 
            Работа в дружном коллективе. Наличие соц. сферы.</p>
          </div>`,
    },
  },
  en: {
    translation: {
      "наладчик контрольно-измерительных приборов и автоматики 5 разряда": `<p className={s.vacancy} style="margin-top: 24px" >
      <u>В связи с увеличением объемов производства ОАО «Беларускабель» приглашает на постоянную работу:</u>
          <ul style="padding: 0">          
            <li>- инженера-энергетика;</li>
            <li>- электромонтера по ремонту и обслуживанию электрооборудования 4-5 разряда;</li>
            <li>- инженера центральной заводской лаборатории (физические испытания);</li>
            <li>- опрессовщика кабелей и проводов пластикатами и резиной (мужчина, возможно обучение на производстве);</li>
            <li>- тракториста;</li>
            <li>- скрутчика изделий кабельного производства (мужчина, возможно обучение на производстве);</li>
            <li>- оплетчика проводов и кабелей (женщина, возможно обучение на производстве);</li>
            <li>- контролера кабельных изделий (мужчина, возможно обучение на производстве);</li>
            <li>- испытателя проводов и кабелей (женщина, возможно обучение на производстве);</li>
            <li>- инженера по автоматизированным системам управления производством;</li>
            <li>- электрогазосварщика 5 разряда;</li>
            <li>- мастера в ремонтно-механический участок</li>
          </ul>
          </p>

          <div>
            <p>Вакансии размещены на портале ГСЗ.</p>
            <b>Обращаться по адресу:</b>
            <p>г. Мозырь, ул. Октябрьская, 14.</p>
            <b>Контактные телефоны:</b>
            <p>тел. 372566, 240803, 8(029)3702405, 8(029)1108162</p>
          </div>
          <div>
            <b>Работа в ОАО "БЕЛАРУСКАБЕЛЬ" это:</b>
            <p style="width: 100%; max-width: 670px;">хорошие условия труда, полный соцпакет, возможность обучения и карьерного роста, стабильная заработная плата и все социальные гарантии в соответствии с ТК РБ.<br/> 
            Работа в дружном коллективе. Наличие соц. сферы.</p>
          </div>`,
    },
  },
  bel: {
    translation: {
      "наладчик контрольно-измерительных приборов и автоматики 5 разряда": `<p className={s.vacancy} style="margin-top: 24px" >
      <u>В связи с увеличением объемов производства ОАО «Беларускабель» приглашает на постоянную работу:</u>
          <ul style="padding: 0">          
            <li>- инженера-энергетика;</li>
            <li>- электромонтера по ремонту и обслуживанию электрооборудования 4-5 разряда;</li>
            <li>- инженера центральной заводской лаборатории (физические испытания);</li>
            <li>- опрессовщика кабелей и проводов пластикатами и резиной (мужчина, возможно обучение на производстве);</li>
            <li>- тракториста;</li>
            <li>- скрутчика изделий кабельного производства (мужчина, возможно обучение на производстве);</li>
            <li>- оплетчика проводов и кабелей (женщина, возможно обучение на производстве);</li>
            <li>- контролера кабельных изделий (мужчина, возможно обучение на производстве);</li>
            <li>- испытателя проводов и кабелей (женщина, возможно обучение на производстве);</li>
            <li>- инженера по автоматизированным системам управления производством;</li>
            <li>- электрогазосварщика 5 разряда;</li>
            <li>- мастера в ремонтно-механический участок</li>
          </ul>
          </p>

          <div>
            <p>Вакансии размещены на портале ГСЗ.</p>
            <b>Обращаться по адресу:</b>
            <p>г. Мозырь, ул. Октябрьская, 14.</p>
            <b>Контактные телефоны:</b>
            <p>тел. 372566, 240803, 8(029)3702405, 8(029)1108162</p>
          </div>
          <div>
            <b>Работа в ОАО "БЕЛАРУСКАБЕЛЬ" это:</b>
            <p style="width: 100%; max-width: 670px;">хорошие условия труда, полный соцпакет, возможность обучения и карьерного роста, стабильная заработная плата и все социальные гарантии в соответствии с ТК РБ.<br/> 
            Работа в дружном коллективе. Наличие соц. сферы.</p>
          </div>`,
    },
  },
};
