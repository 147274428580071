import React, {useState} from 'react'
import {useEffect} from 'react'
import Filters from '../Filters/Filters'
import MainTopic from '../MainTopic/MainTopic'
import s from './Topic.module.scss'
import {useSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {
  loadIndustryProducts,
  loadCategoriesProducts,
  loadExactCategory,
  loadExactIndustry,
} from '../../../store/async/asyncCategories'
import scrollIntoView from 'scroll-into-view'

const Content = ({setActiveIndustry, activeIndustry, industries, setBreadCrumb}) => {
  const {data, nameOfActiveCategory, nameOfActiveIndustry} = useSelector(store => store.categories)
  const {language} = useSelector(store => store.language)
  const dispatch = useDispatch()
  const [activeCategory, setActiveCategory] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [activePage, setActivePage] = useState(1)

  useEffect(() => {

    if (!activeCategory) return

    if (activeCategory[0] !== undefined) {
      setActiveIndustry()

      let isPage = searchParams.get('page') ? {page: searchParams.get('page')} : {}

      if (activeCategory[0]) {
        setSearchParams({category: activeCategory[0], ...isPage})
        dispatch(loadCategoriesProducts({id: activeCategory[0], lang: language}))
      }

      setBreadCrumb([
        {
          id: 1,
          title: 'Главная',
          href: '/shop',
          show: true,
        },
        {
          id: 2,
          title: 'Каталог',
          href: '/shop',
          show: true,
        },
        {
          id: 3,
          title: `Кабель ${activeCategory[1] ? activeCategory[1].toLowerCase() : ''}`,
          href: '/shop',
          show: activeCategory[1] ? true : false,
        },
      ])

      scrollIntoView(document.body.querySelector('.goods'), {
        time: 0,
        align: {
          top: 0.1,
        },
      })
    }
  }, [activeCategory])

  useEffect(() => {

    if (!activeIndustry) return
    if (activeIndustry[0]) {
      setActiveCategory()

      let isPage = searchParams.get('page') ? {page: searchParams.get('page')} : {}

      if (activeIndustry[0]) {
        setSearchParams({industry: activeIndustry[0], ...isPage})
        dispatch(loadIndustryProducts({id: activeIndustry[0], lang: language}))
      }

      setBreadCrumb([
        {
          id: 1,
          title: 'Главная',
          href: '/shop',
          show: true,
        },
        {
          id: 2,
          title: 'Каталог',
          href: '/shop',
          show: true,
        },
        {
          id: 3,
          title: `Кабель ${activeIndustry[1] ? activeIndustry[1].toLowerCase() : ''}`,
          href: '/shop',
          show: activeIndustry[1] ? true : false,
        },
      ])

      scrollIntoView(document.body.querySelector('.goods'), {
        time: 0,
        align: {
          top: 0.1,
        },
      })
    }
  }, [activeIndustry])

  useEffect(() => {
    if (activeIndustry[0]) dispatch(loadIndustryProducts({id: activeIndustry[0], lang: language}))
    else if (activeCategory[0])
      dispatch(loadCategoriesProducts({id: activeCategory[0], lang: language}))
  }, [language])

  // проверяем, есть ли в начальной ссылке информация об активной категории
  useEffect(() => {
    if (searchParams.get('industry')) {
      dispatch(loadExactIndustry({id: searchParams.get('industry'), lang: language}))
      setActiveIndustry([searchParams.get('industry'), nameOfActiveIndustry])
      return
    } else if (searchParams.get('category')) {
      dispatch(loadExactCategory({id: searchParams.get('category'), lang: language}))
      setActiveCategory([searchParams.get('category'), nameOfActiveCategory])
      return
    } else {
       setActiveCategory([false, false])
    }
  }, [])

  useEffect(() => {
    if (nameOfActiveCategory) setActiveCategory([activeCategory[0], nameOfActiveCategory])
  }, [nameOfActiveCategory])

  useEffect(() => {
    if (nameOfActiveIndustry) setActiveIndustry([activeIndustry[0], nameOfActiveIndustry])
  }, [nameOfActiveIndustry])

  return (
    <div className={`${s.container} goods`}>
      <div className={s.flex}>
        <div className={`${s.flexItem} ${s.filtersWrapper}`}>
          <Filters
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            activeIndustry={activeIndustry}
            setActiveIndustry={setActiveIndustry}
            industries={industries}
            categories={data}
          />
        </div>
        <div className={s.flexItem}>
          <MainTopic
            activeCategory={activeCategory}
            activeIndustry={activeIndustry}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </div>
      </div>
    </div>
  )
}

export default Content
