import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  language: localStorage.getItem('lng') || 'ru',
}

export const LanguageReducer = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload
      localStorage.setItem('lng', action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const {setLanguage} = LanguageReducer.actions

export default LanguageReducer.reducer
