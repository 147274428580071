import './Management.css'
import {Row, Col, Card, Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import scrollIntoView from 'scroll-into-view'
import {useTranslation} from 'react-i18next'

export default function Management() {
  const {t} = useTranslation()
  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  const management = [
    {
      name: `${t('Шилов Виталий Петрович')}`,
      info: `${t('Директор ОАО «Беларускабель»')}`,
      phone: `+375 (236) 24-77-29`,
      email: '',
      photo: '1human.png',
    },
    {
      name: `${t('Фиранчук Дмитрий Николаевич')}`,
      info: `${t('Зам. директора по развитию')}`,
      phone: `+375 (236) 25-41-62`,
      email: '',
      photo: '2human.png',
    },
    {
      name: `${t('Камеш Владимир Александрович')}`,
      info: `${t('Зам. директора по снабжению и организации перевозок')}`,
      phone: `+375 (236) 25-41-14`,
      email: '',
      photo: '3human.png',
    },
    {
      name: `${t('Барановский Сергей Григорьевич')}`,
      info: `${t('Главный инженер')}`,
      phone: `+375 (236) 25-23-04`,
      email: '',
      photo: 'baranovski.jpg',
    },
    {
      name: `${t('Фещенко Светлана Леонидовна')}`,
      info: `${t('Заместитель директора по идеологической работе, кадрам и социальным вопросам')}`,
      phone: `+375 (236) 25-42-24`,
      email: '',
      photo: 'feshenko.jpg',
    },
    {
      name: `${t('Белько Анна Александровна')}`,
      info: `${t('Главный бухгалтер')}`,
      phone: `+375 (236) 24-65-23`,
      email: '',
      photo: 'belko.jpg',
    },
  ]
  return (
    <>
      <div className="management-page-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Меню Руководство')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Меню Руководство')}</div>
        <Row md={3} className="management-page-row">
          {management.map(human => (
            <Col className="management-page-col">
              <Card className="human">
                <Card.Img className="human-photo" variant="top" src={human.photo} alt="photo" />
                <Card.Body>
                  <Card.Title>
                    <span className="human-name">{human.name}</span>
                  </Card.Title>
                  <Card.Text className="card-modify">
                    <p>{human.info}</p>
                    <p>{human.phone}</p>
                    <p>{human.email}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  )
}
