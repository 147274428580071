import React, {createContext, useCallback, useContext, useMemo} from "react";
import {useQuery} from "@tanstack/react-query";
import {getSettings} from "../api/settings.endpoint.js";
import {WarningFilled} from "@ant-design/icons";
import {Result} from "antd";

const AppSettingsContext = createContext(null);

export const AppSettingsProvider = ({ children }) => {
    const settingsQuery = useQuery({
        queryFn: getSettings,
        queryKey: ['settings', 'all'],
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    const settings = useMemo(() => {
        if (settingsQuery.data) {
            return new Map(settingsQuery.data?.map(({ key, value }) => [key, value]));
        }
        return new Map();
    }, [settingsQuery]);

    const getSettingByKey = useCallback((key) => {
        if (settings.has(key)) {
            return settings.get(key);
        }
        return undefined;
    }, [settings]);

    if (settingsQuery.isLoading) return null;
    if (settingsQuery.isError) return (
        <Result
            status="Что-то пошло не так"
            icon={<WarningFilled style={{ color: '#faad14'}} />}
            title="Возникла ошибка при загрузке данных. Попробуйте обновить страницу"
        />
    );

    return (
        <AppSettingsContext.Provider value={{
            getSettingByKey
        }}>
            {children}
        </AppSettingsContext.Provider>
    );
};

export const useAppSettings = () => {
    const context = useContext(AppSettingsContext);
    if (!context) {
        throw new Error("useAppSettings must be used within an AppSettingProvider");
    }
    return context;
};
