import {API_URL} from "./env.js";
import axios from "axios";

const https = axios.create({
    baseURL: API_URL,
});

https.interceptors.request.use(
    (config) => {
        const jwtToken = localStorage.getItem("jwt_token");
        if (jwtToken) {
            config.headers.Authorization = `Bearer ${jwtToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

https.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            return Promise.reject({
                status: error.response.status,
                message: error.response.data?.message || "Ошибка запроса",
            });
        } else if (error.request) {
            return Promise.reject({ status: 500, message: "Сервер не отвечает" });
        } else {
            return Promise.reject({ status: 500, message: "Ошибка запроса" });
        }
    }
);

export {https}