import { BoldText } from "./styled";

export const config = [
  {
    id: 1,
    text:
      "Уважаемые акционеры ОАО «Беларускабель»!",
    isBolded: true,
  },
  {
    id: 2,
    text: "ОАО «Беларускабель» (далее - Общество) информирует Вас о том, что в соответствии с пунктом 26 Устава ОАО «Беларускабель» акционеры Общества обязаны своевременно сообщать депозитарию, формирующему реестр владельцев ценных бумаг Общества, обо всех изменениях данных о себе, включенных в реестр (ФИО, паспортные данные, место регистрации).",
  },
  {
    id: 3,
    text: "Наличие актуальных данных в реестре акционеров позволяет обеспечить реализацию Вами прав акционеров в полном объеме (участие в собрании акционеров, голосование по вопросам повестки дня, получение дивидендов и т.п.).",
  },  
  {
    id: 4,
    text: "Информацию об изменении данных акционера необходимо предоставлять в ОАО «СБЕР БАНК», осуществляющее депозитарное обслуживание ОАО «Беларускабель»:",
  },
  {
    id: 5,
    text: "ОАО «СБЕР БАНК», ДО (дополнительный офис) №324, г. Мозырь, ул. Советская, 130",
    isBolded: true,
  }, 
  {
    id: 6,
    text: "либо по адресу:",
  },   
  {
    id: 7,
    text: "ОАО «СБЕР БАНК», Бульвар им.Мулявина, 6, 220005, г.Минск.",
    isBolded: true,
  },   
  {
    id: 6,
    text:
      "По всем вопросам обращаться по телефону: ",
    subtext: [
      "+375(236)216819 (Татьяна Васильевна)",
    ],
  },

];
