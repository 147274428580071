import './FirstBanner.css'
import {useTranslation} from 'react-i18next'

export default function FirstBanner() {
  const {t} = useTranslation()

  return (
    <>
      <div className="parent">
        <div className={'banner-lbl-wrapper'}>
          <div className="bannerlbl">
            <p className="lbl">{t('ВЫСОКОЕ КАЧЕСТВО ГАРАНТИРОВАНО')}</p>
            <p className="bannerText">
              {t('Вся продукция сертифицирована и производится в соответствии с ТУ и ГОСТ')}
            </p>
          </div>
          <div className="banner1-check">
            <img src="/icon/check-s.png" className="banner1-image-ckeck" alt="banner" />
          </div>
        </div>
        <img src="high-quality-s.jpg" className="banner-image-1" alt="banner" />
      </div>
    </>
  )
}
