import "./PressCenter.css";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import scrollIntoView from "scroll-into-view";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function PressCenter() {
  const news = useSelector((store) => store.news);
  const { t } = useTranslation();

  useEffect(() => {
    scrollIntoView(document.body.querySelector("#root"), {
      time: 0,
      align: {
        top: 0.1,
      },
    });
  }, []);

  return (
    <>
      <div className="press-center-page-conteiner">
        <Breadcrumb className="custom-breadcrumb" style={{ marginTop: "38px" }}>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">{t("Меню Главная")}</Link>
          </span>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">{t("Меню Компания")}</Link>
          </span>
          <Breadcrumb.Item active>{t("Меню Новости")}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t("Меню Новости")}</div>
        <div className="press-center-page-row">
          {news.map((info) => (
            <div key={info.id} className="press-center">
              <img
                className={`press-center-photo ${info.isDefault ? 'default-photo-styles' : ''}`}
                variant="top"
                src={info.img}
                alt="Press center"
              />
              <div className="press-center-wrapper">
                <div className="press-container">
                  <p>{info.date}</p>
                  <p>
                    <span className="press-center-name">{info.title}</span>
                  </p>
                  <p className={"press-center-main-text"}>
                    <p
                      className="info-text"
                      dangerouslySetInnerHTML={{ __html: info.preParagraph }}
                    ></p>
                  </p>
                </div>
                <p>
                  <Link to={`/presscenter/${info.id}`}>
                    <span className={"press-center-button"}>
                      {t("Кнопка Читать")}
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
