import {Button, Form, Input, Space} from "antd";
import {useCallback} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {saveSettings} from "../../../../api/settings.endpoint.js";

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

export const StaticURLSettingsForm = ({ settings, notification }) => {
    const queryClient = useQueryClient();
    const [form] = Form.useForm();
    const saveSettingsMutation = useMutation({
        mutationFn: saveSettings,
        onSuccess: () => {
            notification.success({message: 'Конфигурация успешно сохранена'})
            queryClient.invalidateQueries(['settings', 'private', 'all'])
        }
    });

    const onFormSubmit = useCallback(async ({staticUrl}) => {
        await saveSettingsMutation.mutateAsync({key: "STATIC_URL", value: staticUrl })
    }, []);

    return (
        <Form form={form} name="staticURL-settings-form" {...layout} labelAlign="left" initialValues={settings}
              onFinish={onFormSubmit} disabled={saveSettingsMutation.isLoading}>
            <Form.Item
                label="URL для статичных файлов"
                name="staticUrl"
                rules={[{required: true, message: "Введите URL"}]}
            >
                <Input placeholder="https://belaruskabel.by/"/>
            </Form.Item>

            <Form.Item>
                <Space>
                    <Button>Отмена</Button>
                    <Button type="primary" htmlType="submit" loading={saveSettingsMutation.isLoading}>Сохранить</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
