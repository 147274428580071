const excelExtensions = ["xls", "xlsb", "xlsm", "xlsx"];
const pdfExtensions = ["pdf"];
const docExtensions = ["doc", "docx"];

export function getFileExtension(fileName) {
    const extension = fileName.split(".").pop();

    return extension;
}

export const filesExtensions = [
    ["excel-icon", excelExtensions],
    ["pdf-icon", pdfExtensions],
    ["doc-icon", docExtensions],
].reduce((obj, [value, keys]) => {
    for (const key of keys) {
        Object.defineProperty(obj, key, {value});
    }
    return obj;
}, {});

export const formatKB = (bytes, decimals = 2) => {
    let kb = bytes / 1000;
    if (kb < 1) return '0 KB';

    const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
    let i = 0;

    while (kb >= 1024 && i < sizes.length - 1) {
        kb /= 1024;
        i++;
    }

    return `${kb.toFixed(decimals)} ${sizes[i]}`;
}

export const formatDateTime = (date, locale = 'ru-RU', options = {}) => {
    if (!(date instanceof Date)) date = new Date(date);
    if (isNaN(date)) return 'Invalid Date';

    const defaultOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    return date.toLocaleString(locale, {...defaultOptions, ...options});
}