import React, {useEffect, useState} from 'react'
import s from './Backet.module.scss'
import file from '../../../media/main/backet/file.svg'
import bin from '../../../media/main/backet/bin.svg'
import letters from '../../../media/main/backet/letters.svg'
import {Link} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import {useDispatch, useSelector} from 'react-redux'
import {incrementQuantity, decrementQuantity, removeItem} from '../../../store/reducers/ShoppingReducer'
import {useTranslation} from 'react-i18next'

const BacketOfficial = props => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const {amount, items} = useSelector(store => store.shopping)
  const [firstLoading, setFirstLoading] = useState(true)

  let dublicateItems = []

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])


  useEffect(() => {
    setFirstLoading(false)

    if (!firstLoading) {
      localStorage.setItem('setAllItems', JSON.stringify(items))
    }

    if (items) dublicateItems = [...items]
  }, [items])

  return (
    <>
      {amount ? (
        <div className={s.container}>
          <div className={s.flex}>
            <div className={s.flexItem}>
              <p className={s.title}>{t('Шапка Корзина')}</p>

              <table className={s.table}>
                <tbody>
                  <tr>
                    <th className={s.tableHeader}>
                      <img className={s.tableImg} src={file} alt="Наименование" />
                      <span>{t('Заг Наименование')}</span>
                    </th>
                    <th className={s.tableHeader}>
                      <img className={s.tableImg} src={letters} alt="Количество" />
                      <span>{t('Заг Масса')}</span>
                    </th>
                    <th className={s.tableHeader}>
                      <img className={s.tableImg} src={letters} alt="Количество" />
                      <span>{t('Заг Количество')}</span>
                    </th>
                    <th></th>
                  </tr>
                  {items &&
                    items.map((item, idx) => (
                      <tr key={idx}>
                        <td className={s.firstCell}>
                          <div className={s.flexCard}>
                            <img
                              className={s.img}
                              src="/publicPages/provod.png"
                              alt="Иконка провода"
                            />
                            <p className={s.cardTitle}>{item.name}</p>
                          </div>
                          <p className={s.paragraph}>
                            {t('Номинальное сечение жил')} {item.coreConstruction};
                            {item.numberOfCores !== null
                              ? `${t('Число жил')} - ${item.numberOfCores}`
                              : ''}
                          </p>
                        </td>
                        <td>
                          <span className={s.amount}>
                            {item.weight !== null ? item.weight : '-'}
                          </span>
                        </td>
                        <td>
                          <span className={s.amount}>
                            <span className={s.signs} onClick={() => dispatch(decrementQuantity({id: item.id}))}>-</span>
                            <span className={s.kolvo}>{item.amount}</span>
                            <span className={s.signs} onClick={() => dispatch(incrementQuantity({id: item.id}))}>+</span>
                          </span>
                        </td>
                        <td onClick={() => removeItem(item.id)}>
                          <img src={bin} alt="Удалить элемент" />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {items &&
                items.map((item, idx) => (
                  <div key={idx} className={s.mobile}>
                    <div className={s.flexMobile}>
                      <img
                        className={s.imgMobile}
                        src="/publicPages/provod.png"
                        alt="Иконка провода"
                      />
                      <div>
                        <p className={s.cardTitle}>{item.name}</p>
                        <p className={s.paragraph}>
                          {t('Номинальное сечение жил')}
                          {item.coreConstruction};
                          {item.numberOfCores !== null
                            ? `${t('Число жил')} - ${item.numberOfCores}`
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className={s.flexWrapper}>
                      <span className={s.amount}>{item.weight !== null ? item.weight : '-'}</span>
                    </div>

                    <button className={s.mobileButton} onClick={() => removeItem(item.id)}>
                      {t('Удалить из корзины')}
                    </button>
                  </div>
                ))}
            </div>
            <div className={s.flexItem}>
              <div className={s.itogo}>
                <span className={s.title}>{t('Цена по запросу')}</span>
                <span className={s.title}></span>
              </div>
              <Link to="/order" className={`${s.link} ${s.first}`}>
                {t('Оформить заказ')}
              </Link>
              <Link className={s.link} to="/products">
                {t('Продолжить покупки')}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className={s.container}>
          <div className={s.paddings}>
            <p className={s.title}>{t('На данный момент ваша корзина пуста.')}</p>
            <p>{t('В каталоге вы можете найти интересующие вас')}</p>

            <p className={s.linkWrapper}>
              <Link className={s.link} to="/products">
                {t('Перейти в каталог')}
              </Link>
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default BacketOfficial
