import { configureStore } from "@reduxjs/toolkit";
import AdminReducer from "./reducers/AdminReducer";
import AuthorizeReducer from "./reducers/AuthorizeReducer";
import CabelSearchReducer from "./reducers/cabelSearchReducer";
import CategoriesReducer from "./reducers/CategoriesReducer";
import LanguageReducer from "./reducers/LanguageReducer";
import NewsReducer from "./reducers/NewsReducer";
import SmiReducer from "./reducers/SmiReducer";
import PhotoReducer from "./reducers/PhotoReducer";
import ProductsReducer from "./reducers/ProductsReducer";
import PublicCatalog from "./reducers/PublicCatalog";
import { ShoppingReducer } from "./reducers/ShoppingReducer";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, ShoppingReducer);

export const store = configureStore({
  reducer: {
    persistedReducer,
    photo: PhotoReducer,
    news: NewsReducer,
    smi: SmiReducer,
    auth: AuthorizeReducer,
    categories: CategoriesReducer,
    products: ProductsReducer,
    admin: AdminReducer,
    language: LanguageReducer,
    publicCatalog: PublicCatalog,
    search: CabelSearchReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }),
});

export const persistor = persistStore(store);
