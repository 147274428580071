export const generatePathLocation = (link, setSearchParams, searchParams) => {
  let obj = {}

  if (searchParams.get('categoryId')) {
    obj.categoryId = searchParams.get('categoryId')
  }

  if (searchParams.get('subcategoryId')) {
    obj.subcategoryId = searchParams.get('subcategoryId')
  }

  if (searchParams.get('industriesId')) {
    obj.industriesId = searchParams.get('industriesId')
  }

  obj.page = link

  setSearchParams(obj)
}
