import {https} from "../../../config/index.js";

export const getAllCategories = async () => {
    try {
        const response = await https.get('v2/categories');
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
};

export const createCategory = async (payload) => {
    try {
        const response = await https.post('v2/categories', payload);
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
};

export const updateCategory = async (payload) => {
    try {
        const response = await https.put(`v2/categories/${payload.id}`, payload);
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
}

export const removeCategory = async (categoryId) => {
    try {
        const response = await https.delete(`v2/categories/${categoryId}`);
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
}

export const getAllSubCategories = async () => {
    try {
        const response = await https.get('v2/sub-categories');
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
};

export const createSubCategory = async (payload) => {
    try {
        const response = await https.post('v2/sub-categories', payload);
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
}

export const updateSubCategory = async (payload) => {
    try {
        const response = await https.put(`v2/sub-categories/${payload.id}`, payload);
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
}

export const removeSubCategory = async (id) => {
    try {
        const response = await https.delete(`v2/sub-categories/${id}`);
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
}

export const addSubCategoryToCategory = async (categoryId, subCategoryId) => {
    try {
        const response = await https.post(`v2/categories/${categoryId}/sub-category/${subCategoryId}`);
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
};

export const removeSubCategoryFromCategory = async (categoryId, subCategoryId) => {
    try {
        const response = await https.delete(`v2/categories/${categoryId}/sub-category/${subCategoryId}`);
        return response.data || [];
    } catch (e) {
        console.error(e);
    }
};