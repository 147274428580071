import { createSlice } from "@reduxjs/toolkit";
import { setIndustries } from "../async/asyncAdmin";
import {
  getCategories,
  getIndustries,
  getSubCategories,
  loadCategoriesProducts,
  loadExactCategory,
  loadExactIndustry,
  loadIndustryProducts,
  loadMainProduct,
  loadSubCategoriesProducts,
} from "../async/asyncCategories";

const initialState = {
  isLoading: true,
  data: [],
  subCategories: [],
  showProducts: [],
  industries: [],
  activeCategoryId: "",
  activeSubCategoryId: "",
  activeIndustryId: "",
  activeIndustryName: "",
  activeCategoryName: "Все категории",
  activeSubCategoryName: "",
  lastIdCat: "",
  activeLang: "",
  nameOfActiveCategory: "",
  nameOfActiveIndustry: "",
};

export const CategoriesReducer = createSlice({
  name: "categories",
  initialState,
  reducers: {
    removeCategoryLocal: (state, action) => {
      state.data = state.data.filter((d) => d.id !== action.payload);
    },
    changeLastIdCat: (state, action) => {
      state.lastIdCat = action.payload;
    },
    changeActiveLang: (state, action) => {
      state.activeLang = action.payload;
    },
    setProducts(state, info) {
      state.showProducts = info.payload;
    },
  },
  extraReducers: {
    [getCategories.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getCategories.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data.data;
    },
    [getCategories.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [getSubCategories.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getSubCategories.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.subCategories = action.payload.data.data;
      }
    },
    [getSubCategories.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [getIndustries.pending]: (state) => {
      state.isLoading = true;
    },
    [getIndustries.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.industries = action.payload.data.data;
    },
    [getIndustries.rejected]: (state) => {
      state.isLoading = false;
    },
    [loadSubCategoriesProducts.pending]: (state) => {
      state.isLoading = true;
    },
    [loadSubCategoriesProducts.fulfilled]: (state, action) => {
      state.isLoading = false;

      if (action.payload) {
        state.showProducts = action.payload.products;
        state.activeSubCategoryId = action.payload.id;
        state.activeSubCategoryName = action.payload.name;
      }
    },
    [loadSubCategoriesProducts.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [loadCategoriesProducts.pending]: (state) => {
      state.isLoading = true;
    },
    [loadCategoriesProducts.fulfilled]: (state, action) => {
      state.isLoading = false;

      if (action.payload) {
        state.showProducts = action.payload.products;
        state.activeCategoryId = action.payload.id;
        state.activeCategoryName = action.payload.name;
      }
    },
    [loadCategoriesProducts.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [loadIndustryProducts.pending]: (state, action) => {
      state.isLoading = true;
    },
    [loadIndustryProducts.fulfilled]: (state, action) => {
      state.isLoading = false;

      if (action.payload) {
        state.showProducts = action.payload.products;
      }
    },
    [loadIndustryProducts.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [setIndustries.pending]: (state) => {
      state.isLoading = true;
    },
    [setIndustries.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [setIndustries.rejected]: (state) => {
      state.isLoading = false;
    },
    [loadMainProduct.pending]: (state) => {
      state.isLoading = true;
    },
    [loadMainProduct.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [loadMainProduct.rejected]: (state) => {
      state.isLoading = false;
    },
    [loadExactCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [loadExactCategory.fulfilled]: (state, action) => {
      console.log(action.payload);
      if (undefined !== action.payload) {
        state.isLoading = false;
        state.nameOfActiveIndustry = "";
        state.nameOfActiveCategory = action.payload.name;
      }
    },
    [loadExactCategory.rejected]: (state) => {
      state.isLoading = false;
    },

    [loadExactIndustry.pending]: (state) => {
      state.isLoading = true;
    },
    [loadExactIndustry.fulfilled]: (state, action) => {
      console.log(action.payload);
      if (undefined !== action.payload) {
        state.isLoading = false;
        state.nameOfActiveCategory = "";
        state.nameOfActiveIndustry = action.payload.name;
      }
    },
    [loadExactIndustry.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  removeCategoryLocal,
  changeLastIdCat,
  changeActiveLang,
  setProducts,
} = CategoriesReducer.actions;

export default CategoriesReducer.reducer;
