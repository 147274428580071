import {useState} from 'react'
import Tabs from './Tabs/Tabs'
import Category from './Category/Category'
import s from './Admin.module.scss'
import img1 from './img/img1.svg'
import img11 from './img/img11.svg'
import img0 from './img/img0.svg'
import img00 from './img/img00.svg'
import img2 from './img/img2.svg'
import img22 from './img/img22.svg'
import img3 from './img/img3.svg'
import img33 from './img/img33.svg'
import Button from './ui/Button/Button'
import Goods from './Goods/Goods'
import News from './News/News'
import {useDispatch, useSelector} from 'react-redux'
import LogInForm from './LogIn/LogIn'
import {logInAdmin} from '../../store/async/asyncAdmin'
import EditGood from './EditGood/Goods'
import {ProductManagement} from "./ProductManagement/ProductManagement.jsx";
import {CategoryManagement} from "./CategoryManagement/CategoryManagement.jsx";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import SettingsManagement from "./SettingsManagement";
import {AuthProvider} from "../../context/AuthContext.jsx";
import MediaManagementPage from "./MediaManagement/MediaManagementPage.jsx";

const queryClient = new QueryClient()

export default function Admin() {
  const [activeTab, setActiveTab] = useState(1)
  const [part, setPart] = useState(0)
  const {isAuthenticated, jwt_token, isLoading} = useSelector(store => store.admin)
  const dispatch = useDispatch()

  const categories = [
    {
      id: 1,
      title: 'Категории',
      img: img0,
      activeImg: img00,
    },
    {
      id: 2,
      title: 'Товары',
      img: img1,
      activeImg: img11,
    },
    {
      id: 3,
      title: 'Новости',
      img: img2,
      activeImg: img22,
    },
    {
      id: 4,
      title: 'Редактировать товар',
      img: img3,
      activeImg: img33,
    },
    {
      id: 5,
      title: 'Управление товарами',
      img: img1,
      activeImg: img11,
    },
    {
      id: 6,
      title: 'Управление категориями',
      img: img1,
      activeImg: img11,
    },
    {
      id: 7,
      title: 'Управление медиа',
      img: img1,
      activeImg: img11,
    },
    {
      id: 8,
      title: 'Настройки',
      img: img1,
      activeImg: img11,
    },
  ]

  const logIn = ({email, password}) => {
    dispatch(logInAdmin({email, password}))
  }

  return (
      <>
        <>
          {isAuthenticated ? (
              <QueryClientProvider client={queryClient}>
                <AuthProvider>
                  <div className={s.body}>
                    <div className={s.container}>
                      <div className={s.flexes}>
                    <span onClick={() => setPart(0)}>
                      <Button text="Сайт" active={!part} small />
                    </span>
                        <span onClick={() => setPart(1)} className={s.margin}>
                      <Button text="Интернет-магазин" active={part} small />
                    </span>
                      </div>
                      <div className={s.flex}>
                        <Tabs setActiveTab={setActiveTab} activeTab={activeTab} categories={categories} />
                        {activeTab === 1 && <Category jwt={jwt_token} part={part} />}
                        {activeTab === 2 && <Goods part={part} jwt={jwt_token} />}
                        {activeTab === 3 && <News part={part} jwt={jwt_token} />}
                        {activeTab === 4 && <EditGood part={part} jwt={jwt_token} />}
                        {activeTab === 5 && <ProductManagement jwt={jwt_token} />}
                        {activeTab === 6 && <CategoryManagement jwt={jwt_token} />}
                        {activeTab === 7 && <MediaManagementPage />}
                        {activeTab === 8 && <SettingsManagement />}
                      </div>
                    </div>
                  </div>
                  <div className={`${s.blur} ${isLoading && s.active}`}></div>
                </AuthProvider>
              </QueryClientProvider>
          ) : (
              <LogInForm logIn={logIn} />
          )}
        </>
      </>
  )
}
