import styled from "styled-components";

export const Paragraph = styled.p`
  font-family: "Raleway";
  font-size: 18px;
  font-weight: ${(props) => (props.isBolded ? "bold" : "normal")};
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const EnumList = styled.ul`
  & > li {
    list-style-type: disc;
  }
`;
