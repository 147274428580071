import {useAuth} from "../../../context/AuthContext.jsx";
import {Card, Divider, Layout, notification, Spin} from "antd";
import {useQuery} from "@tanstack/react-query";
import {getPrivateSettings} from "../../../api/settings.endpoint.js";
import {useState} from "react";
import {EmailSettingsForm} from "./forms/EmailSettingsForm.jsx";
import {StaticURLSettingsForm} from "./forms/StaticURLSettingsForm.jsx";

const SettingsManagement = () => {
    const { token } = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [settings, _setSettingsMap] = useState(new Map());
    const settingsQuery = useQuery({
        queryFn: getPrivateSettings,
        queryKey: ['settings', 'private', 'all'],
        onSuccess: (data) => {
            const settingsMap = new Map();
            if (data && data.length) {
                data.forEach(s => {
                    settingsMap.set(s.key, s.value);
                });
            }
            _setSettingsMap(settingsMap);
        }
    });

    if (settingsQuery.isLoading) {
        return <Spin />;
    }

    return (
        <Layout>
            {contextHolder}
            <Layout.Content>
                <Card title="Настройки приложения">
                    {settings.has("STATIC_URL") && (
                        <>
                            <StaticURLSettingsForm settings={{ staticUrl: settings.get("STATIC_URL")}} notification={api} />
                            <Divider />
                        </>
                    )}
                    {settings.has("EMAIL_SETTINGS") && (<EmailSettingsForm settings={settings.get("EMAIL_SETTINGS")} notification={api} />)}
                </Card>
            </Layout.Content>
        </Layout>
    )
};

export default SettingsManagement;
