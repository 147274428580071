import React, {useCallback, useState} from "react";
import {Upload, Button, notification, Space} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {uploadFile} from "../../../api/media.endpoint.js";

const UploadMediaForm = () => {
    const [api, contextHolder] = notification.useNotification();
    const queryClient = useQueryClient();

    const [fileList, setFileList] = useState([]);

    const uploadMediaMutation = useMutation({
        mutationFn: uploadFile,
        onSuccess: () => {
            api.success({message: "Файл успешно загружены!"});
            queryClient.invalidateQueries({ queryKey: ["media", "all"] });
        },
        onError: () => {
            api.error({message: "Ошибка загрузки изображения!"});
        },
    });

    const onImageChange = useCallback((info) => {
        const files = info?.fileList;
        if (files) {
            setFileList(files);
        } else {
            setFileList([]);
        }

    }, [fileList]);

    const uploadHandler = async () => {
        if (fileList.length) {
            await Promise.all(fileList.map(
                (file) => {
                    const formData = new FormData();
                    formData.append("file", file.originFileObj, file.name);
                    return uploadMediaMutation.mutateAsync(formData);
                }
            ));
        }
    };

    return (
        <>
            {contextHolder}
            <Space direction="horizontal">
                <Upload
                    listType="text"
                    accept="image/*"
                    maxCount={5}
                    fileList={fileList}
                    onChange={onImageChange}
                >
                    <Button icon={<UploadOutlined />}>Выбрать изображение</Button>
                </Upload>
                <Button onClick={uploadHandler} type="primary" disabled={!fileList.length}>Загрузить на сервер</Button>
            </Space>
        </>
    );
};

export default UploadMediaForm;
