import './Carousel.css'

import {Carousel, Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

export default function CustomCarousel() {
  const [activeSlide, setActiveSlide] = useState(0)
  const {t} = useTranslation()

  return (
    <>
      <div className="custom-carousel-container">
        <Carousel className="check">
          <Carousel.Item>
            <img className="frontPic" src="lift-kabel.jpg" alt="" />
            <img className="frontPic-mobile" src="lift-kabel-m.jpg" alt="" />
            <Carousel.Caption>
              <div className={'carousel-caption-wrapper'}>
                <div>
                  <p>{t('Лифтовые кабели и провода')}</p>
                  <h5 dangerouslySetInnerHTML={{__html: `${t('Лифтовые кабели и провода ОП')}`}}></h5>
                  <Link
                    to="/products?categoryId=1ec43cdc-2f0f-463c-8be3-674203cd9ed6"
                    className="carousel-button"
                  >
                    {t('Кнопка Перейти')}
                  </Link>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="frontPic" src="mc7.jpeg" alt="" />
            <img className="frontPic-mobile" src="mc7.jpeg" alt="" />
            <Carousel.Caption>
              <div className={'carousel-caption-wrapper'}>
                <div>
                  <p>{t('Кабели контрольные')}</p>
                  <h5 dangerouslySetInnerHTML={{__html: `${t('Кабели контрольные ОП')}`}}></h5>
                  <Link
                    to="/products?categoryId=1b6320d9-4318-4d57-aa63-88dbd439c71e"
                    className="carousel-button"
                  >
                    {t('Кнопка Перейти')}
                  </Link>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="frontPic" src="kabel-upr.jpg" alt="" />
            <img className="frontPic-mobile" src="kabel-upr-m.jpg" alt="" />
            <Carousel.Caption>
              <div className={'carousel-caption-wrapper'}>
                <div>
                  <p
                    style={{maxWidth: '100%'}}
                    dangerouslySetInnerHTML={{__html: `${t('КАБЕЛИ УПРАВЛЕНИЯ')}`}}
                  ></p>
                  <h5 dangerouslySetInnerHTML={{__html: `${t('КАБЕЛИ УПРАВЛЕНИЯ ОП')}`}}></h5>
                  <Link
                    to="/products?categoryId=f9b21823-9fde-4fe2-b5a6-ea6a242f06a0"
                    className="carousel-button"
                  >
                    {t('Кнопка Перейти')}
                  </Link>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="frontPic" src="kabel-slide-4.jpg" alt="" />
            <img className="frontPic-mobile" src="kabel-slide-4-m.jpg" alt="" />
            <Carousel.Caption>
              <div className={'carousel-caption-wrapper'}>
                <div>
                  <p>
                    {t(
                      'Продукция для электрической установки железнодорожной сигнализации, централизации и блокировки'
                    )}
                  </p>
                  <Link
                    to="/products?categoryId=da3c9113-c9ae-44ca-9ad2-0459aae8d017&subcategoryId=a6156140-8d80-41cb-804c-8ec0aed54ef6"
                    className="carousel-button"
                  >
                    {t('Кнопка Перейти')}
                  </Link>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="frontPic" src="kabel-slide-5.jpg" alt="" />
            <img className="frontPic-mobile" src="kabel-slide-5-m.jpg" alt="" />
            <Carousel.Caption>
              <div className={'carousel-caption-wrapper'}>
                <div>
                  <p>{t('КАБЕЛИ И ПРОВОДА СВЯЗИ')}</p>
                  <h5 dangerouslySetInnerHTML={{__html: `${t('КАБЕЛИ И ПРОВОДА СВЯЗИ ОП')}`}}></h5>
                  <Link
                    to="/products?categoryId=a2d192d2-5603-4220-b3c2-1a1d2efadabf"
                    className="carousel-button"
                  >
                    {t('Кнопка Перейти')}
                  </Link>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="frontPic" src="mc6.jpeg" alt="" />
            <img className="frontPic-mobile" src="mc6.jpeg" alt="" />
            <Carousel.Caption>
              <div className={'carousel-caption-wrapper'}>
                <div>
                  <p
                    style={{maxWidth: '100%'}}
                    dangerouslySetInnerHTML={{__html: `${t('КАБЕЛИ И ПРОВОДА')}`}}
                  ></p>
                  <h5
                    style={{maxWidth: '98%'}}
                    dangerouslySetInnerHTML={{__html: `${t('КАБЕЛИ И ПРОВОДА ОП')}`}}
                  ></h5>
                  <Link
                    to="/products?categoryId=fe33bb54-7bb5-4fa2-9ab3-2e30bc92e64c"
                    className="carousel-button"
                  >
                    {t('Кнопка Перейти')}
                  </Link>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="frontPic" src="p.png" alt="" />
            <img className="frontPic-mobile" src="mc3.jpeg" alt="" />
            <Carousel.Caption>
              <div className={'carousel-caption-wrapper'}>
                <div>
                  <p
                    style={{maxWidth: '100%'}}
                    dangerouslySetInnerHTML={{__html: `${t('КАБЕЛИ МОНТАЖНЫЕ')}`}}
                  ></p>
                  <h5
                    style={{maxWidth: '100%'}}
                    dangerouslySetInnerHTML={{__html: `${t('КАБЕЛИ МОНТАЖНЫЕ ОП')}`}}
                  ></h5>
                  <Link
                    to="/products?categoryId=fe33bb54-7bb5-4fa2-9ab3-2e30bc92e64c"
                    className="carousel-button"
                  >
                    {t('Кнопка Перейти')}
                  </Link>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div className={'control-block-wrapper'}>
          <div className="control-block">
            <Row className="g-0 control-block-row">
              <Col
                className={`control-block-info ${activeSlide === 1 && 'control-block-active-info'}`}
                dangerouslySetInnerHTML={{__html: `${t('Лифтовые кабели и провода')}`}}
              ></Col>
              <Col
                className={`control-block-info ${activeSlide === 2 && 'control-block-active-info'}`}
                dangerouslySetInnerHTML={{__html: `${t('Кабели контрольные')}`}}
              ></Col>
              <Col
                className={`control-block-info ${activeSlide === 3 && 'control-block-active-info'}`}
                dangerouslySetInnerHTML={{__html: `${t('КАБЕЛИ УПРАВЛЕНИЯ')}`}}
              ></Col>
              <Col
                className={`control-block-info ${activeSlide === 4 && 'control-block-active-info'}`}
                dangerouslySetInnerHTML={{__html: `${t('Кабель для железной дороги')}`}}
              ></Col>
              <Col
                className={`control-block-info ${activeSlide === 5 && 'control-block-active-info'}`}
                dangerouslySetInnerHTML={{__html: `${t('КАБЕЛИ И ПРОВОДА СВЯЗИ')}`}}
              ></Col>
              <Col
                className={`control-block-info ${activeSlide === 6 && 'control-block-active-info'}`}
                dangerouslySetInnerHTML={{
                  __html: `${t('Кабели и провода для сигнализации и видеонаблюдения')}`,
                }}
              ></Col>
              <Col
                className={`control-block-info ${activeSlide === 7 && 'control-block-active-info'}`}
                dangerouslySetInnerHTML={{__html: `${t('КАБЕЛИ И ПРОВОДА МОНТАЖНЫЕ')}`}}
              ></Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}
