import React, {useState} from 'react'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {Link} from 'react-router-dom'
import s from './ThemeGallery.module.scss'
import {Breadcrumb} from 'react-bootstrap'
import {Fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'
import scrollIntoView from 'scroll-into-view'
import {v4} from 'uuid'
import {useTranslation} from 'react-i18next'

Fancybox.bind('[data-fancybox="photo"]', {
  dragToClose: false,

  Toolbar: false,
  closeButton: 'top',

  Image: {
    zoom: false,
  },

  on: {
    initCarousel: fancybox => {
      const slide = fancybox.Carousel.slides[fancybox.Carousel.page]

      fancybox.$container.style.setProperty('--bg-image', `url("${slide.$thumb.src}")`)
    },
    'Carousel.change': (fancybox, carousel, to, from) => {
      const slide = carousel.slides[to]

      fancybox.$container.style.setProperty('--bg-image', `url("${slide.$thumb.src}")`)
    },
  },
})

const ThemeGallery = () => {
  const {t} = useTranslation()
  const {slug} = useParams()
  const data = useSelector(state => state.photo)
  const [activeData, setActiveData] = useState([])

  useEffect(() => {
    if (slug === 'day-of-war') {
      setActiveData(data.dayOfWar)
    } else if (slug === 'circus') {
      setActiveData(data.circus)
    } else if (slug === 'water-trip') {
      setActiveData(data.waterTrip)
    } else if (slug === 'learning') {
      setActiveData(data.learning)
    } else if (slug === 'proforientation') {
      setActiveData(data.proforientation)
    } else if (slug === '9-may') {
      setActiveData(data.May)
    } else if (slug === 'winner-day') {
      setActiveData(data.winnerDay)
    } else if (slug === 'memory') {
      setActiveData(data.memory)
    } else {
      setActiveData([])
    }

    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [
    data.dayOfWar,
    data.circus,
    data.waterTrip,
    data.learning,
    data.proforientation,
    data.May,
    data.winnerDay,
    data.memory,
    slug,
  ])

  const showTitle = title => {
    if (slug === 'day-of-war')
      return `${t(
        'День всенародной памяти жертв Великой Отечественной Войны и геноцида белорусского народа'
      )}`
    else if (slug === 'circus') return `${t('Поездка в цирк')}`
    else if (slug === 'water-trip') return `${t('Водный поход на байдарках')}`
    else if (slug === 'learning') return `${t('Профориентация экскурсия фото')}`
    else if (slug === 'proforientation') return `${t('Профориентация экскурсия фото')}`
    else if (slug === '9-may') return `${t('Белорускабель отметил 9 мая')}`
    else if (slug === 'winner-day') return `${t('День победы')}`
    else if (slug === 'memory') return `${t('Чернобыль - мы обязаны знать и помнить')}`
  }

  return (
    <>
      <div className={s.container}>
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/photogallery">{t('Меню Фотогалерея')}</Link>
          </span>
          <Breadcrumb.Item active>
            {activeData.images && showTitle(activeData.title)}
          </Breadcrumb.Item>
        </Breadcrumb>
        {activeData.images && (
          <>
            <div className="commonLabels">{showTitle(activeData.title)}</div>
            <div className={s.wrapper}>
              {activeData.images.map(img => (
                <a data-fancybox="photo" href={img} key={v4()}>
                  <img src={img} alt="Картинка" />
                </a>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ThemeGallery
