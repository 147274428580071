import React from 'react'
import {Link} from 'react-router-dom'
import s from './Banner.module.scss'
import {useTranslation} from 'react-i18next'

const Banner = () => {
  const {t} = useTranslation()
  return (
    <div className={s.containerWrapper}>
      <div className={s.container}>
        <div className={s.wrapper}>
          <p className={s.title}>{t('Интернет магазин «Беларускабель»')}</p>
          <p
            className={s.paragraph}
            dangerouslySetInnerHTML={{__html: `${t('Покупайте кабель онлайн')}`}}
          ></p>
          <ul className={s.flex}>
            <li>{t('Рассрочка от 3 месяцев')}</li>
            <li>{t('Оплата онлайн')}</li>
            <li>{t('Широкий ассортимент')}</li>
            <li>{t('Выгодные цены')}</li>
          </ul>
          <Link to="/shop" target="_blank" className={s.button}>
            {t('Перейти в Интернет магазин')}
          </Link>
        </div>
        <div className={s.img}>
          <img src="internet-background.png" alt="" />
        </div>
      </div>
    </div>
  )
}
export default Banner
