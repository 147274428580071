import React, {useState} from 'react'
import AdminTitle from '../ui/AdminTitle/AdminTitle'
import s from './News.module.scss'
import NewsForm from './NewsForm/NewsForm'
// import NewsList from './NewsList/NewsList'
import img from '../img/backet.svg'
import activeImg from '../img/backet0.svg'
import Select from '../ui/Select/Select'
import Button from '../ui/Button/Button'

const News = props => {
  const [activeCategory, setActiveCategory] = useState(false)
  const [activeSubCategory, setActiveSubCategory] = useState(false)
  const [isSelectOpen, setIsSelectOpen] = useState(false)

  const categories = ['МКЭШМнг(А)-LS', 'TEST_PROVOD']
  const subCategories = ['MEGA', 'HA']

  return (
    <div className={s.container}>
      <div
        className={`${s.sheet} ${isSelectOpen ? s.active : ''}`}
        onClick={() => {
          setIsSelectOpen(false)
        }}
      ></div>

      <AdminTitle title="Добавить новость" />
      <NewsForm />
      

      <div className={s.margin}>
        <AdminTitle title="Удалить категорию " />
        <Select
          activeValue={activeCategory}
          setActiveValue={setActiveCategory}
          values={categories}
          placeholder={'Выберите категорию'}
          setIsSelectOpen={setIsSelectOpen}
          isSelectOpen={isSelectOpen}
          id={1}
        />
        <Button text={'Удалить категорию'} img={img} activeImg={activeImg} />
      </div>
      <div className={s.margin}>
        <AdminTitle title="Удалить подкатегорию " />
        <Select
          activeValue={activeSubCategory}
          setActiveValue={setActiveSubCategory}
          placeholder={'Выберите подкатегорию'}
          values={subCategories}
          setIsSelectOpen={setIsSelectOpen}
          isSelectOpen={isSelectOpen}
          id={2}
        />
        <Button text={'Удалить подкатегорию'} img={img} activeImg={activeImg} />
      </div>


      {/* <AdminTitle title="Список новостей" />
      <NewsList /> */}
    </div>
  )
}

export default News
