export const photogallery = {
  ru: {
    translation: {
      'День всенародной памяти жертв Великой Отечественной Войны и геноцида белорусского народа':
        'День всенародной памяти жертв Великой Отечественной Войны и геноцида белорусского народа',
      'Поездка в цирк': 'Поездка в цирк',
      'Водный поход на байдарках': 'Водный поход на байдарках',
      'Командноштабные учения 2022': 'Командноштабные учения 2022',
      'Профориентация экскурсия фото': 'Профориентация экскурсия фото',
      'Белорускабель отметил 9 мая': 'Белорускабель отметил 9 мая',
      'День победы': 'День победы',
      'Чернобыль - мы обязаны знать и помнить': 'Чернобыль - мы обязаны знать и помнить',
      'Кнопка Подробнее': 'Подробнее',
      'Наши клиенты': 'Наши клиенты',
    },
  },
  en: {
    translation: {
      'День всенародной памяти жертв Великой Отечественной Войны и геноцида белорусского народа':
        'Day of national memory of the victims of the Great Patriotic War and the genocide of the Belarusian people',
      'Поездка в цирк': 'Trip to the circus',
      'Водный поход на байдарках': 'Kayak water trip',
      'Командноштабные учения 2022': 'Command post exercise 2022',
      'Профориентация экскурсия фото': 'Career guidance excursion photo',
      'Белорускабель отметил 9 мая': 'Beloruscable celebrated May 9',
      'День победы': 'Victory Day',
      'Чернобыль - мы обязаны знать и помнить': 'Chernobyl - we must know and remember',
      'Кнопка Подробнее': 'More',
      'Наши клиенты': 'Our clients',
    },
  },
  bel: {
    translation: {
      'День всенародной памяти жертв Великой Отечественной Войны и геноцида белорусского народа':
        'Дзень усенароднай памяці ахвяр Вялікай Айчыннай Вайны і генацыду беларускага народа',
      'Поездка в цирк': 'Паездка ў цырк',
      'Водный поход на байдарках': 'Водны паход на байдарках',
      'Командноштабные учения 2022': 'Каманднаштабныя вучэнні 2022',
      'Профориентация экскурсия фото': 'Прафарыентацыя экскурсія фота',
      'Белорускабель отметил 9 мая': 'Беларускабель адзначыў 9 мая',
      'День победы': 'Дзень Перамогі',
      'Чернобыль - мы обязаны знать и помнить': 'Чарнобыль - мы абавязаны ведаць і памятаць',
      'Кнопка Подробнее': 'Больш падрабязна',
      'Наши клиенты': 'Нашы кліенты',
    },
  },
}
