import {createSlice} from '@reduxjs/toolkit'
import {Link} from 'react-router-dom'

const initialState = [
  {
    id: 1,
    title: 'По «ступеням к будущей профессии»: активисты молодежного парламента побывали на производстве ОАО «БЕЛАРУСКАБЕЛЬ»',
    date: '11.11.2022',
    preParagraph: 'Молодежный парламент – инициатор многих полезных проектов. Одной из последних новаторских идей стала реализация проекта, помогающего молодым людям не только определиться с профессией, но и воочию ознакомиться с работой предприятий и организаций района.',
    paragraph: `Молодежный парламент – инициатор многих полезных проектов. Одной из последних новаторских идей стала реализация проекта, помогающего молодым людям не только определиться с профессией, но и воочию ознакомиться с работой предприятий и организаций района.`,
  },
  {
    id: 2,
    title: 'По «ступеням к будущей профессии»: активисты молодежного парламента побывали на производстве ОАО «БЕЛАРУСКАБЕЛЬ»',
    date: '11.11.2022',
    preParagraph: 'Молодежный парламент – инициатор многих полезных проектов. Одной из последних новаторских идей стала реализация проекта, помогающего молодым людям не только определиться с профессией, но и воочию ознакомиться с работой предприятий и организаций района.',
    paragraph: `Молодежный парламент – инициатор многих полезных проектов. Одной из последних новаторских идей стала реализация проекта, помогающего молодым людям не только определиться с профессией, но и воочию ознакомиться с работой предприятий и организаций района.`,
  },
  {
    id: 3,
    title: 'По «ступеням к будущей профессии»: активисты молодежного парламента побывали на производстве ОАО «БЕЛАРУСКАБЕЛЬ»',
    date: '11.11.2022',
    preParagraph: 'Молодежный парламент – инициатор многих полезных проектов. Одной из последних новаторских идей стала реализация проекта, помогающего молодым людям не только определиться с профессией, но и воочию ознакомиться с работой предприятий и организаций района.',
    paragraph: `Молодежный парламент – инициатор многих полезных проектов. Одной из последних новаторских идей стала реализация проекта, помогающего молодым людям не только определиться с профессией, но и воочию ознакомиться с работой предприятий и организаций района.`,
  },
]

export const SmiReducer = createSlice({
  name: 'smi',
  initialState,
  reducers: {},
})

// Action creators are generated for each case reducer function
// export const {increment, decrement, incrementByAmount} = SmiReducer.actions

export default SmiReducer.reducer
