import {Breadcrumb} from 'react-bootstrap'
import './InnovativeProduction.css'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import scrollIntoView from 'scroll-into-view'
import {useTranslation} from 'react-i18next'

export default function InnovativeProduction() {
  const {t} = useTranslation()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])
  return (
    <>
      <div className="innovative-page-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Инновационная продукция')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Инновационная продукция')}</div>
        <div
          className="innovatives-block"
          dangerouslySetInnerHTML={{__html: `${t('ТУ BY 400083186.137-2012')}`}}
        ></div>
      </div>
    </>
  )
}
