import "./Certificates.css";
import { Carousel } from "react-bootstrap";
import s from "./Certificates.module.scss";
import NewsBlock from "./News/News";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Certificates() {
  const { t } = useTranslation();
  let news = useSelector((store) => store.news);
  news = news.slice(0, 2);

  return (
    <div className={`certList-wrapper ${s.flex}`}>
      <div className={`certList ${s.flexItem}`}>
        <div className={"listCol"}>
          <div className="labels">
            <p>{t("Достижения и Сертификаты")}</p>
          </div>
          <Carousel className="certCarousel" variant="dark">
          <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/certificate-143.jpg"
                alt="Second slide"
              />
            </Carousel.Item>            
          <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/11.png"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/kachestvo-2023.jpg"
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/novinka-2023.jpg"
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/laureat.jpg"
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/best-tovar.jpg"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/6.webp"
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/7.webp"
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/stb-iso-14001-2017-s.jpg"
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/stb-iso-45001-2020-s.jpg"
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/10.webp"
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/diplom-ohrana-2023-1-s.jpg"
                alt="Third slide"
              />
            </Carousel.Item>       
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/achievements/diplom-ohrana-2023-2-s.jpg"
                alt="Third slide"
              />
            </Carousel.Item>                  
          </Carousel>
        </div>
      </div>
      <div className={`certList ${s.flexItem}`}>
        <div className="labels">
          <p>{t("Заг Новости")}</p>
        </div>
        <div className={s.news}>
          {news.map((n) => (
            <NewsBlock {...n} />
          ))}
        </div>

        <p className={s.paragraph}>
          <Link to="/presscenter">{t("Все новости")}</Link>
        </p>
      </div>
    </div>
  );
}
