export const checkout = {
  ru: {
    translation: {
      'Оформление заказа': 'Оформление заказа',
      'Оформить заказ': 'Оформить заказ',
      'Я выражаю согласие на': 'Я выражаю согласие на',
      'передачу и обработку персональных данных': 'передачу и обработку персональных данных',
      'в соответствии с': 'в соответствии с',
      'Пользовательским соглашением': 'Пользовательским соглашением',
      'Отправляя заказ, Вы соглашаетесь с': 'Отправляя заказ, Вы соглашаетесь с',
      'Правилами приобретения продукции в интернет-магазине ОАО “Беларускабель”':
        'Правилами приобретения продукции в интернет-магазине ОАО “Беларускабель”',
      'Неправильный номер телефона': 'Неправильный номер телефона',
      'Необходимо ввести номер телефона': 'Необходимо ввести номер телефона',
      'Неправильный email': 'Неправильный email',
      'Необходимо ввести email': 'Необходимо ввести email',
      'Оформление ФИО': 'Оформление ФИО',
      'Номер телефона': 'Номер телефона',
      'Наименование организации': 'Наименование организации',
      'Вы не сможете отслеживать быстрый заказ в личном кабинете. Для отслеживания заказа Вам необходимо':
        'Вы не сможете отслеживать быстрый заказ в личном кабинете. Для отслеживания заказа Вам необходимо',
      ', либо, если Вы уже зарегистрированы, авторизоваться и сделать заказ в личном кабинете.':
        ', либо, если Вы уже зарегистрированы, авторизоваться и сделать заказ в личном кабинете.',
      'кнопка Зарегистрироваться': 'Зарегистрироваться',
      'Продукция в наличии': 'Продукция в наличии',
      'Номинальное сечение жил, мм² - ': 'Номинальное сечение жил, мм² - ',
      'Удалить из корзины': 'Удалить из корзины',
    },
  },
  en: {
    translation: {
      'Оформление заказа': 'Checkout',
      'Оформить заказ': 'Checkout',
      'Я выражаю согласие на': 'I agree to',
      'передачу и обработку персональных данных': 'transfer and processing of personal data',
      'в соответствии с': 'in accordance with',
      'Пользовательским соглашением': 'User Agreement',
      'Отправляя заказ, Вы соглашаетесь с': 'By submitting an order, you agree to',
      'Правилами приобретения продукции в интернет-магазине ОАО “Беларускабель”':
        'Rules for the purchase of products in the online store of JSC "Belaruskabel"',
      'Неправильный номер телефона': 'Wrong phone number',
      'Необходимо ввести номер телефона': 'Phone number required',
      'Неправильный email': 'Wrong email',
      'Необходимо ввести email': 'Email required',
      'Оформление ФИО': 'Full name registration',
      'Номер телефона': 'Phone number',
      'Наименование организации': 'Name of company',
      'Вы не сможете отслеживать быстрый заказ в личном кабинете. Для отслеживания заказа Вам необходимо':
        'You will not be able to track a quick order in your personal account. To track your order, you need',
      ', либо, если Вы уже зарегистрированы, авторизоваться и сделать заказ в личном кабинете.':
        ', or, if you are already registered, log in and place an order in your personal account.',
      'кнопка Зарегистрироваться': 'Sign up',
      'Продукция в наличии': 'Products in stock',
      'Номинальное сечение жил, мм² - ': 'Nominal cross-section of conductors, mm² -',
      'Удалить из корзины': 'Remove from cart',
    },
  },
  bel: {
    translation: {
      'Оформление заказа': 'Афармленне замовы',
      'Оформить заказ': 'Афармленне замовы',
      'Я выражаю согласие на': 'Я выказваю згоду на',
      'передачу и обработку персональных данных': 'перадачу і апрацоўку персанальных дадзеных',
      'в соответствии с': 'у адпаведнасці з',
      'Пользовательским соглашением': 'Карыстальніцкай дамовай',
      'Отправляя заказ, Вы соглашаетесь с': 'Адпраўляючы замову, Вы згаджаецеся з',
      'Правилами приобретения продукции в интернет-магазине ОАО “Беларускабель”':
        'Правіламі набыцця прадукцыі ў інтэрнэт-краме ААТ "Беларускабель"',
      'Неправильный номер телефона': 'Няправільны нумар тэлефона',
      'Необходимо ввести номер телефона': 'Неабходна ўвесці нумар тэлефона',
      'Неправильный email': 'Няправільны email',
      'Необходимо ввести email': 'Неабходна ўвесці email',
      'Оформление ФИО': 'Афармленне ПІБ',
      'Номер телефона': 'Нумар тэлефона',
      'Наименование организации': 'Найменне арганізацыі',
      'Вы не сможете отслеживать быстрый заказ в личном кабинете. Для отслеживания заказа Вам необходимо':
        'Вы не зможаце адсочваць хуткі заказ у асабістым кабінеце. Для адсочвання замовы Вам неабходна',
      ', либо, если Вы уже зарегистрированы, авторизоваться и сделать заказ в личном кабинете.':
        ', альбо, калі Вы ўжо зарэгістраваны, аўтарызавацца і зрабіць заказ у асабістым кабінеце.',
      'кнопка Зарегистрироваться': 'Зарэгістравацца',
      'Продукция в наличии': 'Прадукцыя ў наяўнасці',
      'Номинальное сечение жил, мм² - ': 'Намінальнае сячэнне жыў, мм² -',
      'Удалить из корзины': 'Выдаліць з кошыка',
    },
  },
}
