import {createAsyncThunk} from '@reduxjs/toolkit'
import {https} from '../../config'

export const setCategory = createAsyncThunk('admin/setCategory', async ({name, jwt}) => {
    return await https
        .post(
            '/ru/categories',
            {
                name,
            },
            {
                headers: {
                    Authorization: jwt,
                },
            }
        )
        .then(response => response)
})

export const setSubcategory = createAsyncThunk(
    'admin/setSubcategory',
    async ({name, categoryId, jwt}) => {
        return await https
            .post(
                '/ru/sub_categories',
                {
                    name,
                    categoryId,
                },
                {
                    headers: {
                        Authorization: jwt,
                    },
                }
            )
            .then(response => response)
    }
)

export const setIndustries = createAsyncThunk(
    'admin/setIndustries',
    async ({name, entity, jwt}) => {
        return await https
            .post(
                '/ru/industries',
                {
                    name,
                    entity,
                },
                {
                    headers: {
                        Authorization: jwt,
                    },
                }
            )
            .then(response => response)
    }
)

export const logInAdmin = createAsyncThunk('admin/auth', async info => {
    return await https
        .post('/user/auth', {
            email: info.email,
            password: info.password,
        })
        .then(response => {
            if (response?.data?.data?.jwt_token) {
                localStorage.setItem('jwt_token', response.data.data.jwt_token);
            }
            return response.data.data
        })
})

export const updateCategory = createAsyncThunk('admin/updateCategory', ({id, jwt, name}) => {
    return https.put(`/ru/category/${id}`, {
        name
    }, {
        headers: {
            Authorization: jwt,
        },
    });
});

export const removeSubcategory = createAsyncThunk('admin/removeSubcategory', async ({id, jwt}) => {
    return await https.delete(`/ru/sub_category/${id}`, {
        headers: {
            Authorization: jwt,
        }
    }).then(response => response);
})

export const removeCategory = createAsyncThunk('admin/removeCategory', async ({id, jwt}) => {
    return await https
        .delete(
            '/ru/category/' + id,
            {
                id,
            },
            {
                headers: {
                    Authorization: jwt,
                },
            }
        )
        .then(response => response)
})

export const removeIndustry = createAsyncThunk('admin/removeIndustry', async ({id, jwt}) => {
    return await https
        .delete(
            '/ru/industry/' + id,
            {
                id,
            },
            {
                headers: {
                    Authorization: jwt,
                },
            }
        )
        .then(response => response)
})

export const addGoods = createAsyncThunk('admin/goods', async (formData, jwt) => {
    return await https
        .post('/ru/products/', formData, {
            headers: {
                Authorization: jwt,
            },
        })
        .then(response => response)
})

export const getProduct = createAsyncThunk('admin/editGoods', async info => {
    return await https.get('/ru/product/' + info).then(response => {
        return response.data.data
    })
})

export const removeGood = createAsyncThunk('admin/removeGood', async ({id, jwt}) => {
    return await https.delete('/ru/product/' + id,
        {
            id,
        },
        {
            headers: {
                Authorization: jwt,
            },
        }
    ).then(response => response)
})
