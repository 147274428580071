import {useCallback, useMemo, useState} from "react";
import {Button, Checkbox, Form, Input, Space, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";

const apiUrl = process.env.REACT_APP_API;

export const CategoryForm = ({
    jwt,
    formRef,
    category = null,
    subCategories,
    onSubmit,
    onCancel,
    isLoading = false,
}) => {
    const [fileList, setFileList] = useState(() => {
        if (category && category.media) {
            return [{
                uid: category.media._id,
                name: category.media.filename,
                status: 'done',
                url: category.imageUrl,
                thumbUrl: category.imageUrl,
            }];
        }
        return [];
    });
    const [isImageLoading, setIsImageLoading] = useState(false);

    const initialValues = useMemo(() => {
        return category ? category : {};
    }, [category]);

    const subCategoryCheckboxes = useMemo(() => {
        return subCategories.filter(({ ln }) => ln === 'ru').map((subCategory) => ({
            label: subCategory.name,
            value: subCategory.id,
        }));
    }, [subCategories]);

    const onFormSubmitHandler = useCallback(async (values) => {
        const {name, subCategories} = values;
        const [media] = fileList;

        await onSubmit({
            name,
            subCategories,
            media: media?.response?._id ?? null,
        });
    }, [onSubmit, fileList]);

    const onImageChange = useCallback((info) => {
        const [file] = info?.fileList || [];
        if (info.file.status !== 'uploading') {
            setIsImageLoading(true);
        }
        if (info.file.status === 'done') {
            setIsImageLoading(false);
        } else if (info.file.status === 'error') {
            setIsImageLoading(false);
        } else if (info.file.status === 'removed') {
            setIsImageLoading(false);
        }

        if (file) {
            setFileList([file]);
        } else {
            setFileList([]);
        }

    }, [fileList]);

    return (
        <Form form={formRef} onFinish={onFormSubmitHandler} disabled={isLoading || isImageLoading} initialValues={initialValues}>
            <Form.Item name="name" label="Название категории"  rules={[
                {
                    required: true,
                    message: 'Поле обязательное',
                },
            ]}>
                <Input />
            </Form.Item>

            <Form.Item label="Изображение (необязательно)" name="image">
                <Upload
                    action={`${apiUrl}/v2/media/upload`}
                    headers={{authorization: `Bearer ${jwt}` }}
                    listType="text"
                    accept="image/*"
                    maxCount={1}
                    fileList={fileList}
                    onChange={onImageChange}
                >
                    <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
                </Upload>
            </Form.Item>

            <Form.Item name="subCategories" label="Подкатегории">
                <Checkbox.Group options={subCategoryCheckboxes} />
            </Form.Item>

            <Form.Item>
                <Space>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="primary" htmlType="submit" loading={isLoading || isImageLoading}>
                        Сохранить
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
};
