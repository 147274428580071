import React, {useState} from 'react'
import s from './Map.module.scss'
import {useTranslation} from 'react-i18next'

const Map = () => {
  const [activePin, setActivePin] = useState(1)
  const {t} = useTranslation()

  // const coords = [
  //   {id: 1, top: 11, left: 69},
  //   {id: 2, top: 27, left: 77},
  //   {id: 3, top: 11, left: 54},
  //   {id: 4, top: 9, left: 41},
  //   {id: 5, top: 12, left: 50.5},
  //   {id: 6, top: 16, left: 61},
  //   {id: 7, top: 18.5, left: 63},
  //   {id: 8, top: 20, left: 60},
  //   {id: 9, top: 22, left: 62},
  //   {id: 10, top: 7, left: 50},
  //   {id: 11, top: 6, left: 51},
  //   {id: 12, top: 5, left: 52},
  //   {id: 13, top: 9, left: 48.5},
  //   {id: 14, top: 9, left: 52},
  //   {id: 15, top: 17, left: 58},
  //   {id: 16, top: 16, left: 50.5},
  //   {id: 17, top: 30, left: 52.3},
  //   {id: 18, top: 23, left: 52.7},
  //   {id: 19, top: 13, left: 46.5},
  //   {id: 20, top: 43.8, left: 15},
  //   {id: 21, top: 26, left: 64},
  //   {id: 22, top: 18, left: 51.7},
  //   {id: 23, top: 34.5, left: 69},
  //   {id: 24, top: 27.2, left: 61.2},
  //   {id: 25, top: 21.2, left: 56.8},
  //   {id: 26, top: 17, left: 48},
    
  // ]

  // const countries = [
  //   {id: 1, country: `${t('Страна Россия')}`},
  //   {id: 2, country: `${t('Страна Китай')}`},
  //   {id: 3, country: `${t('Страна Украина')}`},
  //   {id: 4, country: `${t('Страна Великобритания')}`},
  //   {id: 5, country: `${t('Страна Молдова')}`},
  //   {id: 6, country: `${t('Страна Казахстан')}`},
  //   {id: 7, country: `${t('Страна Киргизия')}`},
  //   {id: 8, country: `${t('Страна Азербайджан')}`},
  //   {id: 9, country: `${t('Страна Туркменистан')}`},
  //   {id: 10, country: `${t('Страна Литва')}`},
  //   {id: 11, country: `${t('Страна Латвия')}`},
  //   {id: 12, country: `${t('Страна Эстония')}`},
  //   {id: 13, country: `${t('Страна Чехия')}`},
  //   {id: 14, country: `${t('Страна Польша')}`},
  //   {id: 15, country: `${t('Страна Грузия')}`},
  //   {id: 16, country: `${t('Страна Сербия')}`},
  //   {id: 17, country: `${t('Страна Египет')}`},
  //   {id: 18, country: `${t('Страна Кипр')}`},
  //   {id: 19, country: `${t('Страна Германия')}`},
  //   {id: 20, country: `${t('Страна Панама')}`},
  //   {id: 21, country: `${t('Страна Афганистан')}`},
  //   {id: 22, country: `${t('Страна Болгария')}`},
  //   {id: 23, country: `${t('Страна Индия')}`},
  //   {id: 24, country: `${t('Страна Иран')}`},
  //   {id: 25, country: `${t('Страна Армения')}`},
  //   {id: 26, country: `${t('Страна Хорватия')}`},
  // ]

  const coords = [
    {id: 1, top: 30, left: 74},
    {id: 2, top: 50.5, left: 74},
    {id: 3, top: 41, left: 54.4},
    {id: 4, top: 38, left: 45.8},
    {id: 5, top: 42.3, left: 53.9},
    {id: 6, top: 42.4, left: 64.2},
    {id: 7, top: 45.8, left: 66},
    {id: 8, top: 46.45, left: 59},
    {id: 9, top: 46.7, left: 62},
    {id: 10, top: 37, left: 52.65},
    {id: 11, top: 35.8, left: 52.85},
    {id: 12, top: 34.4, left: 53.2},
    {id: 13, top: 40.9, left: 50.4},
    {id: 14, top: 39.3, left: 51.4},
    {id: 15, top: 45.4, left: 57.95},
    {id: 16, top: 44.3, left: 51.92},
    {id: 17, top: 53.2, left: 54.2},
    {id: 18, top: 49, left: 55.1},
    {id: 19, top: 40, left: 49},
    {id: 20, top: 61.2, left: 25.1},
    {id: 21, top: 49.8, left: 63.8},
    {id: 22, top: 45.2, left: 53},
    {id: 23, top: 55, left: 67},
    {id: 25, top: 46.3, left: 58.25},
    {id: 26, top: 44.3, left: 50.6},
  ]

  const countries = [
    {id: 1, country: `${t('Страна Россия')}`},
    {id: 2, country: `${t('Страна Китай')}`},
    {id: 3, country: `${t('Страна Украина')}`},
    {id: 4, country: `${t('Страна Великобритания')}`},
    {id: 5, country: `${t('Страна Молдова')}`},
    {id: 6, country: `${t('Страна Казахстан')}`},
    {id: 7, country: `${t('Страна Киргизия')}`},
    {id: 8, country: `${t('Страна Азербайджан')}`},
    {id: 9, country: `${t('Страна Туркменистан')}`},
    {id: 10, country: `${t('Страна Литва')}`},
    {id: 11, country: `${t('Страна Латвия')}`},
    {id: 12, country: `${t('Страна Эстония')}`},
    {id: 13, country: `${t('Страна Чехия')}`},
    {id: 14, country: `${t('Страна Польша')}`},
    {id: 15, country: `${t('Страна Грузия')}`},
    {id: 16, country: `${t('Страна Сербия')}`},
    {id: 17, country: `${t('Страна Египет')}`},
    {id: 18, country: `${t('Страна Кипр')}`},
    {id: 19, country: `${t('Страна Германия')}`},
    {id: 20, country: `${t('Страна Панама')}`},
    {id: 21, country: `${t('Страна Афганистан')}`},
    {id: 22, country: `${t('Страна Болгария')}`},
    {id: 23, country: `${t('Страна Индия')}`},
    {id: 25, country: `${t('Страна Армения')}`},
    {id: 26, country: `${t('Страна Хорватия')}`},
  ]

  return (
    <div className={`_container ${s.wrapper}`}>
      <div className="commonLabels">{t('География поставок')}</div>
      <div className={s.flex}>
        <div className={s.flexMap}>
          <img src="map.svg" alt="Карта" />
          {coords.map(coord => (
            <div
              className={`${s.pin} ${activePin === coord.id ? s.active : ''}`}
              style={{
                position: 'absolute',
                top: coord.top + '%',
                left: coord.left + '%',
              }}
              key={coord.id}
            >
              {activePin === coord.id ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  shapeRendering="geometricPrecision"
                  textRendering="geometricPrecision"
                  imageRendering="optimizeQuality"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  className={s.svgZoom}
                >
                  <path
                    fill="#d7392d"
                    d="M28.5 57.5c3.633.057 3.8-.943.5-3a2.428 2.428 0 00-.5 2c-1.762-.762-2.762-2.096-3-4 1.333 0 2-.667 2-2 1.327 1.3 2.66 2.633 4 4a266.818 266.818 0 0112.5-15c9.045-10.342 8.545-20.175-1.5-29.5C28.588 3.414 18.755 7.248 13 21.5a12.5 12.5 0 000 7c3.26 7.526 7.76 14.192 13.5 20-1.406.473-2.073 1.473-2 3-5.744-5.925-10.577-12.592-14.5-20-2.547-16.916 4.62-26.583 21.5-29 20.374 4.079 26.54 15.745 18.5 35A299.285 299.285 0 0032.5 60c-1.912.28-3.246-.554-4-2.5zm-1-7l-1-2c-5.74-5.808-10.24-12.474-13.5-20a12.5 12.5 0 010-7C18.755 7.248 28.588 3.414 42.5 10c10.045 9.325 10.545 19.158 1.5 29.5a266.818 266.818 0 00-12.5 15c-1.34-1.367-2.673-2.7-4-4zm2-34c6.206-.068 9.873 2.932 11 9-2.137 7.736-6.803 10.069-14 7-5.296-6.46-4.296-11.793 3-16z"
                    opacity=".999"
                  />
                  <path
                    fill="#d73a2d"
                    d="M26.5 13.5c14.862-.61 19.695 6.057 14.5 20-4.091 3.805-8.925 4.972-14.5 3.5-5.864-3.566-8.03-8.733-6.5-15.5 1.305-3.461 3.471-6.128 6.5-8zm3 3c-7.296 4.207-8.296 9.54-3 16 7.197 3.069 11.863.736 14-7-1.127-6.068-4.794-9.068-11-9z"
                  />
                  <path
                    fill="#d2382c"
                    d="M26.5 48.5l1 2c0 1.333-.667 2-2 2-.667 0-1-.333-1-1-.073-1.527.594-2.527 2-3z"
                  />
                  <path
                    fill="#cf372c"
                    d="M28.5 57.5v-1a2.428 2.428 0 01.5-2c3.3 2.057 3.133 3.057-.5 3z"
                  />
                </svg>
              ) : (
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  className={s.svgZoom}
                >
                  <g fill="#d83a2e">
                    <path d="M21.9 6.8C14.3 10.7 10.5 17 10.5 25.5c0 5.7.3 6.7 4.7 13.1 6.7 9.7 15.6 20.9 16.7 20.9 1.4 0 17.9-21.5 20.2-26.3 4.5-9.2 0-21.2-10-26.4C35 3.1 29 3.1 21.9 6.8zm19 2c1.9.9 4.8 3.4 6.5 5.5 2.5 3.1 3.1 4.9 3.4 10 .3 5.6 0 6.7-3 11.3-4.4 6.8-15.1 20.4-15.9 20.1-1-.3-12.7-15.3-16-20.5-10.3-16.3 7.4-35 25-26.4z" />
                    <path d="M27.5 14.9c-5.3 2.2-8.1 9.1-6.2 14.9C22.7 34 27.9 38 32 38s9.3-4 10.7-8.3c3.1-9.3-6.3-18.4-15.2-14.8zm9 2.7c2 .8 4.5 5.6 4.5 8.8 0 1.3-1.2 3.8-2.6 5.5-2 2.5-3.4 3.1-6.4 3.1-3 0-4.4-.6-6.4-3.1-1.4-1.7-2.6-4.2-2.6-5.5 0-3 2.4-8 4.2-8.7 1.9-.8 7.4-.8 9.3-.1z" />
                  </g>
                </svg>
              )}
            </div>
          ))}
        </div>
        <div className={s.flexItem}>
          {countries.map(country => (
            <span
              onMouseEnter={() => setActivePin(country.id)}
              onMouseLeave={() => setActivePin(0)}
            >
              {activePin === country.id ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  shapeRendering="geometricPrecision"
                  textRendering="geometricPrecision"
                  imageRendering="optimizeQuality"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  className={s.svgZoom}
                >
                  <path
                    fill="#d7392d"
                    d="M28.5 57.5c3.633.057 3.8-.943.5-3a2.428 2.428 0 00-.5 2c-1.762-.762-2.762-2.096-3-4 1.333 0 2-.667 2-2 1.327 1.3 2.66 2.633 4 4a266.818 266.818 0 0112.5-15c9.045-10.342 8.545-20.175-1.5-29.5C28.588 3.414 18.755 7.248 13 21.5a12.5 12.5 0 000 7c3.26 7.526 7.76 14.192 13.5 20-1.406.473-2.073 1.473-2 3-5.744-5.925-10.577-12.592-14.5-20-2.547-16.916 4.62-26.583 21.5-29 20.374 4.079 26.54 15.745 18.5 35A299.285 299.285 0 0032.5 60c-1.912.28-3.246-.554-4-2.5zm-1-7l-1-2c-5.74-5.808-10.24-12.474-13.5-20a12.5 12.5 0 010-7C18.755 7.248 28.588 3.414 42.5 10c10.045 9.325 10.545 19.158 1.5 29.5a266.818 266.818 0 00-12.5 15c-1.34-1.367-2.673-2.7-4-4zm2-34c6.206-.068 9.873 2.932 11 9-2.137 7.736-6.803 10.069-14 7-5.296-6.46-4.296-11.793 3-16z"
                    opacity=".999"
                  />
                  <path
                    fill="#d73a2d"
                    d="M26.5 13.5c14.862-.61 19.695 6.057 14.5 20-4.091 3.805-8.925 4.972-14.5 3.5-5.864-3.566-8.03-8.733-6.5-15.5 1.305-3.461 3.471-6.128 6.5-8zm3 3c-7.296 4.207-8.296 9.54-3 16 7.197 3.069 11.863.736 14-7-1.127-6.068-4.794-9.068-11-9z"
                  />
                  <path
                    fill="#d2382c"
                    d="M26.5 48.5l1 2c0 1.333-.667 2-2 2-.667 0-1-.333-1-1-.073-1.527.594-2.527 2-3z"
                  />
                  <path
                    fill="#cf372c"
                    d="M28.5 57.5v-1a2.428 2.428 0 01.5-2c3.3 2.057 3.133 3.057-.5 3z"
                  />
                </svg>
              ) : (
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  className={s.svgZoom}
                >
                  <g fill="#d83a2e">
                    <path d="M21.9 6.8C14.3 10.7 10.5 17 10.5 25.5c0 5.7.3 6.7 4.7 13.1 6.7 9.7 15.6 20.9 16.7 20.9 1.4 0 17.9-21.5 20.2-26.3 4.5-9.2 0-21.2-10-26.4C35 3.1 29 3.1 21.9 6.8zm19 2c1.9.9 4.8 3.4 6.5 5.5 2.5 3.1 3.1 4.9 3.4 10 .3 5.6 0 6.7-3 11.3-4.4 6.8-15.1 20.4-15.9 20.1-1-.3-12.7-15.3-16-20.5-10.3-16.3 7.4-35 25-26.4z" />
                    <path d="M27.5 14.9c-5.3 2.2-8.1 9.1-6.2 14.9C22.7 34 27.9 38 32 38s9.3-4 10.7-8.3c3.1-9.3-6.3-18.4-15.2-14.8zm9 2.7c2 .8 4.5 5.6 4.5 8.8 0 1.3-1.2 3.8-2.6 5.5-2 2.5-3.4 3.1-6.4 3.1-3 0-4.4-.6-6.4-3.1-1.4-1.7-2.6-4.2-2.6-5.5 0-3 2.4-8 4.2-8.7 1.9-.8 7.4-.8 9.3-.1z" />
                  </g>
                </svg>
              )}
              <span className={s.title}>{country.country}</span>
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Map
