import React from "react";

import {
  StyledLink,
  ListItem,
  Wrapper,
  IconImage,
  IconImageWrapper,
} from "./styled";
import { filesExtensions, getFileExtension } from "utils";

export default function AnswerList({ config }) {
  return (
    <ul>
      {config.map(({ id, title, link }) => {
        const currentExtension = getFileExtension(link);
        const iconTypeName = filesExtensions[currentExtension];
        const iconWidth = iconTypeName === "pdf-icon" ? "20px" : "24px";

        return (
          <Wrapper>
            <IconImageWrapper>
              <IconImage
                src={`/shareholders/${iconTypeName}.svg`}
                width={iconWidth}
                alt="file-icon"
              />
            </IconImageWrapper>
            <ListItem key={id}>
              <StyledLink className="answer-list-li" to={link} target="_blank">
                {title}
              </StyledLink>
            </ListItem>
          </Wrapper>
        );
      })}
    </ul>
  );
}
